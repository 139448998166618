import { img_error_404 } from "../../utils/images";
import Error from "../../components/common/Error";

const NotFound = () => {
  return (
    <>
      <Error image={img_error_404} title="Page Not Found" description="The page you are looking was moved, removed,<br/>  renamed, or might never exist!" />
    </>
  )
};

export default NotFound
