import { Link, useNavigate } from "react-router-dom";
import AuthSideContent from "../../components/common/AuthSideContent";
import Logo from "../../components/common/Logo";
import Button from "../../components/common/Button";
import { useSelector } from "react-redux";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useRef } from "react";
import maskEmailsPhones from 'mask-email-phone';
import { Spinner } from "react-bootstrap";
import { showAlertMessage } from "../../utils/sweetAlert";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import IpInfo from "../../components/common/IpInfo";

const Verification = () => {
  //navigate
  const navigate = useNavigate();
  //Auth Login
  const signIn = useSignIn();

  //Get Authentication Data
  const authData = useSelector((state) => state.auth);
  if (!authData.isAuthenticated) {
    showAlertMessage({
      icon: 'error',
      title: 'Error occurred!',
      text: 'Something went wrong. Please try again',
    });
    navigate('/auth/login')
  }
  // const user = {
  //   userDetails: authData.userDetails,
  //   token: authData.token
  // }

  const initialValues = {
    one: '',
    two: '',
    three: '',
    four: '',
    five: '',
    six: '',
  };
  // Form validation
  const validationSchema = Yup.object().shape({
    one: Yup.string()
      .matches(/^\d$/, 'Field must contain a single digit')
      .required('Field is required'),
    two: Yup.string()
      .matches(/^\d$/, 'Field must contain a single digit')
      .required('Field is required'),
    three: Yup.string()
      .matches(/^\d$/, 'Field must contain a single digit')
      .required('Field is required'),
    four: Yup.string()
      .matches(/^\d$/, 'Field must contain a single digit')
      .required('Field is required'),
    five: Yup.string()
      .matches(/^\d$/, 'Field must contain a single digit')
      .required('Field is required'),
    six: Yup.string()
      .matches(/^\d$/, 'Field must contain a single digit')
      .required('Field is required'),
  });
  // Form submit
  const onSubmit = async (values, actions) => {
    try {
      let otp = '';
      for (const key in values) {
        otp += values[key];
      }

      if (otp === atob(authData?.otp)) {
        signIn({
          auth: {
            token: authData.token,
            type: 'Bearer'
          },
          expiresIn: 3600,
          authState: { mobile: values.mobile }
        });
        await showAlertMessage({ text: 'Login successfully' });
        const result = await showAlertMessage({
          title: "Are you sure?",
          text: "This is your trusted device?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, It is.!"
        });
        if (result.isConfirmed) {
          localStorage.setItem(btoa("_session"), btoa(String(true)));
        }
        navigate('/');
      } else {
        showAlertMessage({
          icon: 'error',
          title: 'Error occurred!',
          text: 'Invalid OTP. Please double check.',
        });
      }
    } catch (error) {
      const errorMessage = error.response.data ? error.response.data.message : error.message
      showAlertMessage({
        icon: 'error',
        title: 'Error occurred!',
        text: errorMessage,
      });
    }
    actions.setSubmitting(false);
  }

  const digit2Ref = useRef(null);
  const digit3Ref = useRef(null);
  const digit4Ref = useRef(null);
  const digit5Ref = useRef(null);
  const digit6Ref = useRef(null);

  const handleInputChange = (event, nextRef) => {
    const { value } = event.target;
    if (value.length === 1 && nextRef.current) {
      nextRef.current.focus();
    }
  };

  return (
    <>
      <div className="auth-main">
        <div className="auth-wrapper v3">
          <AuthSideContent className="auth-sidecontent" />
          <div className="auth-form">
            <div className="card my-5">
              <div className="card-body">
                <div className="mb-4">
                  <Logo alt="image" height="200px" />
                  <h3 className="mb-2"><b>Enter Verification Code</b></h3>
                  <p className="text-muted mb-4">We send you on mail and mobile.</p>
                  <p className="">Kindly check code on {authData && maskEmailsPhones(authData?.userDetails?.email)} & {authData && maskEmailsPhones(authData?.userDetails?.mobile)}</p>
                </div>
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="row my-4 text-center">
                        <div className="col">
                          <Field maxLength={1} size={1} className="form-control text-center" name="one" placeholder="0" autoFocus onKeyUp={(e) => handleInputChange(e, digit2Ref)} />
                        </div>
                        <div className="col">
                          <Field maxLength={1} size={1} className="form-control text-center" name="two" placeholder="0"
                            onKeyUp={(e) => handleInputChange(e, digit3Ref)}
                            innerRef={digit2Ref}
                          />
                        </div>
                        <div className="col">
                          <Field maxLength={1} size={1} className="form-control text-center" name="three" placeholder="0"
                            onKeyUp={(e) => handleInputChange(e, digit4Ref)}
                            innerRef={digit3Ref}
                          />
                        </div>
                        <div className="col">
                          <Field maxLength={1} size={1} className="form-control text-center" name="four" placeholder="0"
                            onKeyUp={(e) => handleInputChange(e, digit5Ref)}
                            innerRef={digit4Ref}
                          />
                        </div>
                        <div className="col">
                          <Field maxLength={1} size={1} className="form-control text-center" name="five" placeholder="0"
                            onKeyUp={(e) => handleInputChange(e, digit6Ref)}
                            innerRef={digit5Ref}
                          />
                        </div>
                        <div className="col">
                          <Field maxLength={1} size={1} className="form-control text-center" name="six" placeholder="0"
                            onKeyUp={(e) => handleInputChange(e, digit6Ref)} // Last field, stays in place
                            innerRef={digit6Ref}
                          />
                        </div>
                      </div>
                      <div className="d-grid mt-4">
                        <Button type="submit" className="btn btn-primary" icon={isSubmitting && <Spinner animation="border" size="sm" />} disabled={isSubmitting} text={isSubmitting ? "Processing..." : "Continue"} />
                      </div>
                    </Form>
                  )}
                </Formik>
                <div className="d-flex justify-content-start align-items-end mt-3 mb-5">
                  <p className="mb-0">Did not receive the email?</p>
                  <Link to="#" className="link-primary ms-2">Resend code</Link>
                </div>
                <IpInfo />
              </div>
            </div>
            <div className="auth-footer">
              <div className="m-0 w-100 text-center">
                Proudly Powered By: <Link to="https://www.inshatech.com" target="_blank" className="link-primary">Insha Technologies</Link>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )
};

export default Verification
