import { img_error_500 } from "../../utils/images";
import Error from "../../components/common/Error";

const ServerError = () => {
  return (
    <>
      <Error image={img_error_500} title="Internal Server Error" description="Server error 500. we fixing the problem. please<br/> try again at a later
                  stage." />
    </>
  )
};

export default ServerError
