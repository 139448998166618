import Swal from "sweetalert2";

const showErrorMessage = async (errors) => {
  const errorKeys = Object.keys(errors);
  
  for (let i = 0; i < errorKeys.length; i++) {
    const key = errorKeys[i];
    const error = errors[key];

    return await showAlertMessage({
      icon: 'error',
      title: 'Error occurred!',
      text: error,
      confirmButtonText: "Okay!",
      confirmButtonColor: "#d33",
    });
    
  }
}

const showAlertMessage = async ({ title = "Success!", text, icon = "success", confirmButtonText = "Okay!", confirmButtonColor = "#218838", showCancelButton = false, cancelButtonColor = "#d33" }) => {
  const response = await Swal.fire({
    title: title,
    text: text,
    icon: icon,
    confirmButtonText: confirmButtonText,
    confirmButtonColor: confirmButtonColor,
    showCancelButton: showCancelButton,
    cancelButtonColor: cancelButtonColor,
  });
  return response;
}

export { showErrorMessage, showAlertMessage };
