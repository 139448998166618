import Breadcrumb from "../components/common/Breadcrumb";
import Error from "../components/common/Error";
import { coming_soon } from "../utils/images";

const Dashboard = ({ pageName }) => {
  return (
    <>
      <div className="pc-container">
        <div className="pc-content">
          <Breadcrumb pageName={pageName} />
          <Error image={coming_soon} title="Coming soon...!" isButton={false} pageType="internal" />
        </div>
      </div>
    </>
  )
};

export default Dashboard
