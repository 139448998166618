import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import React, { useState, useEffect } from "react";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { Tag } from "primereact/tag";

const CategoryTable = ({ categoryDetails, userDetails }) => {
  const [categories, setCategories] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setCategories(
      categoryDetails.map((item) => ({
        ...item,
        category: item.category,
        status: item.status.charAt(0).toUpperCase() + item.status.slice(1),
      }))
    );
    setLoading(false);
  }, [categoryDetails, userDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const getSeverity = (value) => {
    switch (value) {
      case "Active":
        return "success";
      case "Inactive":
        return "danger";
      default:
        return null;
    }
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag value={rowData.status} severity={getSeverity(rowData.status)}></Tag>
    );
  };

  return (
    <>
      <div className="dt-responsive table-responsive">
        <DataTable
          size="normal"
          className="table-striped table-hover table-bordered nowrap"
          stripedRows
          showGridlines
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          value={categories}
          sortField="category"
          sortOrder={-1}
          scrollable
          scrollHeight="100%"
          editMode="row"
          dataKey="id"
          tableStyle={{ minWidth: "100%" }}
          loading={loading}
        >
          <Column
            field="_id"
            header="Id"
            style={{ width: "auto", display: "none" }}
          ></Column>
          <Column
            field="category"
            header="Category"
            style={{ width: "auto" }}
            sortable
          ></Column>
          <Column
            field="status"
            body={statusBodyTemplate}
            header="Status"
            sortable
            style={{ width: "auto", textAlign: "center" }}
          ></Column>
        </DataTable>
      </div>
    </>
  );
};

export default CategoryTable;
