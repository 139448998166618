import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { showAlertMessage } from "../../utils/sweetAlert";
import { doLogout } from "../../store/slices/AuthSlice";

const UserMenu = ({className}) => {
  // redux 
  const dispatch = useDispatch();
  // auth toolkit
  const signOut = useSignOut();
  //navigate
  const navigate = useNavigate();
  // logout
  const handleLogout = async () => {
    const result = await showAlertMessage({
      title: "Are you sure?",
      text: "You want to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Logout!"
    });
    if (result.isConfirmed) {
      dispatch(doLogout());
      signOut();
      navigate('/auth/login');
      localStorage.removeItem("_Auth_id");
      window.location.reload();
    }
  }

  return (
    <>
      <div className={className}>
        <Link to="/account/profile" className="dropdown-item">
          <i className="ti ti-user"></i>
          <span>My Account</span>
        </Link>
        <Link to="account/invoice" className="dropdown-item">
          <i className="ti ti-receipt"></i>
          <span>Invoice</span>
        </Link>
        <Link to="https://wa.me/918888191926" target="_blank" className="dropdown-item">
          <i className="ti ti-headset"></i>
          <span>Support</span>
        </Link>
        {/* <Link to="" className="dropdown-item">
          <i className="ti ti-lock"></i>
          <span>Lock Screen</span>
        </Link> */}
        <Link onClick={() => handleLogout()} className="dropdown-item">
          <i className="ti ti-power"></i>
          <span>Logout</span>
        </Link>
      </div>
    </>
  )
};

export default UserMenu
