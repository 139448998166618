import React, { useEffect, useState } from "react";
import { BsFillMoonFill, BsFillSunFill, BsPalette, BsPaletteFill, BsX } from "react-icons/bs";
import { FcSettings } from "react-icons/fc";
import { Link } from "react-router-dom";
import Button from "./common/Button";
import { img_caption_1, img_caption_2, img_container_1, img_container_2, img_layout_1, img_layout_2 } from "../utils/images";

const Setting = () => {
  const getInitialTheme = () => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      return savedTheme;
    } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      return 'dark';
    } else {
      return 'light';
    }
  };
  const [theme, setTheme] = useState(getInitialTheme);
  const [themeContrast, setThemeContrast] = useState(localStorage.getItem('themeContrast') || 'false');
  const [preset, setPreset] = useState(localStorage.getItem('preset') || 'preset-1');
  const [sidebarCaption, setSidebarCaption] = useState(localStorage.getItem('sidebarCaption') || 'true');
  const [isRTL, setIsRTL] = useState(localStorage.getItem('isRTL') === 'true');
  const [isContainer, setIsContainer] = useState(localStorage.getItem('isContainer') === 'true');
  const [reset, setReset] = useState(true);

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    //Theme Scheme
    localStorage.setItem('theme', theme);
    body.setAttribute('data-pc-theme', theme);
    //Theme Contrast Scheme
    body.setAttribute('data-pc-theme_contrast', themeContrast);
    localStorage.setItem('themeContrast', themeContrast);
    //Theme Color Scheme
    body.setAttribute('data-pc-preset', preset);
    localStorage.setItem('preset', preset);
    //Theme Sidebar Caption Scheme
    body.setAttribute('data-pc-sidebar-caption', sidebarCaption);
    localStorage.setItem('sidebarCaption', sidebarCaption);
    //Theme Rtl Scheme
    const direction = isRTL ? 'rtl' : 'ltr';
    const html = document.getElementsByTagName('html')[0];
    body.setAttribute('data-pc-direction', direction);
    if (isRTL) {
      html.setAttribute('dir', 'rtl');
    } else {
      html.removeAttribute('dir');
    }
    localStorage.setItem('isRTL', isRTL);
    //Theme Layout With Scheme
    const updateLayout = (isContainer) => {
      const content = document.querySelector('.pc-content');
      const footer = document.querySelector('.footer-wrapper');

      if (content && footer) {
        if (isContainer) {
          content.classList.add('container');
          footer.classList.add('container');
          footer.classList.remove('container-fluid');
        } else {
          content.classList.remove('container');
          footer.classList.remove('container');
          footer.classList.add('container-fluid');
        }
      }
    };

    updateLayout(isContainer);
    localStorage.setItem('isContainer', isContainer);

  }, [theme, themeContrast, preset, sidebarCaption, isRTL, isContainer]);
  // Theme Scheme
  const handleLayoutChange = (themeName) => {
    setTheme(themeName);
  };

  //Theme System Theme Scheme
  const handleSystemTheme = () => {
    const systemTheme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    setTheme(systemTheme);
  };

  //Theme Contrast Theme
  function handleLayoutSidebarChange(value) {
    setThemeContrast(value);
  }

  //Theme Color Scheme
  const handlePresetChange = (e) => {
    const value = e.target.dataset.value;
    setPreset(value);
  };
  //Theme Sidebar Caption Scheme
  const handleLayoutCaptionChange = (value) => {
    setSidebarCaption(value);
  };
  //Theme Rtl Scheme
  const handleLayoutRtlChange = (value) => {
    setIsRTL(value === 'true');
  };
  //Theme Layout With Scheme
  const handleChangeBoxContainer = (value) => {
    setIsContainer(value === 'true');
  };
  //Theme Layout Reset
  const resetLayout = (reset) => {
    setTheme('light')
    setThemeContrast('false');
    setPreset('preset-1');
    setSidebarCaption('true');
    setIsRTL(!'false');
    setIsContainer(!'false')
    setReset(!reset);
  }
  return (
    <div className="setting-container" style={{ overflowY: "auto" }}>
      <div className="pct-c-btn">
        <Link to="#" data-bs-toggle="offcanvas" data-bs-target="#offcanvas_pc_layout">
          <FcSettings className="pc-icon" />
        </Link>
      </div>
      <div className="offcanvas border-0 pct-offcanvas offcanvas-end" tabIndex="-1" id="offcanvas_pc_layout">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title">Settings</h5>
          <Button type="button" className="btn btn-icon btn-link-danger" dataBsDismiss="offcanvas" ariaLabel="Close" icon={<BsX />} />
        </div>
        <div className="pct-body" style={{ height: "calc(100% - 85px)", overflowY: "auto" }}>
          <div className="offcanvas-body py-0">
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <div className="pc-dark">
                  <h6 className="mb-1">Theme Mode</h6>
                  <p className="text-muted text-sm">Choose light or dark mode or Auto</p>
                  <div className="row theme-layout">
                    <div className="col-4">
                      <div className="d-grid">
                        <Button className={`preset-btn btn ${theme === 'light' ? 'active' : ''}`} data_value="true" onClick={() => handleLayoutChange('light')} icon={<BsFillSunFill className="pc-icon" color='#B97B1E' />} />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="d-grid">
                        <Button className={`preset-btn btn ${theme === 'dark' ? 'active' : ''}`} data_value="false" onClick={() => handleLayoutChange('dark')} icon={<BsFillMoonFill className="pc-icon" />} />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="d-grid">
                        <Button className={`preset-btn btn ${theme !== 'light' && theme !== 'dark' ? 'active' : ''}`} data_value="default" onClick={() => handleSystemTheme()} icon={<FcSettings className="pc-icon" />} />
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="list-group-item">
                <h6 className="mb-1">Theme Contrast</h6>
                <p className="text-muted text-sm">Choose theme contrast</p>
                <div className="row theme-contrast">
                  <div className="col-6">
                    <div className="d-grid">
                      <Button className={`preset-btn btn ${themeContrast === 'true' ? 'active' : ''}`} data_value="true" onClick={() => handleLayoutSidebarChange('true')} icon={<BsPaletteFill className="pc-icon" />} />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-grid">
                      <Button className={`preset-btn btn ${themeContrast === 'false' ? 'active' : ''}`} data_value="false" onClick={() => handleLayoutSidebarChange('false')} icon={<BsPalette className="pc-icon" />} />
                    </div>
                  </div>
                </div>
              </li>
              <li className="list-group-item">
                <h6 className="mb-1">Custom Theme</h6>
                <p className="text-muted text-sm">Choose your Primary color</p>
                <div className="theme-color preset-color">
                  <Link className={preset === "preset-1" ? 'active' : ''} data-value="preset-1" onClick={handlePresetChange}><i className="ti ti-check"></i></Link>
                  <Link className={preset === "preset-2" ? 'active' : ''} data-value="preset-2" onClick={handlePresetChange}><i className="ti ti-check"></i></Link>
                  <Link className={preset === "preset-3" ? 'active' : ''} data-value="preset-3" onClick={handlePresetChange}><i className="ti ti-check"></i></Link>
                  <Link className={preset === "preset-4" ? 'active' : ''} data-value="preset-4" onClick={handlePresetChange}><i className="ti ti-check"></i></Link>
                  <Link className={preset === "preset-5" ? 'active' : ''} data-value="preset-5" onClick={handlePresetChange}><i className="ti ti-check"></i></Link>
                  <Link className={preset === "preset-6" ? 'active' : ''} data-value="preset-6" onClick={handlePresetChange}><i className="ti ti-check"></i></Link>
                  <Link className={preset === "preset-7" ? 'active' : ''} data-value="preset-7" onClick={handlePresetChange}><i className="ti ti-check"></i></Link>
                  <Link className={preset === "preset-8" ? 'active' : ''} data-value="preset-8" onClick={handlePresetChange}><i className="ti ti-check"></i></Link>
                  <Link className={preset === "preset-9" ? 'active' : ''} data-value="preset-9" onClick={handlePresetChange}><i className="ti ti-check"></i></Link>
                  <Link className={preset === "preset-10" ? 'active' : ''} data-value="preset-10" onClick={handlePresetChange}><i className="ti ti-check"></i></Link>
                </div>
              </li>
              <li className="list-group-item">
                <h6 className="mb-1">Sidebar Caption</h6>
                <p className="text-muted text-sm">Sidebar Caption Hide/Show</p>
                <div className="row theme-nav-caption">
                  <div className="col-6">
                    <div className="d-grid">
                      <button className={`preset-btn btn ${sidebarCaption === 'true' ? 'active' : ''}`} data_value="true" onClick={() => handleLayoutCaptionChange('true')}>
                        {/* Replace with your image */}
                        <img src={img_caption_1} alt="img" className="img-fluid" width="70%" />
                      </button>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-grid">
                      <button className={`preset-btn btn ${sidebarCaption === 'false' ? 'active' : ''}`} data_value="false" onClick={() => handleLayoutCaptionChange('false')}>
                        {/* Replace with your image */}
                        <img src={img_caption_2} alt="img" className="img-fluid" width="70%" />
                      </button>
                    </div>
                  </div>
                </div>
              </li>
              <li className="list-group-item">
                <div className="pc-rtl">
                  <h6 className="mb-1">Theme Layout</h6>
                  <p className="text-muted text-sm">LTR/RTL</p>
                  <div className="row theme-direction">
                    <div className="col-6">
                      <div className="d-grid">
                        <button className={`preset-btn btn ${!isRTL ? 'active' : ''}`} data_value="false" onClick={() => handleLayoutRtlChange('false')}>
                          {/* Replace with your image */}
                          <img src={img_layout_1} alt="img" className="img-fluid" width="70%" />
                        </button>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="d-grid">
                        <button className={`preset-btn btn ${isRTL ? 'active' : ''}`} data_value="true" onClick={() => handleLayoutRtlChange('true')}>
                          {/* Replace with your image */}
                          <img src={img_layout_2} alt="img" className="img-fluid" width="70%" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="list-group-item">
                <div className="pc-container-width">
                  <h6 className="mb-1">Layout Width</h6>
                  <p className="text-muted text-sm">Choose Full or Container Layout</p>
                  <div className="row theme-container">
                    <div className="col-6">
                      <div className="d-grid">
                        <button className={`preset-btn btn ${!isContainer ? 'active' : ''}`} data_value="false" onClick={() => handleChangeBoxContainer('false')}>
                          {/* Replace with your image */}
                          <img src={img_container_1} alt="img" className="img-fluid" width="70%" />
                        </button>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="d-grid">
                        <button className={`preset-btn btn ${isContainer ? 'active' : ''}`} data_value="true" onClick={() => handleChangeBoxContainer('true')}>
                          {/* Replace with your image */}
                          <img src={img_container_2} alt="img" className="img-fluid" width="70%" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="list-group-item">
                <div className="d-grid">
                  <Button className="btn btn-light-danger" id="layoutreset" text="Reset Layout" onClick={()=>resetLayout(reset)} />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setting;
