import Breadcrumb from "../../components/common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Button from "../../components/common/Button"
import ClassForm from "../../components/class/ClassForm";
import ClassTable from "../../components/class/ClassTable";
import { fetchClass } from "../../store/slices/ClassSlice";

const Class = () => {
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state.user);
  useEffect(() => {
    dispatch(fetchClass({
      institute: userDetails?.institute?._id
    }))
  }, [dispatch, userDetails]);
  const { classDetails } = useSelector((state) => state.class);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <section className="pc-container">
        <ClassForm show={show} handleClose={handleClose} userDetails={userDetails}/>
        <div className="pc-content">
          <Breadcrumb />
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div id="sticky-action" className="sticky-action">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col-sm-6">
                        <h5>Manage Class</h5>
                      </div>
                      <div className="col-sm-6 text-sm-end mt-3 mt-sm-0">
                        {/* <button type="reset" className="btn btn-success">Add New</button> */}
                        <Button onClick={handleShow} className="btn btn-success me-2" text="Add New" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <ClassTable classDetails={classDetails} userDetails={userDetails} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
};

export default Class
