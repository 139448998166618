import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postData, putData } from "../../api";

const fetchSubCaste = createAsyncThunk('fetchSubCaste', async (data, thunkAPI) => {
  try {
    const response = await postData('administration/caste/sub-caste/all', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const putSubCaste = createAsyncThunk('putSubCaste', async (data, thunkAPI) => {
  try {
    const response = await putData('administration/caste/sub-caste/update', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const createSubCaste = createAsyncThunk('createSubCaste', async (data, thunkAPI) => {
  try {
    const response = await postData('administration/caste/sub-caste/add', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const SubCasteSlice = createSlice({
  name: 'subCasteSlice',
  initialState: {
    isLoading: false,
    isError: false,
    subCasteDetails: [],
  },
  extraReducers: (builder) => {
    builder
      //fetch
      .addCase(fetchSubCaste.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchSubCaste.fulfilled, (state, action) => {
        state.isLoading = false;
        state.subCasteDetails = action.payload.records;
        state.isError = false;
      })
      .addCase(fetchSubCaste.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
      //put
      .addCase(putSubCaste.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(putSubCaste.fulfilled, (state, action) => {
        state.isLoading = false;
        const updatedSubCaste = action.payload.response;
        const index = state.subCasteDetails.findIndex(item => item._id === updatedSubCaste._id);
        if (index !== -1) {
          // Create a new array with the updated item
          state.subCasteDetails[index] = updatedSubCaste;
        } else {
          // Handle if the item is not found, possibly add it to the array
          state.subCasteDetails.push(updatedSubCaste);
        }
        state.isError = false;
      })
      .addCase(putSubCaste.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
      //create
      .addCase(createSubCaste.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(createSubCaste.fulfilled, (state, action) => {
        state.isLoading = false;
        state.subCasteDetails.push(action.payload.response);
        state.isError = false;
      })
      .addCase(createSubCaste.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
  }
})

export { SubCasteSlice, fetchSubCaste, putSubCaste, createSubCaste }
export const { subCasteDetails } = SubCasteSlice.actions;