import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postData, putData } from "../../api";

const fetchClass = createAsyncThunk('fetchClass', async (data, thunkAPI) => {
  try {
    const response = await postData('administration/class/all', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const putClass = createAsyncThunk('putClass', async (data, thunkAPI) => {
  try {
    const response = await putData('administration/class/update', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const createClass = createAsyncThunk('createClass', async (data, thunkAPI) => {
  try {
    const response = await postData('administration/class/add', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const ClassSlice = createSlice({
  name: 'classSlice',
  initialState: {
    isLoading: false,
    isError: false,
    classDetails: [],
  },
  extraReducers: (builder) => {
    builder
      //fetch
      .addCase(fetchClass.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchClass.fulfilled, (state, action) => {
        state.isLoading = false;
        state.classDetails = action.payload.records;
        state.isError = false;
      })
      .addCase(fetchClass.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
      //put
      .addCase(putClass.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(putClass.fulfilled, (state, action) => {
        state.isLoading = false;
        const updatedClass = action.payload.response;
        const index = state.classDetails.findIndex(item => item._id === updatedClass._id);
        if (index !== -1) {
          // Create a new array with the updated item
          state.classDetails[index] = updatedClass;
        } else {
          // Handle if the item is not found, possibly add it to the array
          state.classDetails.push(updatedClass);
        }
        state.isError = false;
      })
      .addCase(putClass.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
      //create
      .addCase(createClass.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(createClass.fulfilled, (state, action) => {
        state.isLoading = false;
        state.classDetails.push(action.payload.response);
        state.isError = false;
      })
      .addCase(createClass.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
  }
})

export { ClassSlice, fetchClass, putClass, createClass }
export const { classDetails } = ClassSlice.actions;