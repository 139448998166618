import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import Select from 'react-select';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { fetchClass } from "../../../store/slices/ClassSlice";
import { fetchDivision } from "../../../store/slices/DivisionSlice";
import { showAlertMessage } from "../../../utils/sweetAlert";
import Button from "../../common/Button";
import { fetchAdmission } from "../../../store/slices/AdmissionSlice";

const AdmissionSearchForm = ({ userDetails }) => {
  const dispatch = useDispatch();
  const [prams, setPrams] = useState([]);

  //Fetch admissions
  useEffect(() => {
    dispatch(fetchAdmission(prams))
  }, [dispatch, prams]);


  //Get Standards (Class)
  const [standardOptions, setStandardOptions] = useState([]);
  useEffect(() => {
    dispatch(fetchClass({
      institute: userDetails?.institute?._id
    }))
  }, [dispatch, userDetails])
  const { classDetails } = useSelector((state) => state.class);
  useEffect(() => {
    const getStandards = async () => {
      setStandardOptions(() => classDetails.map(item => ({ value: item.class, label: item.class })));
    }
    getStandards()
  }, [classDetails])

  // Divisions
  const { divisionDetails } = useSelector((state) => state.division);
  const [divisions, setDivisions] = useState([])
  const handleCurrentClassChange = (option, setFieldValue) => {
    setFieldValue('class', option ? option.value : '');
    setDivisions([])
    dispatch(fetchDivision({ institute: userDetails?.institute?._id, class: option.value }))
  }

  useEffect(() => {
    const getDivisions = async () => {
      setDivisions(() => divisionDetails.map(item => ({ value: item.division, label: item.division })));
    }
    getDivisions()
  }, [divisionDetails])
  const initialValues = {
    institute: userDetails?.institute?._id,
    udise: userDetails?.institute?.udise,
    session: userDetails?.institute?.active_session?.session,
    class: '',
    division: '',
    isLeaved: false,
  }
  // Form validation
  const validationSchema = Yup.object().shape({
    class: Yup.string()
      .required('Class is required'),
    division: Yup.string()
      .required('Division is required'),
  });
  // Form submit
  const onSubmit = async (values, actions) => {
    try {
      setPrams(values);
    } catch (error) {
      showAlertMessage({
        icon: 'error',
        title: 'Error occurred!',
        text: error.message,
      });
    } finally {
      actions.setSubmitting(false);
    }
  };
  // const statusOptions = [
  //   {value: false, label: 'Active'},
  //   {value: true, label: 'Leaved'}
  // ]

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({ isSubmitting, setFieldValue, values, handleBlur, resetForm }) => (
          <Form>
            <div className="row text-sm-start">
              <div className="col-sm-5">
                <div className="form-group">
                  <Select
                    name="class"
                    options={standardOptions}
                    value={standardOptions.find(option => option.value === values.class)}
                    onChange={(option) => handleCurrentClassChange(option, setFieldValue)}
                    onBlur={handleBlur('class')}
                    placeholder="Class"
                    autoFocus
                  />
                  <ErrorMessage className="text-danger mb-1" name="class" component="div" />
                </div>
              </div>
              <div className="col-sm-5">
                <div className="form-group">
                  <Select
                    name="division"
                    cacheOptions
                    defaultOptions
                    options={divisions}
                    value={divisions.find(option => option.value === values.division)}
                    onChange={(option) => setFieldValue('division', option ? option.value : '')}
                    onBlur={handleBlur('division')}
                    placeholder="Division"
                  />
                  <ErrorMessage className="text-danger mb-1" name="division" component="div" />
                </div>
              </div>
              {/* <div className="col-sm-3">
                <div className="form-group">
                  <Select
                    name="isLeaved"
                    cacheOptions
                    defaultOptions
                    options={statusOptions}
                    value={statusOptions.find(option => option.value === values.isLeaved)}
                    onChange={(option) => setFieldValue('isLeaved', option ? option.value : '')}
                    onBlur={handleBlur('isLeaved')}
                    placeholder="Status"
                  />
                  <ErrorMessage className="text-danger mb-1" name="isLeaved" component="div" />
                </div>
              </div> */}
              <div className="col-sm-2">
                <Button className="btn btn-light-primary" type="submit" icon={isSubmitting && <Spinner animation="border" size="sm" />} disabled={isSubmitting} text={isSubmitting ? "Processing..." : "Search..."} />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
};

export default AdmissionSearchForm
