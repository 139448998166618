import { Link, useNavigate } from "react-router-dom";
import AuthSideContent from "../../components/common/AuthSideContent";
import Logo from "../../components/common/Logo";
import Label from "../../components/common/Label";
import Button from "../../components/common/Button";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { showAlertMessage } from "../../utils/sweetAlert";
import { Spinner } from "react-bootstrap";
import { patchData } from "../../api";
import IpInfo from "../../components/common/IpInfo";

const ForgotPassword = () => {
  //navigate
  const navigate = useNavigate();
  // Form initial values
  const initialValues = {
    mobile: '',
  };
  // Form validation
  const validationSchema = Yup.object().shape({
    mobile: Yup.string()
      .matches(/^[6-9]\d{9}$/, 'Invalid Indian mobile number')
      .required('Mobile number is required')
  });
  // Form submit
  const onSubmit = async (values, actions) => {
    try {
      const response = await patchData('administration/user/auth/reset-password', values);
      await showAlertMessage({ text: response.message });
      navigate('/');
    } catch (error) {
      const  errorMessage = error.response.data ? error.response.data.message : error.message
      showAlertMessage({
        icon: 'error',
        title: 'Error occurred!',
        text: errorMessage,
      });
    }
    actions.setSubmitting(false);
  };
  return (
    <>
      <div className="auth-main">
        <div className="auth-wrapper v3">
          <AuthSideContent className="auth-sidecontent" />
          <div className="auth-form">
            <div className="card my-5">
              <div className="card-body">
                <Logo alt="image" height="200px" />
                <div className="d-flex justify-content-between align-items-end mb-4">
                  <h3 className="mb-0">
                    <b>Forgot Password</b>
                  </h3>
                  <Link to="/auth/login" className="link-primary">Back to Login</Link>
                </div>
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="form-group mb-3">
                        <Label className="form-label" title="Mobile" />
                        <Field
                          className="form-control"
                          name="mobile"
                          placeholder="Mobile"
                          maxLength={10}
                          size={10}
                          autoFocus
                        />
                        <ErrorMessage className="text-danger mb-1" name="mobile" component="div" />
                      </div>
                      <p className="mt-4 text-sm text-muted">Do not forgot to check SPAM box or SMS.</p>
                      <div className="d-grid mt-3 mb-5">
                        <Button type="submit" className="btn btn-primary"
                          icon={isSubmitting && <Spinner animation="border" size="sm" />} disabled={isSubmitting} text={isSubmitting ? "Processing..." : "Reset Password"}
                        />
                      </div>
                      <IpInfo/>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="auth-footer">
              <div className="m-0 w-100 text-center">
                Proudly Powered By: <Link to="https://www.inshatech.com" target="_blank" className="link-primary">Insha Technologies</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default ForgotPassword
