import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { patchData, postData, putData } from "../../api";

const fetchTC = createAsyncThunk('fetchTC', async (data, thunkAPI) => {
  try {
    const response = await postData('administration/transfer-certificate/all', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const putTC = createAsyncThunk('putTC', async (data, thunkAPI) => {
  try {
    const response = await putData('administration/transfer-certificate/update', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const patchUnlockTC = createAsyncThunk('patchUnlockTC', async (data, thunkAPI) => {
  try {
    const response = await patchData('administration/transfer-certificate/unlock', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const createTC = createAsyncThunk('createTC', async (data, thunkAPI) => {
  try {
    const response = await postData('administration/transfer-certificate/add', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const TCSlice = createSlice({
  name: 'tcSlice',
  initialState: {
    isLoading: false,
    isError: false,
    tcDetails: [],
  },
  extraReducers: (builder) => {
    builder
      //fetch
      .addCase(fetchTC.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchTC.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tcDetails = action.payload.records;
        state.isError = false;
      })
      .addCase(fetchTC.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
      //put
      .addCase(putTC.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(putTC.fulfilled, (state, action) => {
        state.isLoading = false;
        const updatedTC = action.payload.response;
        const index = state.tcDetails.findIndex(item => item._id === updatedTC._id);
        if (index !== -1) {
          // Replace the existing object with the updated object
          state.tcDetails[index] = { ...state.tcDetails[index], ...updatedTC };
        } else {
          // Handle if the item is not found, possibly add it to the array (though not likely in an update case)
          state.tcDetails.push(updatedTC);
        }
        state.isError = false;
      })
      .addCase(putTC.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
      //create
      .addCase(createTC.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(createTC.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tcDetails.push(action.payload.response);
        state.isError = false;
      })
      .addCase(createTC.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
      //patch
      .addCase(patchUnlockTC.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(patchUnlockTC.fulfilled, (state, action) => {
        state.isLoading = false;
        const updatedTC = action.payload.response;
        const index = state.tcDetails.findIndex(item => item._id === updatedTC._id);
        if (index !== -1) {
          // Replace the existing object with the updated object
          state.tcDetails[index] = { ...state.tcDetails[index], ...updatedTC };
        } else {
          // Handle if the item is not found, possibly add it to the array (though not likely in an update case)
          state.tcDetails.push(updatedTC);
        }
        state.isError = false;
      })
      .addCase(patchUnlockTC.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
  }
})

export { TCSlice, fetchTC, putTC, createTC, patchUnlockTC }
export const { tcDetails } = TCSlice.actions;