
import { showAlertMessage } from "../../utils/sweetAlert";
import { useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { fetchCities, fetchCountries, fetchStates } from "../../store/slices/CSCSlice";
import Select from 'react-select';
import { ErrorMessage, Field, Form, Formik } from "formik";
import Button from "../common/Button";
import { Spinner } from "react-bootstrap";
import { putInstitute } from "../../store/slices/InstituteSlice";
import { Templates } from "../../utils/common";

const InfoForm = ({ instituteDetails }) => {

  const dispatch = useDispatch();
  // Country options
  const { countries: countriesList, states: statesList, cities: citiesList } = useSelector((state) => state.csc);
  const [countries, setCountries] = useState([])
  useEffect(() => {
    dispatch(fetchCountries())
  }, [dispatch])

  useEffect(() => {
    const getCountries = async () => {
      setCountries(() => countriesList.map(country => ({ value: country._id, label: country.name })));
    }
    getCountries()
  }, [countriesList])

  // State options
  const [states, setStates] = useState([])
  const handleCountryChange = (option, setFieldValue) => {
    setFieldValue('country', option ? option.value : '');
    dispatch(fetchStates({ country: option.value }))
  };
  useEffect(() => {
    const getStates = async () => {
      setStates(() => statesList.map(state => ({ value: state._id, label: state.name })));
    }
    getStates()
  }, [statesList])

  // City options
  const [cities, setCities] = useState([])
  const handleStateChange = (option, setFieldValue) => {
    setFieldValue('state', option ? option.value : '');
    dispatch(fetchCities({ state: option.value }))
  };
  useEffect(() => {
    const getCities = async () => {
      setCities(() => citiesList.map(city => ({ value: city._id, label: city.name })));
    }
    getCities()
  }, [citiesList])

  // Form initial values
  const initialValues = useMemo(() => ({
    institute_name: instituteDetails?.institute_name,
    institute_name_IL: instituteDetails?.inLanguage?.institute_name,
    organization_name: instituteDetails?.organization_name,
    organization_name_IL: instituteDetails?.inLanguage?.organization_name,
    mobile: instituteDetails?.mobile,
    email: instituteDetails?.email,
    udise: instituteDetails?.udise,
    contact: instituteDetails?.contact,
    address: instituteDetails?.address,
    address_IL: instituteDetails?.inLanguage?.address,
    country: instituteDetails?.country?._id,
    state: instituteDetails?.state?._id,
    city: instituteDetails?.city?._id,
    permission_details: instituteDetails?.permission_details,
    pincode: instituteDetails?.pincode,
    tcTemplate: instituteDetails?.tcTemplate ? instituteDetails?.tcTemplate : "1",
  }), [instituteDetails]);
  // Form validation
  const validationSchema = Yup.object().shape({
    institute_name: Yup.string()
      .required('Institute name is required'),
    organization_name: Yup.string()
      .required('Organization name is required'),
    permission_details: Yup.string()
      .required('Permission details is required'),
    udise: Yup.string()
      .matches(/^27\d{0,10}$/, 'UDISE number must start with 27 and have a maximum length of 11')
      .required('UDISE number is required'),
    mobile: Yup.string()
      .matches(/^[6-9]\d{9}$/, 'Invalid Indian mobile number')
      .required('Mobile number is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    address: Yup.string()
      .required('Address is required'),
    country: Yup.string()
      .required('County is required'),
    state: Yup.string()
      .required('State is required'),
    city: Yup.string()
      .required('City is required'),
    pincode: Yup.string()
      .required('Pincode is required'),
  });
  // Form submit
  const onSubmit = async (values, actions) => {
    try {
      const response = await dispatch(putInstitute({
        _id: instituteDetails._id,
        query: {...values, inLanguage: {
          institute_name: values?.institute_name_IL,
          organization_name: values?.organization_name_IL,
          address: values?.address_IL
        }}
      }));
      if (response?.payload?.status === 'success') {
        await showAlertMessage({ text: response?.payload?.message });
      } else {
        throw new Error(response?.payload?.response?.data.message);
      }
    } catch (error) {
      showAlertMessage({
        icon: 'error',
        title: 'Error occurred!',
        text: error.message,
      });
    } finally {
      actions.setSubmitting(false);
    }
  };

  useEffect(() => {
    dispatch(fetchStates({ country: initialValues.country }))
    dispatch(fetchCities({ state: initialValues.state }))
  }, [dispatch, initialValues]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({ isSubmitting, setFieldValue, values, handleBlur }) => (
          <Form>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">Institution Name*</label>
                    <Field
                      className="form-control"
                      name="institute_name"
                      autoFocus
                    />
                    <ErrorMessage className="text-danger mb-1" name="institute_name" component="div" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">Institution Name (Indian Language)</label>
                    <Field
                      className="form-control"
                      name="institute_name_IL"
                    />
                    <ErrorMessage className="text-danger mb-1" name="institute_name_IL" component="div" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">Organization Name*</label>
                    <Field
                      className="form-control"
                      name="organization_name"
                    />
                    <ErrorMessage className="text-danger mb-1" name="organization_name" component="div" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">Organization Name (Indian Language)</label>
                    <Field
                      className="form-control"
                      name="organization_name_IL"
                    />
                    <ErrorMessage className="text-danger mb-1" name="organization_name_IL" component="div" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">UDISE*</label>
                    <Field
                      className="form-control"
                      name="udise"
                      readOnly="true"
                    />
                    <ErrorMessage className="text-danger mb-1" name="udise" component="div" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">Mobile*</label>
                    <Field
                      className="form-control"
                      name="mobile"
                      maxLength={10}
                      size={10}
                    />
                    <ErrorMessage className="text-danger mb-1" name="mobile" component="div" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">Email*</label>
                    <Field
                      className="form-control"
                      name="email"
                      type="email"
                    />
                    <ErrorMessage className="text-danger mb-1" name="email" component="div" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">Contact</label>
                    <Field
                      className="form-control"
                      name="contact"
                    />
                    <ErrorMessage className="text-danger mb-1" name="contact" component="div" />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Address*</label>
                    <Field
                      className="form-control"
                      name="address"
                    />
                    <ErrorMessage className="text-danger mb-1" name="address" component="div" />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Address (India Language)</label>
                    <Field
                      className="form-control"
                      name="address_IL"
                    />
                    <ErrorMessage className="text-danger mb-1" name="address_IL" component="div" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">Country*</label>
                    <Select
                      name="country"
                      cacheOptions
                      defaultOptions
                      options={countries}
                      value={countries.find(option => option.value === values.country)}
                      onChange={(option) => handleCountryChange(option, setFieldValue)}
                      onBlur={handleBlur('country')}
                    />
                    <ErrorMessage className="text-danger mb-1" name="country" component="div" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">State*</label>
                    <Select
                      name="state"
                      cacheOptions
                      defaultOptions
                      options={states}
                      value={states.find(option => option.value === values.state)}
                      onChange={(option) => handleStateChange(option, setFieldValue)}
                      onBlur={handleBlur('state')}
                    />
                    <ErrorMessage className="text-danger mb-1" name="state" component="div" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">City*</label>
                    <Select
                      name="city"
                      cacheOptions
                      defaultOptions
                      options={cities}
                      value={cities.find(option => option.value === values.city)}
                      onChange={(option) => setFieldValue('city', option ? option.value : '')}
                      onBlur={handleBlur('city')}
                    />
                    <ErrorMessage className="text-danger mb-1" name="city" component="div" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">Pin code*</label>
                    <Field
                      className="form-control form-control-sm"
                      name="pincode"
                      maxLength={6}
                      size={6}
                    />
                    <ErrorMessage className="text-danger mb-1" name="pincode" component="div" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">Permission Details*</label>
                    <Field
                      className="form-control"
                      name="permission_details"
                    />
                    <ErrorMessage className="text-danger mb-1" name="permission_details" component="div" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">TC Template</label>
                    <Select
                      name="tcTemplate"
                      cacheOptions
                      defaultOptions
                      options={Templates}
                      value={Templates.find(option => option.value === values.tcTemplate)}
                      onChange={(option) => setFieldValue('tcTemplate', option ? option.value : '')}
                      onBlur={handleBlur('tcTemplate')}
                    />
                    <ErrorMessage className="text-danger mb-1" name="tcTemplate" component="div" />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer bg-light border-0">
              <div className="row">
                <label className="col-lg-4"></label>
                <div className="col-lg-8">
                  <Button className="btn btn-primary" type="submit" icon={isSubmitting && <Spinner animation="border" size="sm" />} disabled={isSubmitting} text={isSubmitting ? "Processing..." : "Update Now"} />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
};

export default InfoForm
