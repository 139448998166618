import { Spinner } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { showAlertMessage } from '../../../utils/sweetAlert';
import Button from '../../common/Button';
import { avatar_2 } from '../../../utils/images';
import { putAdmission } from '../../../store/slices/AdmissionSlice';

const MoveStudentForm = ({ show, handleClose, userDetails, student }) => {

  const { divisionDetails } = useSelector((state) => state.division);
  const [divisions, setDivisions] = useState([])
  useEffect(() => {
    const getDivisions = async () => {
      setDivisions(() => divisionDetails.map(item => ({ value: item.division, label: item.division })));
    }
    getDivisions()
  }, [divisionDetails, student])

  const dispatch = useDispatch();
  // Form initial values
  const initialValues = {
    udise: userDetails?.institute?.udise,
    institute: userDetails?.institute?._id,
    _id: student?._id,
    class: student?.class,
    division: student?.division,
    user: userDetails?._id
  }
  // Form validation
  const validationSchema = Yup.object().shape({
    division: Yup.string()
      .required('Division is required'),
  });
  // Form submit
  const onSubmit = async (values, actions) => {
    try {
      if (values.division !== student.division) {
        const response = await dispatch(putAdmission({
          _id: values._id,
          query:{
            student: {
              division: values.division
            }
          }
        }));
        if (response?.payload?.status === 'success') {
          await showAlertMessage({ text: response?.payload?.message });
          handleClose(true)
        } else {
          throw new Error(response?.payload?.response?.data.message);
        }
      }else{
        showAlertMessage({
          icon: 'error',
          title: 'Error occurred!',
          text: 'No any change found!',
        });
      }
    } catch (error) {
      showAlertMessage({
        icon: 'error',
        title: 'Error occurred!',
        text: error.message,
      });
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <>
      <Offcanvas show={show} onHide={handleClose} key="end" placement="end" name="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Move Student</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="d-flex align-items-center mb-3">
            <div className="flex-shrink-0">
              <img src={student?.image ? student?.image : avatar_2} alt="" className="user-avtar wid-45 rounded-circle" />
            </div>
            <div className="flex-grow-1 ms-3 me-2">
              <h6 className="mb-0">{student?.name}</h6>
              <small>{student?.parent?.f_name}</small>
              <br />
              <small>{`${student?.class} - ${student?.division}`}</small>
            </div>
          </div>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize={true}
          >
            {({ isSubmitting, setFieldValue, values, handleBlur, resetForm }) => (
              <Form>
                <div className="form-group">
                  <label className="form-label">Class*</label>
                  <Field
                    className="form-control"
                    name="class"
                    disabled
                  />
                  <ErrorMessage className="text-danger mb-1" name="class" component="div" />
                </div>
                <div className="form-group">
                  <label className="form-label">Division*</label>
                  <Select
                    name="division"
                    options={divisions}
                    value={divisions.find(option => option.value === values.division)}
                    onChange={(option) => setFieldValue('division', option ? option.value : '')}
                    onBlur={handleBlur('division')}
                  />
                  <ErrorMessage className="text-danger mb-1" name="standard" component="div" />
                </div>
                <div className="card-footer text-end btn-page">
                  <Button className="btn btn-outline-danger" onClick={() => resetForm()} text="Cancel" />
                  <Button className="btn btn-primary" type="submit" icon={isSubmitting && <Spinner animation="border" size="sm" />} disabled={isSubmitting} text={isSubmitting ? "Processing..." : "Move Now"} />
                </div>
              </Form>
            )}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
};

export default MoveStudentForm
