import { Spinner } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from '../../common/Button';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { showAlertMessage } from '../../../utils/sweetAlert';
import * as Yup from 'yup';
import Select from 'react-select';
import { statusOptions } from '../../../utils/common';
import { useDispatch } from 'react-redux';
import { createSubCaste } from '../../../store/slices/SubCasteSlice';
import { useEffect, useState } from 'react';
const SubCasteForm = ({ show, handleClose, userDetails, categoryDetails }) => {
  const [categories, setCategories] = useState([])

  useEffect(() => {
    const getCategories = async () => {
      setCategories(() => categoryDetails.map(item => ({ value: item.category, label: item.category })));
    }
    getCategories()
  }, [categoryDetails])

  const dispatch = useDispatch();
  // Form initial values
  const initialValues = {
    udise: userDetails?.institute?.udise,
    institute: userDetails?.institute?._id,
    category: '',
    sub_caste: '',
    status: 'active',
    user: userDetails?._id
  }
  // Form validation
  const validationSchema = Yup.object().shape({
    category: Yup.string()
    .required('Category is required'),
    sub_caste: Yup.string()
    .required('Sub Caste is required'),
    status: Yup.string()
      .required('Status is required'),
  });
  // Form submit
  const onSubmit = async (values, actions) => {
    try {
      const response = await dispatch(createSubCaste(values));
      if (response?.payload?.status === 'success') {
        await showAlertMessage({ text: response?.payload?.message });
        handleClose(true)
      } else {
        throw new Error(response?.payload?.response?.data.message);
      }
    } catch (error) {
      showAlertMessage({
        icon: 'error',
        title: 'Error occurred!',
        text: error.message,
      });
    } finally {
      actions.setSubmitting(false);
    }
  };


  return (
    <>
      <Offcanvas show={show} onHide={handleClose} key="end" placement="end" name="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Sub Caste</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize={true}
          >
            {({ isSubmitting, setFieldValue, values, handleBlur, resetForm }) => (
              <Form>
                <div className="form-group">
                  <label className="form-label">Category*</label>
                  <Select
                    name="category"
                    options={categories}
                    value={categories.find(option => option.value === values.category)}
                    onChange={(option) => setFieldValue('category', option ? option.value : '')}
                    onBlur={handleBlur('category')}
                  />
                  <ErrorMessage className="text-danger mb-1" name="standard" component="div" />
                </div>
                <div className="form-group">
                  <label className="form-label">Sub Caste*</label>
                  <Field
                    className="form-control"
                    name="sub_caste"
                    autoFocus
                  />
                  <ErrorMessage className="text-danger mb-1" name="sub_caste" component="div" />
                </div>
                <div className="form-group">
                  <label className="form-label">Status*</label>
                  <Select
                    name="status"
                    options={statusOptions}
                    value={statusOptions.find(option => option.value === values.status)}
                    onChange={(option) => setFieldValue('status', option ? option.value : '')}
                    onBlur={handleBlur('status')}
                  />
                  <ErrorMessage className="text-danger mb-1" name="status" component="div" />
                </div>
                <div className="card-footer text-end btn-page">
                  <Button className="btn btn-outline-danger" onClick={() => resetForm()} text="Cancel" />
                  <Button className="btn btn-primary" type="submit" icon={isSubmitting && <Spinner animation="border" size="sm" />} disabled={isSubmitting} text={isSubmitting ? "Processing..." : "Add New"} />
                </div>
              </Form>
            )}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
};

export default SubCasteForm
