// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from '../locales/en.json';
import urTranslation from '../locales/ur.json';
import mrTranslation from '../locales/mr.json';
import hiTranslation from '../locales/hi.json';

const resources = {
  en: {
    translation: enTranslation
  },
  ur: {
    translation: urTranslation
  },
  mr:{
    translation: mrTranslation
  }
  ,
  hi:{
    translation: hiTranslation
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
