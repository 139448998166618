
import React from 'react';
import './css/tc.css';
import { format } from 'date-fns';
import { dateToWords, romanSuffix } from '../utils/common';

const TCTemplate = React.forwardRef((props, ref) => {
  const { data } = props
  const { sample = true } = props
  const { template = 1 } = props
  return (
    <>
      <body ref={ref}>
        {sample && <div id="watermark">SAMPLE</div>}
        <main>
          <table className={`${template === 2 ? 'tableTemplate2' : 'tableTC'} table-striped-columns table-responsive`} >
            <tr>
              <td colspan="8">
                {template === 2 ?
                  <>
                    <div className="headerTemplate2">
                      <div className="logoTemplate2">
                        <img src={data?.institute?.image && data?.institute?.image} alt="logo" />
                      </div>
                      <div className="organizationTemplate2">
                        <div className="foundation-nameTemplate2">{data?.institute?.organization_name}</div>
                        <div className="schoolNameTemplate2">{`${data?.institute?.institute_name}, ${data?.institute?.city?.name}`}</div>
                        <div className="addressTemplate2" dangerouslySetInnerHTML={{
                          __html: `Address: ${data.institute?.address}, ${data?.institute?.city?.name} ${data?.institute?.state?.name}, ${data?.institute?.country?.name} <br/> Contact: ${data?.institute?.mobile} Email: ${data?.institute?.email}`
                        }}></div>
                        <div style={{ fontSize: "13px" }}>Govt. Permission: {data?.institute?.permission_details}</div>
                        <div className="certificateTemplate2">LEAVING CERTIFICATE</div>
                        <div className="ruleTemplate2">(Vide Chap. II, Section III Rule 17)</div>
                      </div>
                      <div className="udiseTemplate2">
                        <div>UDISE: {data?.institute?.udise}</div>
                        <div className="statusTC text-center" >{data?.isOriginal ? 'ORIGINAL' : 'DUPLICATE'}</div>
                      </div>
                    </div>
                  </> :
                  <>
                    <div className="headerTC">
                      <div className="english-left" style={{ width: "35%" }}>
                        <div className="foundationTC">{data?.institute?.organization_name}</div>
                        <div className="schoolNameTC">{`${data?.institute?.institute_name}, ${data?.institute?.city?.name}`}</div>
                        <div className="addressTC" dangerouslySetInnerHTML={{
                          __html: `Address: ${data.institute?.address}, ${data?.institute?.city?.name} <br/> ${data?.institute?.state?.name}, ${data?.institute?.country?.name} <br/> Contact: ${data?.institute?.mobile} <br/> Email: ${data?.institute?.email}`
                        }}>
                        </div>
                      </div>
                      <div className="logo-centerTC widthTC">
                        {template != 3 && <div className="affiliationTC">UDISE: {data?.institute?.udise}</div>}
                        <div className="logoTC"><img src={data?.institute?.image && data?.institute?.image} alt="Logo" /></div>
                        <div className="statusTC">{data?.isOriginal ? 'ORIGINAL' : 'DUPLICATE'}</div>
                        <div className="certificateTC">LEAVING CERTIFICATE</div>
                        <div className="rule">(Vide Chap. II, Section III Rule 17)</div>
                      </div>
                      <div className="urdu-right widthTC">
                        <div className="foundation-urdu urdu">{data?.institute?.inLanguage?.organization_name}</div>
                        <div className="schoolName-urdu urdu">{`${data?.institute?.inLanguage?.institute_name}، ${data?.institute?.inLanguage?.address?.split(',')[1]?.trim()}`}</div>
                        <div className="address-urdu urdu">{data?.institute?.inLanguage?.address}</div>
                        <div style={{ fontSize: "13px" }}>Govt. Permission: {data?.institute?.permission_details}</div>
                      </div>
                    </div>
                  </>
                }
                <div className="headerDeclare">No change in any entry in this certificate shall be made expect by authority issuing it and any infringement of this requirement is labile to involve the imposition of penalty such as that rustication.</div>
              </td>
            </tr>
            <tr>
              <td>T.C.No.:</td>
              <td className="dataTC" style={{ width: "100%", backgroundColor: "#F2F2F2" }}>{data?.tc_number}</td>
              <td>Register No of the pupil:</td>
              <td colspan="4" className="dataTC" style={{ backgroundColor: "#F2F2F2" }}>{data?.student?.admission_no}</td>
            </tr>
            <tr>
              <td>1</td>
              <td style={{ backgroundColor: "#F2F2F2" }}>Student I.D:</td>
              <td colspan="5" className="dataTC"><input type="text" maxlength="19" id="saral_id" value={data?.student?.saral_id && data?.student?.saral_id} /></td>
            </tr>
            <tr>
              <td>2</td>
              <td style={{ backgroundColor: "#F2F2F2" }}>Name of the pupil:</td>
              <td colspan="5" className="dataTC">{data?.student?.name && (data?.student?.name).toUpperCase()}</td>
            </tr>
            <tr>
              <td>3</td>
              <td style={{ backgroundColor: "#F2F2F2" }}>Father's name:</td>
              <td colspan="5" className="dataTC">{data?.student?.parent?.f_name && (data?.student?.parent?.f_name).toUpperCase()}</td>
            </tr>
            <tr>
              <td>4</td>
              <td style={{ backgroundColor: "#F2F2F2" }}>Mother's name:</td>
              <td colspan="5" className="dataTC">{data?.student?.parent?.f_name && (data?.student?.parent?.m_name).toUpperCase()}</td>
            </tr>
            <tr>
              <td>5</td>
              <td style={{ backgroundColor: "#F2F2F2" }}>Religion:</td>
              <td className="dataTC">{data?.student?.religion && (data?.student?.religion).toUpperCase()}</td>
              <td style={{ backgroundColor: "#F2F2F2" }}>Caste:</td>
              <td className="dataTC">{data?.student?.caste && (data?.student?.caste).toUpperCase()}</td>
              <td style={{ backgroundColor: "#F2F2F2" }}>Sub Caste:</td>
              <td className="dataTC">{data?.student?.sub_caste ? (data?.student?.sub_caste).toUpperCase() : '*******'}</td>
            </tr>
            <tr>
              <td>6</td>
              <td style={{ backgroundColor: "#F2F2F2" }}>Nationality:</td>
              <td className="dataTC">INDIAN</td>
              <td style={{ backgroundColor: "#F2F2F2" }}>State:</td>
              <td colspan="3" className="dataTC">{data?.student?.birth_state?.name && (data?.student?.birth_state.name).toUpperCase()}</td>
            </tr>
            <tr>
              <td>7</td>
              <td style={{ backgroundColor: "#F2F2F2" }}>Aadhaar No.:</td>
              <td colspan="5" className="dataTC"><input type="text" maxlength="12" id="aadhaar" value={data?.student?.aadhaar_no && data?.student?.aadhaar_no} /></td>
            </tr>
            <tr>
              <td>8</td>
              <td style={{ backgroundColor: "#F2F2F2" }}>Place of birth:</td>
              <td colspan="2" className="dataTC">{data?.student?.birth_place && (data?.student?.birth_place).toUpperCase()}</td>
              <td style={{ backgroundColor: "#F2F2F2" }}>Mother Tongue:</td>
              <td colspan="3" className="dataTC">{data?.student?.mother_tongue ? (data?.student?.mother_tongue).toUpperCase() : '*******'}</td>
            </tr>
            <tr>
              <td rowspan="2">9</td>
              <td style={{ backgroundColor: "#F2F2F2" }}>Date of birth:</td>
              <td colspan="5" className="dataTC"><input type="text" maxlength="8" id="dob" value={data?.student?.dob && format(data?.student?.dob, 'ddMMyyyy')} /></td>
            </tr>
            <tr>
              <td style={{ backgroundColor: "#F2F2F2" }}>In words:</td>
              <td colspan="5" className="dataTC">{data?.student?.dob && (dateToWords(data?.student?.dob).toUpperCase())}</td>
            </tr>
            <tr>
              <td>10</td>
              <td style={{ backgroundColor: "#F2F2F2" }}>Last school attended:</td>
              <td colspan="5" className="dataTC">{data?.student?.last_school ? (data?.student?.last_school?.last_school).toUpperCase() : 'THIS SCHOOL'}</td>
            </tr>
            <tr>
              <td>11</td>
              <td style={{ backgroundColor: "#F2F2F2" }}>Date of admission:</td>
              <td colspan="5" className="dataTC">{data?.student?.admission_date && format(data?.student?.admission_date, 'dd-MM-yyyy')}</td>
            </tr>
            <tr>
              <td>12</td>
              <td style={{ backgroundColor: "#F2F2F2" }}>Date of leaving school:</td>
              <td colspan="5" className="dataTC">{data?.date && format(data?.date, 'dd-MM-yyyy')}</td>
            </tr>
            <tr>
              <td>13</td>
              <td style={{ backgroundColor: "#F2F2F2" }}>Progress:</td>
              <td colspan="5" className="dataTC">{data?.progress && data?.progress}</td>
            </tr>
            <tr>
              <td>14</td>
              <td style={{ backgroundColor: "#F2F2F2" }}>Conduct:</td>
              <td colspan="5" className="dataTC">{data?.conduct && data?.conduct}</td>
            </tr>
            <tr>
              <td>15</td>
              <td style={{ backgroundColor: "#F2F2F2" }}>Standard in which studying and since:</td>
              <td className="dataTC">{data?.student?.class && data?.student?.class}<sup>{data?.student?.class && romanSuffix(data?.student?.class)}</sup></td>
              <td colspan="4" className="dataTC" style={{ backgroundColor: "#F2F2F2" }}>{data?.student?.session && data?.student?.session}</td>
            </tr>
            <tr>
              <td>16</td>
              <td style={{ backgroundColor: "#F2F2F2" }}>Reason of school leaving:</td>
              <td colspan="5" className="dataTC">{data?.reason && data?.reason}</td>
            </tr>
            <tr>
              <td>17</td>
              <td style={{ backgroundColor: "#F2F2F2" }}>Remark:</td>
              <td colspan="5" className="dataTC">{data?.remark && data?.remark}</td>
            </tr>
            <tr>
              <td colspan="8">
                <div className="footerTC">
                  <div className="footerTextTC">Certified that above information is in accordance with the school register.</div>
                  <div>
                    <p>Date: {data?.issue_date && format(data?.issue_date, 'dd-MM-yyyy')}</p>
                  </div>
                  <div className="signsTC">
                    <div className="signs-clerk">Clerk</div>
                    <div className="signs-teacher">Class Teacher</div>
                    <div className="signs-headmaster">{`${data?.institute?.user?.users_designation && (data?.institute?.user?.users_designation).charAt(0).toUpperCase() + (data?.institute?.user?.users_designation).slice(1)}'s Signature`}</div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="8" className="powered text-uppercase">
                Proudly Powered By: <a href="https://www.inshatech.com" className="link-dark" rel='noreferrer' target="_blank">Insha Technologies</a>
              </td>
            </tr>
          </table>
        </main>
      </body>
    </>
  );
});

export default TCTemplate
