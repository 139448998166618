import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Breadcrumb = () => {
  const { t } = useTranslation();

  const [pageTitle, setPageTitle] = useState({
    title: "Dashboard",
    pagePath : [],
  });
  const location = useLocation();

  useEffect(() => {
    const urlString = (url) => {
      let wordsArray = url.split("/");
      wordsArray.shift();
      let lastWord = wordsArray.pop();
      let replacedStr = lastWord.replace(/-/g, " ");
      return { pagePath: wordsArray === "" ? [] : wordsArray, pageTitle: capitalizeFirstLetter(replacedStr) };
    };
  
    if (location.pathname === "/") {
      setPageTitle({ pagePath: [], title: "Dashboard" });
    } else {
      const pageInfo = urlString(location.pathname);
      setPageTitle({ pagePath: pageInfo.pagePath, title: pageInfo.pageTitle });
    }
  }, [location.pathname]); // Removed 'pageTitle' from dependency array
  

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className="page-header">
      <div className="page-block">
        <div className="row align-items-center">
          <div className="col-md-12">
            <ul className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">{t("Home")}</Link></li>
              {pageTitle.pagePath &&
                pageTitle.pagePath.map((path, index) => <li key={index} className="breadcrumb-item">{t(capitalizeFirstLetter(path))}</li> )                
              }              
              {pageTitle && <li className="breadcrumb-item" aria-current="page">{t(pageTitle.title)}</li>}
            </ul>
          </div>
          <div className="col-md-12">
            <div className="page-header-title">
              {pageTitle && <h2 className="mb-0">{t(pageTitle.title)}</h2>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
