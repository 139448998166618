import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postData, putData } from "../../api";

const fetchCategory = createAsyncThunk('fetchCategory', async (data, thunkAPI) => {
  try {
    const response = await postData('administration/caste/category/all', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const putCategory = createAsyncThunk('putCategory', async (data, thunkAPI) => {
  try {
    const response = await putData('administration/caste/category/update', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const createCategory = createAsyncThunk('createCategory', async (data, thunkAPI) => {
  try {
    const response = await postData('administration/caste/category/add', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const CategorySlice = createSlice({
  name: 'categorySlice',
  initialState: {
    isLoading: false,
    isError: false,
    categoryDetails: [],
  },
  extraReducers: (builder) => {
    builder
      //fetch
      .addCase(fetchCategory.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.categoryDetails = action.payload.records;
        state.isError = false;
      })
      .addCase(fetchCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
      //put
      .addCase(putCategory.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(putCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        const updatedCategory = action.payload.response;
        const index = state.categoryDetails.findIndex(item => item._id === updatedCategory._id);
        if (index !== -1) {
          // Create a new array with the updated item
          state.categoryDetails[index] = updatedCategory;
        } else {
          // Handle if the item is not found, possibly add it to the array
          state.categoryDetails.push(updatedCategory);
        }
        state.isError = false;
      })
      .addCase(putCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
      //create
      .addCase(createCategory.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(createCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.categoryDetails.push(action.payload.response);
        state.isError = false;
      })
      .addCase(createCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
  }
})

export { CategorySlice, fetchCategory, putCategory, createCategory }
export const { categoryDetails } = CategorySlice.actions;