import { useState } from "react";
import { useDispatch } from "react-redux";
import { avatar_2 } from "../../utils/images";
import { userImage } from "../../store/slices/UserSlice";
import { showAlertMessage } from "../../utils/sweetAlert";
import { Spinner } from "react-bootstrap";

const UserImage = ({ userDetails }) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const handleImageChange = async (e) => {
    setLoading(true)
    try {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      formData.append("_id", userDetails?._id);

      const response = await dispatch(userImage(formData));
      if (response?.payload?.status === 'success') {
        await showAlertMessage({ text: response?.payload?.message });
      } else {
        throw new Error(response?.payload?.response?.data.message);
      }
    } catch (error) {
      showAlertMessage({
        icon: 'error',
        title: 'Error occurred!',
        text: error.message,
      });
    }
    setLoading(false)
  };

  return (
    <div className="col-sm-12 text-center mb-3">
      {isLoading ? <Spinner animation="border" size="lg" /> :
        <div className="user-upload wid-75">
          <img
            src={userDetails?.image ? userDetails?.image : avatar_2}
            alt="Profile"
            className="img-fluid"
          />
          <label htmlFor="uplfile" className="img-avtar-upload">
            <i className="ti ti-camera f-24 mb-1"></i>
            <span>Upload</span>
          </label>
          <input
            type="file"
            id="uplfile"
            className="d-none"
            accept='image/*'
            onChange={handleImageChange}
          />
        </div>
      }
    </div>
  );
};

export default UserImage;
