import Breadcrumb from "../../components/common/Breadcrumb"

const Refund = () => {
  return (
    <>
      <section className="pc-container">
        <div className="pc-content">
          <Breadcrumb />
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Refund Policy</h5>
                </div>
                <div className="card-body">
                  <h1>7-Day Refund Policy</h1>
                  <h2>Introduction</h2>
                  <p>Thank you for choosing Insha Technologies (Edunexgen). We strive to provide high-quality services that meet your needs. However, if you are not entirely satisfied with your purchase, we are here to help. This policy outlines the conditions under which we offer refunds.</p>

                  <h2>Eligibility for Refunds</h2>
                  <ul>
                    <li>
                      <h3>Request Period</h3>
                      <p>You are eligible for a refund if you request it within 7 days of your initial purchase.</p>
                    </li>
                    <li>
                      <h3>Reason for Refund</h3>
                      <p>To help us improve our services, please provide a brief explanation for your refund request.</p>
                    </li>
                    <li>
                      <h3>First-Time Purchase</h3>
                      <p>Refunds are only available for first-time purchases. Renewals and subsequent subscriptions are not eligible for refunds.</p>
                    </li>
                  </ul>

                  <h2>How to Request a Refund</h2>
                  <ul>
                    <li>
                      <h3>Contact Support</h3>
                      <p>Email our support team at <a href="mailto:inshatech@gmail.com">inshatech@gmail.com</a> or use the contact form on our website.</p>
                    </li>
                    <li>
                      <h3>Provide Necessary Details</h3>
                      <p>Include your account information, purchase date, and the reason for the refund request.</p>
                    </li>
                  </ul>

                  <h2>Processing Refunds</h2>
                  <ul>
                    <li>
                      <h3>Review Period</h3>
                      <p>Our team will review your request within 3 business days of receiving it.</p>
                    </li>
                    <li>
                      <h3>Approval</h3>
                      <p>If your refund request is approved, we will process the refund to your original payment method within 7 business days.</p>
                    </li>
                  </ul>

                  <h2>Non-Refundable Services</h2>
                  <ul>
                    <li>
                      <h3>Custom Services</h3>
                      <p>Fees for custom development, consulting, or any bespoke services are non-refundable.</p>
                    </li>
                    <li>
                      <h3>Third-Party Services</h3>
                      <p>Any charges for third-party services or integrations are not eligible for refunds.</p>
                    </li>
                  </ul>

                  <h2>Changes to the Policy</h2>
                  <p>We reserve the right to modify this refund policy at any time. Changes will be communicated through our website.</p>

                  <h2>Contact Us</h2>
                  <p>If you have any questions about our refund policy, please contact us at <a href="mailto:inshatech@gmail.com">inshatech@gmail.com</a>.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
};

export default Refund
