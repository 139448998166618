import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postData, putData } from "../../api";

const fetchNirgam = createAsyncThunk('fetchNirgam', async (data, thunkAPI) => {
  try {
    const response = await postData('administration/nirgam/all', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const putNirgam = createAsyncThunk('putNirgam', async (data, thunkAPI) => {
  try {
    const response = await putData('administration/nirgam/update', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const createNirgam = createAsyncThunk('createNirgam', async (data, thunkAPI) => {
  try {
    const response = await postData('administration/nirgam/add', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const NirgamSlice = createSlice({
  name: 'nirgamSlice',
  initialState: {
    isLoading: false,
    isError: false,
    nirgamDetails: [],
  },
  extraReducers: (builder) => {
    builder
      //fetch
      .addCase(fetchNirgam.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchNirgam.fulfilled, (state, action) => {
        state.isLoading = false;
        state.nirgamDetails = action.payload.records;
        state.isError = false;
      })
      .addCase(fetchNirgam.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
      //put
      .addCase(putNirgam.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(putNirgam.fulfilled, (state, action) => {
        state.isLoading = false;
        const updatedNirgam = action.payload.response;
        const index = state.nirgamDetails.findIndex(item => item._id === updatedNirgam._id);
        if (index !== -1) {
          // Replace the existing object with the updated object
          state.nirgamDetails[index] = { ...state.nirgamDetails[index], ...updatedNirgam };
        } else {
          // Handle if the item is not found, possibly add it to the array (though not likely in an update case)
          state.nirgamDetails.push(updatedNirgam);
        }
        state.isError = false;
      })
      .addCase(putNirgam.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
      //create
      .addCase(createNirgam.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(createNirgam.fulfilled, (state, action) => {
        state.isLoading = false;
        state.nirgamDetails.push(action.payload.response);
        state.isError = false;
      })
      .addCase(createNirgam.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
  }
})

export { NirgamSlice, fetchNirgam, putNirgam, createNirgam }
export const { nirgamDetails } = NirgamSlice.actions;