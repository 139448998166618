import { Link, useNavigate } from "react-router-dom";
import AuthSideContent from "../../components/common/AuthSideContent";
import Button from "../../components/common/Button";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Logo from "../../components/common/Logo";
import { postData } from "../../api";
import { showAlertMessage } from '../../utils/sweetAlert.js';
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { doLogin } from "../../store/slices/AuthSlice.jsx";
import { TbEye, TbEyeClosed } from "react-icons/tb";
import { useEffect, useState } from "react";
import { useContext } from 'react';
import { IPInfoContext } from 'ip-info-react';
import IpInfo from "../../components/common/IpInfo.jsx";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import { IoShieldCheckmarkSharp } from "react-icons/io5";

const Login = () => {
  const signIn = useSignIn();
  const session = atob(localStorage.getItem(btoa("_session")));

  useEffect(() => {
    session === 'true' ? setTrusted(true) : setTrusted(false);
  }, [session])

  const [trusted, setTrusted] = useState(false);
  const removeDevice = async () => {
    const result = await showAlertMessage({
      title: "Are you sure?",
      text: "You want to remove this trusted device?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Remove it.!"
    });
    if (result.isConfirmed) {
      localStorage.removeItem(btoa("_session"));
      setTrusted(false);
      await showAlertMessage({ text: 'Removed successfully' });
    }
  }

  const userInfo = useContext(IPInfoContext);
  console.log(userInfo)
  const [password, setPassword] = useState(false);
  // Redux dispatch
  const dispatch = useDispatch();
  //navigate
  const navigate = useNavigate();
  // Form initial values
  const initialValues = {
    mobile: '',
    password: ''
  };
  // Form validation
  const validationSchema = Yup.object().shape({
    mobile: Yup.string()
      .matches(/^[6-9]\d{9}$/, 'Invalid Indian mobile number')
      .required('Mobile number is required'),
    password: Yup.string()
      .required('Password is required'),
  });
  // Form submit
  //Auth Login
  const onSubmit = async (values, actions) => {
    try {
      const response = await postData('administration/user/auth/login', values);
      dispatch(doLogin(response));
      localStorage.setItem('_Auth_id', response.details._id);
      if (session !== 'true') {
        navigate('/auth/verification')
      } else {
        signIn({
          auth: {
            token: response.token,
            type: 'Bearer'
          },
          expiresIn: 3600,
          authState: { mobile: values.mobile }
        });
        await showAlertMessage({ text: 'Login successfully' });
        navigate('/')
      }
    } catch (error) {
      const errorMessage = error.response.data ? error.response.data.message : error.message
      showAlertMessage({
        icon: 'error',
        title: 'Error occurred!',
        text: errorMessage,
      });
    }
    actions.setSubmitting(false);
  };

  return (
    <>
      <div className="auth-main">
        <div className="auth-wrapper v3">
          <AuthSideContent className="auth-sidecontent" />
          <div className="auth-form">
            <div className="card my-5">
              <div className="card-body">
                <div className="text-center">
                  <Logo alt="image" height="200px" />
                </div>
                <h4 className="text-center f-w-500 mb-3">Login with your your credentials</h4>
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="form-group mb-3">
                        <Field
                          type="text"
                          className="form-control"
                          placeholder="User name"
                          name="mobile"
                          maxLength={10}
                          size={10}
                          autoFocus
                        />
                        <ErrorMessage className="text-danger mb-1" name="mobile" component="div" />
                      </div>
                      <div className="input-group search-form mb-3">
                        <Field
                          type={password ? "text" : "password"}
                          className="form-control"
                          placeholder="Password"
                          name="password"
                        />
                        <Link className="input-group-text bg-transparent" onClick={() => setPassword(!password)}>{password ? <TbEyeClosed /> : <TbEye />}</Link>

                      </div>
                      <ErrorMessage className="text-danger mb-1" name="password" component="div" />
                      <div className="d-flex mt-1 justify-content-between align-items-center mb-2">
                        <Link className="text-secondary f-w-400 mb-0 link-primary" to="/auth/forgot-password">Forgot Password?</Link>
                      </div>
                      <div className="d-grid mt-4">
                        <Button type="submit" className="btn btn-primary" icon={isSubmitting && <Spinner animation="border" size="sm" />} disabled={isSubmitting} text={isSubmitting ? "Processing..." : "Login"} />
                      </div>
                    </Form>
                  )}
                </Formik>
                <div className="d-flex justify-content-between align-items-end mt-4">
                  <h6 className="f-w-500 mb-0">Don't have an Account?</h6>
                  <Link to="/auth/register" className="link-primary">Create Account</Link>
                </div>
                {
                  trusted &&
                  <div className="d-flex justify-content-between align-items-end mt-2 mb-5">
                    <div></div>
                    <div className="link-success"><IoShieldCheckmarkSharp /> Trusted Device (<Link to="#" onClick={removeDevice} className="link-danger">Remove</Link>)</div>
                  </div>
                }

                <IpInfo />
              </div>
            </div>
            <div className="auth-footer">
              <div className="m-0 w-100 text-center">
                Proudly Powered By: <Link to="https://www.inshatech.com" target="_blank" className="link-primary">Insha Technologies</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default Login;
