import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../components/common/Breadcrumb";
import InfoForm from "../../components/general/InfoForm";
import Logo from "../../components/general/Logo";
import { useEffect } from "react";
import { fetchInstitute } from "../../store/slices/InstituteSlice";
import { template1, template2 } from "../../utils/images";


const GeneralInfo = () => {
  const dispatch = useDispatch();

  const { userDetails } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(fetchInstitute({
      _id: userDetails?.institute?._id
    }))
  }, [dispatch, userDetails]);

  const { instituteDetails } = useSelector((state) => state.institute);

  return (
    <>
      <section className="pc-container">
        <div className="pc-content">
          <Breadcrumb />
          <div className="row">
            <div className="col-lg-6">
              <div className="card">
                <div id="sticky-action" className="sticky-action">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col-sm-6">
                        <h5>Institution General Information</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <InfoForm instituteDetails={instituteDetails} />
              </div>
            </div>
            <div className="col-lg-6">
              <Logo instituteDetails={instituteDetails} />
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h5>Sample Information</h5>
                  </div>
                  <div className="card-body">
                    <div className="col-sm-12 text-center mb-1">
                      <h5>Template 1</h5>
                      <img className="ratio ratio-21x9 rounded overflow-hidden" src={template1} alt="" />
                      <hr />
                      <h5>Template 2</h5>
                      <img className="ratio ratio-21x9 rounded overflow-hidden" src={template2} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
};

export default GeneralInfo
