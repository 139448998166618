import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postData, putData } from "../../api";

const fetchSession = createAsyncThunk('fetchSession', async (data, thunkAPI) => {
  try {
    const response = await postData('administration/session/all', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const putSession = createAsyncThunk('putSession', async (data, thunkAPI) => {
  try {
    const response = await putData('administration/session/update', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const createSession = createAsyncThunk('createSession', async (data, thunkAPI) => {
  try {
    const response = await postData('administration/session/add', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const SessionSlice = createSlice({
  name: 'sessionSlice',
  initialState: {
    isLoading: false,
    isError: false,
    sessionDetails: [],
  },
  extraReducers: (builder) => {
    builder
      //fetch
      .addCase(fetchSession.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchSession.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sessionDetails = action.payload.records;
        state.isError = false;
      })
      .addCase(fetchSession.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
      //put
      .addCase(putSession.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(putSession.fulfilled, (state, action) => {
        state.isLoading = false;
        const updatedSession = action.payload.response;
        const index = state.sessionDetails.findIndex(item => item._id === updatedSession._id);
        if (index !== -1) {
          // Create a new array with the updated item
          state.sessionDetails[index] = updatedSession;
        } else {
          // Handle if the item is not found, possibly add it to the array
          state.sessionDetails.push(updatedSession);
        }
        state.isError = false;
      })
      .addCase(putSession.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
      //create
      .addCase(createSession.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(createSession.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sessionDetails.push(action.payload.response);
        state.isError = false;
      })
      .addCase(createSession.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
  }
})

export { SessionSlice, fetchSession, putSession, createSession }
export const { sessionDetails } = SessionSlice.actions;