import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="pc-footer">
        <div className="footer-wrapper container-fluid">
          <div className="row">
            <div className="col my-1">
              <p className="m-0">
                Proudly Powered By: <Link to="https://www.inshatech.com" target="_blank">Insha Technologies</Link>
              </p>
            </div>
            <div className="col-auto my-1">
              <ul className="list-inline footer-link mb-0">
                <li className="list-inline-item"><Link className="text-primary" to="/policies/terms-and-conditions">Terms of Service</Link></li>
                <li className="list-inline-item"><Link className="text-primary" to="/policies/privacy-policy">Privacy Policy</Link></li>
                <li className="list-inline-item"><Link className="text-primary" to="/policies/refund-policy">Refund Policy</Link></li>
                <li className="list-inline-item"><Link className="text-primary" to="https://www.members.inshatech.com/contact.php" target="_blank">Report Abuse</Link></li>
                <li className="list-inline-item"><Link to="/">Home</Link></li>
                <li className="list-inline-item"><Link to="" target="_blank">Documentation</Link></li>
                <li className="list-inline-item"><Link to="https://www.memners.inshatech.com" target="_blank">Support</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
};

export default Footer
