import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCities, fetchCountries, fetchStates } from "../../store/slices/CSCSlice";
import { format } from "date-fns";
import { putUser } from "../../store/slices/UserSlice";
import { showAlertMessage } from "../../utils/sweetAlert";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Select from 'react-select';
import Button from "../../components/common/Button";
import { Spinner } from "react-bootstrap";
import * as Yup from 'yup';
import { bloodGroups, users_designation } from "../../utils/common";

const ProfileForm = ({userDetails}) => {
  const dispatch = useDispatch();
  // Country options
  const { countries: countriesList, states: statesList, cities: citiesList } = useSelector((state) => state.csc);
  const [countries, setCountries] = useState([])
  useEffect(() => {
    dispatch(fetchCountries())
  }, [dispatch])

  useEffect(() => {
    const getCountries = async () => {
      setCountries(() => countriesList.map(country => ({ value: country._id, label: country.name })));
    }
    getCountries()
  }, [countriesList])

  // State options
  const [states, setStates] = useState([])
  const handleCountryChange = (option, setFieldValue) => {
    setFieldValue('country', option ? option.value : '');
    dispatch(fetchStates({ country: option.value }))
  };
  useEffect(() => {
    const getStates = async () => {
      setStates(() => statesList.map(state => ({ value: state._id, label: state.name })));
    }
    getStates()
  }, [statesList])

  // City options
  const [cities, setCities] = useState([])
  const handleStateChange = (option, setFieldValue) => {
    setFieldValue('state', option ? option.value : '');
    dispatch(fetchCities({ state: option.value }))
  };
  useEffect(() => {
    const getCities = async () => {
      setCities(() => citiesList.map(city => ({ value: city._id, label: city.name })));
    }
    getCities()
  }, [citiesList])

  // Form initial values
  const initialValues = useMemo(() => ({
    name: userDetails?.name,
    mobile: userDetails?.mobile,
    email: userDetails?.email,
    emergency_mobile: userDetails?.emergency_mobile,
    users_designation: userDetails?.users_designation,
    blood_group: userDetails?.blood_group,
    address: userDetails?.address,
    country: userDetails?.country?._id,
    state: userDetails?.state?._id,
    city: userDetails?.city?._id,
    pincode: userDetails?.pincode,
    dob: userDetails?.dob && format(userDetails?.dob, 'yyyy-MM-dd')
  }), [userDetails]);
  // Form validation
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Full name is required'),
    mobile: Yup.string()
      .matches(/^[6-9]\d{9}$/, 'Invalid Indian mobile number')
      .required('Mobile number is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    dob: Yup.date().required('Date of Birth is required'),
    address: Yup.string()
      .required('Address is required'),
    country: Yup.string()
      .required('County is required'),
    state: Yup.string()
      .required('State is required'),
    city: Yup.string()
      .required('City is required'),
    pincode: Yup.string()
      .required('Pincode is required'),
    users_designation: Yup.string()
      .required('Designation is required'),
  });
  // Form submit
  const onSubmit = async (values, actions) => {
    try {
      const response = await dispatch(putUser({
        _id: userDetails._id,
        query: values
      }));
      if (response?.payload?.status === 'success') {
        await showAlertMessage({ text: response?.payload?.message });
      } else {
        throw new Error(response?.payload?.response?.data.message);
      }
    } catch (error) {
      showAlertMessage({
        icon: 'error',
        title: 'Error occurred!',
        text: error.message,
      });
    } finally {
      actions.setSubmitting(false);
    }
  };

  useEffect(() => {
    dispatch(fetchStates({ country: initialValues.country }))
    dispatch(fetchCities({ state: initialValues.state }))
  }, [dispatch, initialValues]);
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({ isSubmitting, setFieldValue, values, handleBlur }) => (
          <Form>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label">Name*</label>
                  <Field
                    className="form-control"
                    name="name"
                    autoFocus
                  />
                  <ErrorMessage className="text-danger mb-1" name="name" component="div" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label">Mobile*</label>
                  <Field
                    className="form-control"
                    name="mobile"
                    maxLength={10}
                    size={10}
                  />
                  <ErrorMessage className="text-danger mb-1" name="mobile" component="div" />

                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label">Email*</label>
                  <Field
                    type="email"
                    className="form-control"
                    name="email"
                  />
                  <ErrorMessage className="text-danger mb-1" name="email" component="div" />

                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label">Emergency Mobile</label>
                  <Field
                    className="form-control"
                    name="emergency_mobile"
                    maxLength={10}
                    size={10}
                  />
                  <ErrorMessage className="text-danger mb-1" name="emergency_mobile" component="div" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label">Blood Group</label>
                  <Select
                    name="blood_group"
                    options={bloodGroups}
                    value={bloodGroups.find(option => option.value === values.blood_group)}
                    onChange={(option) => setFieldValue('blood_group', option ? option.value : '')}
                    onBlur={handleBlur('blood_group')}
                  />
                  <ErrorMessage className="text-danger mb-1" name="blood_group" component="div" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label">Designation*</label>
                  <Select
                    name="users_designation"
                    options={users_designation}
                    value={users_designation.find(option => option.value === values.users_designation)}
                    onChange={(option) => setFieldValue('users_designation', option ? option.value : '')}
                    onBlur={handleBlur('users_designation')}
                  />
                  <ErrorMessage className="text-danger mb-1" name="users_designation" component="div" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label">DOB*</label>
                  <Field
                    type="date"
                    className="form-control"
                    name="dob"
                  />
                  <ErrorMessage className="text-danger mb-1" name="address" component="div" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label">Address*</label>
                  <Field
                    className="form-control"
                    name="address"
                  />
                  <ErrorMessage className="text-danger mb-1" name="address" component="div" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label">Country*</label>
                  <Select
                    name="country"
                    cacheOptions
                    defaultOptions
                    options={countries}
                    value={countries.find(option => option.value === values.country)}
                    onChange={(option) => handleCountryChange(option, setFieldValue)}
                    onBlur={handleBlur('country')}
                  />
                  <ErrorMessage className="text-danger mb-1" name="country" component="div" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label">State*</label>
                  <Select
                    name="state"
                    cacheOptions
                    defaultOptions
                    options={states}
                    value={states.find(option => option.value === values.state)}
                    onChange={(option) => handleStateChange(option, setFieldValue)}
                    onBlur={handleBlur('state')}
                  />
                  <ErrorMessage className="text-danger mb-1" name="state" component="div" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label">City*</label>
                  <Select
                    name="city"
                    cacheOptions
                    defaultOptions
                    options={cities}
                    value={cities.find(option => option.value === values.city)}
                    onChange={(option) => setFieldValue('city', option ? option.value : '')}
                    onBlur={handleBlur('city')}
                  />
                  <ErrorMessage className="text-danger mb-1" name="city" component="div" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label">Pin code*</label>
                  <Field
                    className="form-control form-control-sm"
                    name="pincode"
                    maxLength={6}
                    size={6}
                  />
                  <ErrorMessage className="text-danger mb-1" name="pincode" component="div" />
                </div>
              </div>
            </div>
            <div className="card-footer text-center border-0">
              <Button className="btn btn-primary" type="submit" icon={isSubmitting && <Spinner animation="border" size="sm" />} disabled={isSubmitting} text={isSubmitting ? "Processing..." : "Update Profile"} />
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
};

export default ProfileForm
