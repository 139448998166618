import { Link, useNavigate } from "react-router-dom";
import { Badge, Button, Modal, Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { showAlertMessage } from "../../utils/sweetAlert";
import * as Yup from "yup";
import { format } from "date-fns";
import { postData } from "../../api";
import { FaIndianRupeeSign, FaPaperPlane, FaPhone } from "react-icons/fa6";

const Error = ({
  image,
  title,
  description,
  btnText = "Go to home",
  data,
  pageType,
  isButton = true,
  planDetails = false,
}) => {
  const navigate = useNavigate();
  const [finalPrice, setFinalPrice] = useState(0);
  useEffect(() => {
    setFinalPrice(data?.institute?.registrationDate &&
      format(data?.institute?.registrationDate, "dd-MM-yyyy") ===
      format(data?.institute?.nextRenewalDate, "dd-MM-yyyy")
      ? Math.round(9500 + (9500 * 2.5 / 100))
      : 3000)
  }, [data])

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleButton = () => {
    if (data) {
      handleShow();
    } else {
      navigate("/");
    }
  };

  const initialValues = {
    udise: data?.institute?.udise,
    institute: data?.institute?._id,
    institute_name: data?.institute?.institute_name,
    price:
      data?.institute?.registrationDate &&
        format(data?.institute?.registrationDate, "dd-MM-yyyy") ===
        format(data?.institute?.nextRenewalDate, "dd-MM-yyyy")
        ? 9500
        : 3000,
    final_amount: finalPrice,
    mobile: data?.mobile,
    user: data?._id,
  };
  // Form validation
  const validationSchema = Yup.object().shape({
    udise: Yup.string().required("UDISE is required"),
    price: Yup.string().required("Price is required"),
    mobile: Yup.string()
      .matches(/^[6-9]\d{9}$/, 'Invalid Indian mobile number')
      .required('Mobile number is required'),
    final_amount: Yup.string().required("Final amount is required"),
  });
  // Form submit
  const onSubmit = async (values, actions) => {
    try {
      const response = await postData('administration/account/order', values)
      if (response.response.success === true) {
        window.location.href = response.response.data.instrumentResponse.redirectInfo.url
      }
    } catch (error) {
      showAlertMessage({
        icon: "error",
        title: "Error occurred!",
        text: error.message,
      });
    } finally {
      actions.setSubmitting(false);
    }
  };

  const promoCodeHandle = (e, values) => {
    try {
      const str = atob(e.target.value);
      const parts = str.split("-");
      if (parts.length === 2) {
        const [udise, discount] = parts;
        if (values.udise === udise) {
          const value = parseInt(values.price) - parseInt(discount);
          setFinalPrice(value);
        }

      } else {
        throw new Error("Invalid format");
      }
    } catch (error) {
      setFinalPrice(values.price);
      return { udise: null, discount: null };
    }
  }

  return (
    <>
      <div className={pageType === "internal" ? "" : "maintenance-block"}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="card error-card">
                <div className="card-body">
                  <div className="error-image-block">
                    <img className="img-fluid" src={image} alt="img" />
                  </div>
                  <div className="text-center">
                    <h1 className="mt-5">
                      <b>{title}</b>
                    </h1>
                    <p
                      className="error-description"
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
                    {planDetails &&
                      <>
                        <p>Account Setup: <Badge pill bg="badge bg-light-primary rounded-pill f-12"><FaIndianRupeeSign /> 9500/-(One Time)</Badge> Renewal: <Badge pill bg="badge bg-light-primary rounded-pill f-12"><FaIndianRupeeSign /> 4500/-(Per Year)</Badge></p>
                        <p>Contact us for know more about discounts.</p>
                        <p><FaPhone className="text-primary" /> Call Us: <Link to="tel:8888191926">+91-8888191926</Link></p>
                      </>
                    }
                    {isButton && (
                      <Button
                        type="button"
                        className="btn btn-primary mb-3"
                        onClick={handleButton}
                      >
                        <FaPaperPlane /> {btnText}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Payment Details</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {({ isSubmitting, setFieldValue, values, handleBlur, resetForm }) => (
            <Form>
              <Modal.Body>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="form-label">Institute*</label>
                      <Field
                        className="form-control"
                        name="institute_name"
                        readOnly
                      />
                      <ErrorMessage
                        className="text-danger mb-1"
                        name="institute_name"
                        component="div"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label className="form-label">UDISE*</label>
                          <Field className="form-control" name="udise" readOnly />
                          <ErrorMessage
                            className="text-danger mb-1"
                            name="udise"
                            component="div"
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label className="form-label">UDISE*</label>
                          <Field
                            className="form-control"
                            name="mobile"
                            maxLength={10}
                            size={10}
                          />
                          <ErrorMessage
                            className="text-danger mb-1"
                            name="mobile"
                            component="div"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="form-label">Price</label>
                      <Field className="form-control" name="price" readOnly />
                      <ErrorMessage
                        className="text-danger mb-1"
                        name="price"
                        component="div"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label className="form-label">Promo Code</label>
                      {data?.institute?.registrationDate &&
                        format(
                          data?.institute?.registrationDate,
                          "dd-MM-yyyy"
                        ) ===
                        format(
                          data?.institute?.nextRenewalDate,
                          "dd-MM-yyyy"
                        ) ? (
                        <Field
                          className="form-control"
                          name="promo_code"
                          onChange={(e) => promoCodeHandle(e, values)}
                        />
                      ) : (
                        <Field
                          className="form-control"
                          name="promo_code"
                          disabled
                        />
                      )}
                      <ErrorMessage
                        className="text-danger mb-1"
                        name="promo_code"
                        component="div"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label className="form-label">Final Amount*</label>
                      <Field className="form-control" name="final_amount" readOnly />
                      <ErrorMessage
                        className="text-danger mb-1"
                        name="final_amount"
                        component="div"
                      />
                    </div>
                  </div>
                </div>                
              </Modal.Body>
              <Modal.Footer>
                <div style={{ flex: 1 }}>
                <p className="text-danger">* Payment gateway fee is applicable.</p>
                  <Link to="/policies/terms-and-conditions" target="_blank" className="link-primary me-3">Terms of Service</Link>
                  <Link to="/policies/privacy-policy" target="_blank" className="link-primary me-3">Privacy Policy</Link>
                  <Link to="/policies/refund-policy" target="_blank" className="link-primary me-3">Refund Policy</Link>
                  <Link to="https://www.members.inshatech.com/contact.php" target="_blank" className="link-primary me-3">Report Abuse</Link>
                </div>
                <Button type="button" variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button className="btn btn-primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting && <Spinner animation="border" size="sm" />} {isSubmitting ? "Processing..." : "Pay Now"}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default Error;
