import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postData } from "../../api";

const fetchCountries = createAsyncThunk('fetchCountries', async(data, thunkAPI) => {
  try {
    const response = await postData('administration/csc/countries', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const fetchStates = createAsyncThunk('fetchStates', async(data, thunkAPI) => {
  try {
    const response = await postData('administration/csc/states', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const fetchCities = createAsyncThunk('fetchCities', async(data, thunkAPI) => {
  try {
    const response = await postData('administration/csc/cities', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const CSCSlice = createSlice({
  name: 'CSC',
  initialState: {
    isLoading: false,
    isError: false,
    countries: [],
    states: [],
    cities: []
  },
  extraReducers: (builder) => {
    builder
      // Countries
      .addCase(fetchCountries.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.isLoading = false;
        state.countries = action.payload.records;
        state.isError = false;
      })
      .addCase(fetchCountries.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })

      // States
      .addCase(fetchStates.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchStates.fulfilled, (state, action) => {
        state.isLoading = false;
        state.states = action.payload.records;
        state.isError = false;
      })
      .addCase(fetchStates.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })

      // Cities
      .addCase(fetchCities.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchCities.fulfilled, (state, action) => {
        state.isLoading = false;
        state.cities = action.payload.records;
        state.isError = false;
      })
      .addCase(fetchCities.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
  }
})

export { CSCSlice, fetchCountries, fetchCities, fetchStates };