
const Input = (props) => {
  return (
    <>
      <input className={props.className} type={props.type} placeholder={props.placeholder} checked={props.checked} onChange={props.onChange} value={props.value} id={props.id} disabled={props.disabled} readOnly={props.readOnly} maxLength={props.maxLength} />
    </>
  )
};

export default Input
