
import React from 'react';
import './css/nirgam.css';
import { format } from 'date-fns';
import { dateToWords, romanSuffix } from '../utils/common';

const NirgamTemplate = React.forwardRef((props, ref) => {
  const { data } = props
  return (
    <>
      <body ref={ref}>


        <main >
          <table className="table table-striped table-bordered nowrap">
            <tr>
              <td colspan="7">
                <div className="header">
                  <div className="logo">
                    <img src={data?.institute?.image && data?.institute?.image} alt="logo" />
                  </div>
                  <div className="organization">
                    <div className="foundation-name">{data?.institute?.organization_name}</div>
                    <div className="schoolName">{`${data?.institute?.institute_name}, ${data?.institute?.city?.name}`}</div>
                    <div className="address">{`${data.institute?.address}, ${data?.institute?.city?.name} - ${data?.institute?.pincode}, Mobile: ${data?.institute?.mobile} `}</div>
                  </div>
                  <div className="udise">UDISE: {data?.institute?.udise}</div>
                </div>
                <div className="header-title">
                  <div className="marathi-title">विद्यार्थी प्रवेश निर्गम प्रमाणे नोंदणी रजिस्टर</div>
                  <div className="english-title">Pupil Admission and Leaving Details as per General Register Page No. (______________)</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>1</td>
              <td style={{backgroundColor: "#F2F2F2"}}>Admission No: </td>
              <td colspan="5" className="data">{data?.student?.admission_no}</td>
            </tr>
            <tr>
              <td>2</td>
              <td style={{backgroundColor: "#F2F2F2"}}>Pupil's Ful Name: </td>
              <td colspan="5" className="data">{data?.student?.name && (data?.student?.name).toUpperCase()}</td>
            </tr>
            <tr>
              <td rowspan="2">3</td>
              <td style={{backgroundColor: "#F2F2F2"}}>Father / Guardian Name: </td>
              <td colspan="5" className="data">{data?.student?.parent?.f_name && (data?.student?.parent?.f_name).toUpperCase()}</td>
            </tr>
            <tr>
              <td style={{backgroundColor: "#F2F2F2"}}>Mother Name: </td>
              <td colspan="5" className="data">{data?.student?.parent?.f_name && (data?.student?.parent?.m_name).toUpperCase()}</td>
            </tr>
            <tr>
              <td>4</td>
              <td style={{backgroundColor: "#F2F2F2"}}>Religion: </td>
              <td colspan="5" className="data">{data?.student?.religion && (data?.student?.religion).toUpperCase()}</td>
            </tr>
            <tr>
              <td>5</td>
              <td style={{backgroundColor: "#F2F2F2"}}>Caste: </td>
              <td className="data">{data?.student?.caste && (data?.student?.caste).toUpperCase()}</td>
              <td style={{backgroundColor: "#F2F2F2"}}>Sub caste: </td>
              <td className="data">{data?.student?.sub_caste ? (data?.student?.sub_caste).toUpperCase() : '*******'}</td>
              <td style={{backgroundColor: "#F2F2F2"}}>Category: </td>
              <td className="data">{data?.student?.category && (data?.student?.category).toUpperCase()}</td>
            </tr>
            <tr>
              <td>6</td>
              <td style={{backgroundColor: "#F2F2F2"}}>Nationality: </td>
              <td colspan="5" className="data">INDIAN</td>
            </tr>
            <tr>
              <td>7</td>
              <td style={{backgroundColor: "#F2F2F2"}}>Father / Guardian Occupation: </td>
              <td colspan="5" className="data">{data?.student?.parent?.f_occupation ? (data?.student?.parent?.f_occupation).toUpperCase() : '*******'}</td>
            </tr>
            <tr>
              <td>8</td>
              <td style={{backgroundColor: "#F2F2F2"}}>Mother Tongue: </td>
              <td colspan="5" className="data">{data?.student?.mother_tongue && (data?.student?.mother_tongue).toUpperCase()}</td>
            </tr>
            <tr>
              <td>9</td>
              <td style={{backgroundColor: "#F2F2F2"}}>Admission Date: </td>
              <td colspan="5" className="data">{data?.student?.admission_date && format(data?.student?.admission_date, 'dd-MM-yyyy')}</td>
            </tr>
            <tr>
              <td rowspan="2">10</td>
              <td style={{backgroundColor: "#F2F2F2"}}>Date of Birth: </td>
              <td colspan="5" className="data">{data?.student?.dob && format(data?.student?.dob, 'dd-MM-yyyy')}</td>
            </tr>
            <tr>
              <td style={{backgroundColor: "#F2F2F2"}}>In Words: </td>
              <td colspan="5" className="data">{data?.student?.dob && (dateToWords(data?.student?.dob).toUpperCase())}</td>
            </tr>
            <tr>
              <td>11</td>
              <td style={{backgroundColor: "#F2F2F2"}}>Birth Place: </td>
              <td className="data">{data?.student?.birth_place && (data?.student?.birth_place).toUpperCase()}</td>
              <td style={{backgroundColor: "#F2F2F2"}}>State: </td>
              <td colspan="3" className="data">{data?.student?.birth_state?.name && (data?.student?.birth_state?.name).toUpperCase()}</td>
            </tr>
            <tr>
              <td>12</td>
              <td style={{backgroundColor: "#F2F2F2"}}>Standard When Enrolled: </td>
              <td colspan="5" className="data">{data?.student?.admitted_class && data?.student?.admitted_class}<sup>{romanSuffix(data?.student?.admitted_class)}</sup></td>
            </tr>
            <tr>
              <td>13</td>
              <td style={{backgroundColor: "#F2F2F2"}}>Previous School/College: </td>
              <td colspan="5" className="data">{data?.student?.last_school ? (data?.student?.last_school?.last_school).toUpperCase() : 'THIS SCHOOL'}</td>
            </tr>
            <tr>
              <td>14</td>
              <td style={{backgroundColor: "#F2F2F2"}}>Previous Standard: </td>
              <td colspan="5" className="data">{data?.student?.last_class ? data?.student?.last_class : '*******'}<sup>{data?.student?.last_class && romanSuffix(data?.student?.last_class)}</sup></td>
            </tr>
            <tr>
              <td>15</td>
              <td style={{backgroundColor: "#F2F2F2"}}>Admission Authority Sign: </td>
              <td colspan="5" className="data"></td>
            </tr>
            <tr>
              <td>16</td>
              <td style={{backgroundColor: "#F2F2F2"}}>Standard When Leaving: </td>
              <td colspan="5" className="data">{data?.student?.isLeaved ? data?.student?.class : '*******'}<sup>{data?.student?.isLeaved && romanSuffix(data?.student?.class)}</sup></td>
            </tr>
            <tr>
              <td>17</td>
              <td style={{backgroundColor: "#F2F2F2"}}>School/College Leaving Date: </td>
              <td colspan="5" className="data">{data?.student?.isLeaved ? format(data?.student?.transfer_certificate?.date, 'dd-MM-yyyy') : '*******'}</td>
            </tr>
            <tr>
              <td>18</td>
              <td style={{backgroundColor: "#F2F2F2"}}>School/College Leaving Reason: </td>
              <td colspan="5" className="data">{data?.student?.isLeaved ? data?.student?.transfer_certificate?.reason : '*******'}</td>
            </tr>
            <tr>
              <td>19</td>
              <td style={{backgroundColor: "#F2F2F2"}}>Identification Mark: </td>
              <td colspan="5" className="data">{data?.student?.identification_mark_1 ? (data?.student?.identification_mark_1).toUpperCase() : '*******'}</td>
            </tr>
            <tr>
              <td>20</td>
              <td style={{backgroundColor: "#F2F2F2"}}>Headmaster's Signature<br />(When Leaving Recorded): </td>
              <td colspan="5" className="data">{data?.headmaster_sign ? 'YES' : 'NO'}</td>
            </tr>
            <tr>
              <td>21</td>
              <td style={{backgroundColor: "#F2F2F2"}}>Headmaster's Signature: </td>
              <td colspan="5" className="data"></td>
            </tr>
            <tr>
              <td>22</td>
              <td style={{backgroundColor: "#F2F2F2"}}>Remark: </td>
              <td colspan="5" className="data">{data?.remark && (data?.remark).toUpperCase()}</td>
            </tr>
            <tr>
              <td colspan="7">
                <div className="footer">
                  <div>
                    <p>Date: {data?.date && format(data?.date, 'dd-MM-yyyy')}</p>
                    <p>Place: {data?.institute?.city?.name && (data?.institute?.city?.name).toUpperCase()}</p>
                  </div>
                  <div className="signs">
                    <div className="signs-clerk">Clerk</div>
                    <div className="signs-headmaster">{`${data?.institute?.user?.users_designation && (data?.institute?.user?.users_designation).charAt(0).toUpperCase() + (data?.institute?.user?.users_designation).slice(1)}'s Signature`}</div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="8" className="powered text-uppercase">
                Proudly Powered By: <a href="https://www.inshatech.com" className="link-dark" target="_blank" rel='noreferrer'>Insha Technologies</a>
              </td>
            </tr>
          </table>
        </main>
      </body>
    </>
  );
});

export default NirgamTemplate
