import { useEffect, useState } from "react";
import Dashboard from "./pages/Dashboard";
// import i18n from "./utils/i18n";
// import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import NavBar from "./components/NavBar";
import Header from "./components/Header";
import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import Verification from "./pages/auth/Verification";
import NotFound from "./pages/error/NotFound";
import ServerError from "./pages/error/ServerError";
import Profile from "./pages/user/Profile";
import Setting from "./components/Setting";
import Footer from "./components/Footer";
import AuthOutlet from "@auth-kit/react-router/AuthOutlet";
import Registration from "./pages/auth/Registration";
import GeneralInfo from "./pages/institute/GeneralInfo";
import Session from "./pages/institute/Session";
import Class from "./pages/institute/Class";
import Division from "./pages/institute/Division";
import Caste from "./pages/institute/Caste";
import AddAdmission from "./pages/admission/AddAdmission";
import ManageAdmission from "./pages/admission/ManageAdmission";
import EditAdmission from "./pages/admission/EditAdmission";
import Promote from "./pages/admission/Promote";
import Bonafide from "./pages/generate/Bonafide";
import NirgamUtara from "./pages/generate/NirgamUtara";
import TransferCertificate from "./pages/generate/TransferCertificate";
import { useSelector } from "react-redux";
import Expired from "./pages/error/Expired";
import Success from "./pages/payment/Success";
import Failed from "./pages/payment/Failed";
import Invoice from "./pages/invoice/Invoices";
//google analytics
import ReactGA from 'react-ga4';
//tawkto Chat
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { tawkConfiguration } from "./utils/config";
import IPInfo from 'ip-info-react';
import Terms from "./pages/policy/Terms";
import Privacy from "./pages/policy/Privacy";
import Refund from "./pages/policy/Refund";

const hideHeaderFooterRoutes = [
  '/auth/login',
  '/signup',
  '/auth/forgot-password',
  '/auth/reset-password',
  '/auth/verification',
  '/auth/register',
  '/404',
  '/500',
  '/download/bonafide',
];
const validRoutes = [
  '/',
  '/dashboard',
  '/404',
  '/500',
  '/auth/login',
  '/auth/forgot-password',
  '/auth/reset-password',
  '/auth/verification',
  '/account/profile',
  '/auth/register',
  '/institute/general-information',
  '/institute/session',
  '/institute/class',
  '/institute/division',
  '/institute/caste',
  '/admission/add',
  '/admission/manage',
  '/admission/edit/student/',
  '/admission/promote',
  '/generate/bonafide',
  '/generate/nirgam-utara',
  '/generate/transfer-certificate',
  '/account/expired',
  '/account/payment/success',
  '/account/payment/failed',
  '/account/invoice',
  '/policies/terms-and-conditions',
  '/policies/privacy-policy',
  '/policies/refund-policy'
];

const protectedRoutes = [
  '/institute/session',
  '/institute/class',
  '/institute/division',
  '/institute/caste',
  '/admission/add',
  '/admission/manage',
  '/admission/edit/student/',
  '/admission/promote',
  '/generate/bonafide',
  '/generate/nirgam-utara',
  '/generate/transfer-certificate',
  '/'
];

function App() {
  const userDetails = useSelector((state) => state.user.userDetails);
  // Translate
  // const { t } = useTranslation();
  // useEffect(() => {
  //   // Set language
  //   i18n.changeLanguage(localStorage.getItem('i18nextLng') || 'en');
  // }, []);

  // Navbar
  const [isSidebarHidden, setIsSidebarHidden] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarHidden(prevState => !prevState);
  };

  //Navigation
  const location = useLocation();
  const pathname = location.pathname;
  const hideHeaderFooter = hideHeaderFooterRoutes.includes(pathname);

  useEffect(() => {
    setIsSidebarHidden(false)
  }, [pathname])

  //Google Analytics
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);

  if (protectedRoutes.includes(pathname)) {
    if (userDetails?.institute?.status === 'inactive') { return <Navigate to="/account/expired" /> }
  }

  // If route is not valid, navigate to 404 page
  if (!validRoutes.includes(pathname)) {
    if (pathname.includes('/admission/edit/student/')) {

    } else {
      return <Navigate to="/404" />;
    }
  }

  return (
    <>
      <IPInfo>
        {!hideHeaderFooter &&
          <>
            <NavBar isHidden={isSidebarHidden} pathname={pathname} />
            <Header onClick={toggleSidebar} />
            <Setting />
          </>
        }
        < TawkMessengerReact
          propertyId={tawkConfiguration.propertyId}
          widgetId={tawkConfiguration.widgetId} />
        <Routes>
          {/* Secure routes */}
          <Route element={<AuthOutlet fallbackPath='/auth/login' />}>
            {/* Dashboard */}
            <Route path='/' element={<Dashboard />} />
            {/* Admission */}
            <Route path='/admission/add' element={<AddAdmission />} />
            <Route path='/admission/manage' element={<ManageAdmission />} />
            <Route path='/admission/edit/student/:id' element={<EditAdmission />} />
            <Route path='/admission/promote' element={<Promote />} />

            {/* Generate */}
            <Route path='/generate/bonafide' element={<Bonafide />} />
            <Route path='/generate/nirgam-utara' element={<NirgamUtara />} />
            <Route path='/generate/transfer-certificate' element={<TransferCertificate />} />

            {/* User */}
            <Route path='/account/profile' element={<Profile />} />
            {/* Institution */}
            <Route path='/institute/general-information' element={<GeneralInfo />} />
            <Route path='/institute/session' element={<Session />} />
            <Route path='/institute/class' element={<Class />} />
            <Route path='/institute/division' element={<Division />} />
            <Route path='/institute/caste' element={<Caste />} />

          </Route>

          {/* Auth */}
          <Route path='/auth/login' element={<Login />} />
          <Route path='/auth/forgot-password' element={<ForgotPassword />} />
          <Route path='/auth/reset-password' element={<ResetPassword />} />
          <Route path='/auth/verification' element={<Verification />} />
          <Route path='/auth/register' element={<Registration />} />
          {/* Error */}
          <Route path='/404' element={<NotFound />} />
          <Route path='/500' element={<ServerError />} />
          <Route path='/account/expired' element={<Expired />} />

          {/* Payment */}
          <Route path='/account/payment/success' element={<Success />} />
          <Route path='/account/payment/failed' element={<Failed />} />
          <Route path='/account/invoice' element={<Invoice />} />

          {/* Policy */}
          <Route path='/policies/terms-and-conditions' element={<Terms />} />
          <Route path='/policies/privacy-policy' element={<Privacy />} />
          <Route path='/policies/refund-policy' element={<Refund />} />

        </Routes>
        {!hideHeaderFooter && <Footer />}
      </IPInfo>
    </>
  );
}

export default App;
