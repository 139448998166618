import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { patchData, postData, putData, uploadData } from "../../api";

const fetchUser = createAsyncThunk('fetchUser', async (data, thunkAPI) => {
  try {
    const response = await postData('administration/user/single', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const putUser = createAsyncThunk('putUser', async (data, thunkAPI) => {
  try {
    const response = await putData('administration/user/update', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const changePassword = createAsyncThunk('changePassword', async (data, thunkAPI) => {
  try {
    const response = await patchData('administration/user/auth/by-self/change-password', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const userImage = createAsyncThunk('userImage', async (data, thunkAPI) => {
  try {
    const response = await uploadData('administration/user/upload', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const UserSlice = createSlice({
  name: 'user',
  initialState: {
    isLoading: false,
    isError: false,
    userDetails: {},
    instituteDetails: {},
    _id: null,
    isData: false
  },
  reducers: {
    userDetails(state, action) {
      state.userDetails = action.payload.userDetails;
      state._id = action.payload.userDetails._id;
    }
  },
  extraReducers: (builder) => {
    builder
      //fetch User
      .addCase(fetchUser.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isData = false;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userDetails = action.payload.record;
        state._id = action.payload.record._id;
        state.isError = false;
        state.isData = true;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isData = false;
        console.error("Error fetching user:", action.payload);
      })
      //fetch User
      .addCase(putUser.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isData = false;
      })
      .addCase(putUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userDetails = action.payload.response;
        state._id = action.payload.response._id;
        state.isError = false;
        state.isData = true;
      })
      .addCase(putUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isData = false;
        console.error("Error fetching user:", action.payload);
      })
      //User Image
      .addCase(userImage.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isData = false;
      })
      .addCase(userImage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userDetails = action.payload.response;
        state._id = action.payload.response._id;
        state.isError = false;
        state.isData = true;
      })
      .addCase(userImage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isData = false;
        console.error("Error fetching user:", action.payload);
      })
      //Patch User
      .addCase(changePassword.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isData = false;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userDetails = action.payload.response;
        state._id = action.payload.response._id;
        state.isError = false;
        state.isData = true;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isData = false;
        console.error("Error fetching user:", action.payload);
      })
  }
});

export { UserSlice, fetchUser, putUser, userImage, changePassword };
export const { userDetails } = UserSlice.actions;
