import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import React, { useState, useEffect, useRef } from "react";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { format } from "date-fns";
import { showAlertMessage } from "../../utils/sweetAlert";
import { useDispatch } from "react-redux";
import { avatar_2 } from "../../utils/images";
import { TiPrinter } from "react-icons/ti";
import { Button } from "primereact/button";
import { useReactToPrint } from "react-to-print";
import { Tag } from "primereact/tag";
import { putNirgam } from "../../store/slices/NirgamSlice";
import NirgamTemplate from "../../templates/NirgamTemplate";
import { FilterMatchMode } from "primereact/api";

const NirgamTable = ({ nirgamDetails, userDetails }) => {
  const dispatch = useDispatch();
  const [nirgams, setNirgams] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setNirgams(
      nirgamDetails.map((item) => ({
        ...item,
        _id: item?._id,
        name: item?.student?.name,
        father: item?.student?.parent?.f_name,
        admission_no: item?.student?.admission_no,
        session: item?.student?.session,
        date: item?.date && format(item?.date, "yyyy-MM-dd"),
        headmaster_sign: item?.headmaster_sign,
        remark: item?.remark,
        status: item?.student.isLeaved ? "Leaved" : "Active",
        user: item?.user?.name,
      }))
    );
    setLoading(false);
  }, [nirgamDetails, userDetails]);

  const onRowEditComplete = async (e) => {
    try {
      const { newData } = e;
      const { __v: deleteV, ...rest } = newData; // Delete key
      const values = {
        _id: rest._id,
        query: { date: rest.date, remark: rest.remark, user: userDetails?._id, headmaster_sign: rest.headmaster_sign },
      }; // Update user details
      const response = await dispatch(putNirgam(values));
      if (response?.payload?.status === "success") {
        await showAlertMessage({ text: response?.payload?.message });
      } else {
        throw new Error(response?.payload?.response?.data.message);
      }
    } catch (error) {
      showAlertMessage({
        icon: "error",
        title: "Error occurred!",
        text: error.message,
      });
    }
  };

  const textEditor = (options) => {
    return (
      <input
        type="text"
        className="form-control"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  const dateEditor = (options) => {
    return (
      <input
        type="date"
        className="form-control"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  const allowEdit = (rowData) => {
    return rowData.name !== "Blue Band";
  };

  const actions = (rowData) => {
    return (
      <>
        <div className="d-flex gap-2 mb-4">
          <Button
            className="btn btn-icon btn-light-primary"
            icon={<TiPrinter />}
            tooltip="Print"
            onClick={() => download(rowData)}
          />
        </div>
      </>
    );
  };

  //Status
  const getSeverity = (value) => {
    switch (value) {
      case "Active":
        return "success";
      case "Leaved":
        return "danger";
      default:
        return null;
    }
  };
  const statusBodyTemplate = (rowData) => {
    return (
      <Tag value={rowData.status} severity={getSeverity(rowData.status)}></Tag>
    );
  };

  //Print
  const nirgamRef = useRef();
  const [nirgamData, setNirgamData] = useState({});
  const download = (rowData) => {
    setNirgamData(rowData);
    setLoading(true);
    setTimeout(() => {
      print();
      setLoading(false);
    }, 1000);
  };

  const print = useReactToPrint({
    content: () => nirgamRef.current,
    documentTitle: "Nirgam Utara " + format(new Date(), 'dd-MM-yyyy HH:mm:ss bb'),
    removeAfterPrint: true,
  });
  const imageBodyTemplate = (rowData) => {
    return (
      <img
        src={rowData.student.image ? rowData.student.image : avatar_2}
        alt=""
        className="user-avtar wid-45 rounded-circle"
      />
    );
  };

  const style = {
    backgroundColor: "rgba(255, 255, 255, 0.3)", // Adjust opacity as needed
    backdropFilter: "blur(10px)", // Adjust blur amount
    width: "auto", // Adjust
    textAlign: "center", // Adjust
  };

  //Sign
  const sign = (rowData) => {
    return (
      <input
        type="checkbox"
        className="form-control form-check-input input-light-primary"
        checked={rowData.headmaster_sign}
      ></input>
    );
  };

  const checkBoxEditor = (options) => {
    return (
      <input
        type="checkbox"
        className="form-control form-check-input input-light-primary"
        checked={options.value}
        onChange={(e) => options.editorCallback(e.target.checked)}
      />
    );
  };

  const dateBodyTemplate = (rowData) => {
    return format(rowData.date, 'dd-MM-yyyy');
  }

  // Search
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <>
        <div className="row text-sm-end col-12">
          <div className="col-sm-6">
            <div className="form-group">
              <input
                className="form-control"
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
                placeholder="Search"
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const header = renderHeader();

  return (
    <>
      <div style={{ display: "none" }}>
        <NirgamTemplate ref={nirgamRef} data={nirgamData} />
      </div>
      <div className="dt-responsive table-responsive">
        <DataTable
          size="normal"
          className="table-striped table-hover table-bordered nowrap"
          stripedRows
          showGridlines
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          value={nirgams}
          sortField="date"
          sortOrder={-1}
          scrollable
          scrollHeight="100%"
          editMode="row"
          dataKey="id"
          onRowEditComplete={onRowEditComplete}
          tableStyle={{ minWidth: "50rem" }}
          loading={loading}
          filters={filters}
          filterDisplay="row"
          globalFilterFields={[
            "name",
            "father",
            "mother",
            "mobile",
            "admission_no",
          ]}
          header={header}
          emptyMessage="No records found."
        >
          <Column
            field="_id"
            header="Id"
            style={{ width: "auto", display: "none" }}
          ></Column>
          <Column
            header="Image"
            body={imageBodyTemplate}
            alignFrozen="left"
            frozen
            sortable
            style={style}
          ></Column>
          <Column
            field="name"
            header="Name"
            alignFrozen="left"
            frozen
            sortable
            style={style}
          ></Column>
          <Column
            field="father"
            header="Father"
            sortable
            style={{ width: "auto", textAlign: "center" }}
          ></Column>
          <Column
            field="admission_no"
            header="ADM No"
            sortable
            style={{ width: "auto" }}
          ></Column>
          <Column
            field="session"
            header="Session"
            sortable
            style={{ width: "auto" }}
          ></Column>
          <Column
            field="date"
            header="Date"
            body={dateBodyTemplate}
            editor={(options) => dateEditor(options)}
            sortable
            style={{ width: "auto" }}
          ></Column>
          <Column
            field="headmaster_sign"
            header="Sign"
            body={sign}
            editor={(options) => checkBoxEditor(options)}
            sortable
            style={{ width: "auto", textAlign: "center" }}
          ></Column>
          <Column
            field="remark"
            header="Remark"
            editor={(options) => textEditor(options)}
            sortable
            style={{ width: "auto" }}
          ></Column>
          <Column
            field="status"
            header="Status"
            body={statusBodyTemplate}
            sortable
            style={{ width: "auto" }}
          ></Column>
          <Column
            field="user"
            header="User"
            sortable
            style={{ width: "auto" }}
          ></Column>
          <Column
            rowEditor={allowEdit}
            header="Update"
            headerStyle={{ width: "auto", minWidth: "8rem" }}
            frozen
            alignFrozen="right"
            bodyStyle={style}
          ></Column>
          <Column
            header="Actions"
            style={style}
            alignFrozen="right"
            frozen
            body={actions}
          ></Column>
        </DataTable>
      </div>
    </>
  );
};

export default NirgamTable;
