import { createSlice } from "@reduxjs/toolkit";

const PromoteSlice = createSlice({
  name: 'auth',
  initialState: {
    promoteStudentDetails: {},
  },
  reducers: {
    storePromoteStudents(state, action) {
      state.promoteStudentDetails = action.payload;
    }
  },
})

export { PromoteSlice }
export const { storePromoteStudents } = PromoteSlice.actions;