import { useSelector } from "react-redux";
import PromoteSearchForm from "../../components/admission/promote/PromoteSearchForm";
import PromoteTable from "../../components/admission/promote/PromoteTable";
import Breadcrumb from "../../components/common/Breadcrumb";

const Promote = () => {
  const { userDetails } = useSelector((state) => state.user);
  const { admissionDetails } = useSelector((state) => state.admission);
  return (
    <>
      <section className="pc-container">
        <div className="pc-content">
          <Breadcrumb />
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div id="sticky-action" className="sticky-action">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col-sm-6">
                        <h5>Admissions</h5>
                      </div>
                      <div className="col-sm-6 text-sm-end mt-3 mt-sm-0">
                        <PromoteSearchForm userDetails={userDetails} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <PromoteTable admissionDetails={admissionDetails} userDetails={userDetails} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
};

export default Promote
