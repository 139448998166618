import React from 'react';
import './css/bonafide.css';
import 'bootstrap'
import { format } from 'date-fns';
import { dateToWords } from '../utils/common';

const BonafideTemplate = React.forwardRef((props, ref) => {
  const { data } = props
  return (
    <>
      <main ref={ref}>
        <div className="boarder">
          <div className="inner-top"></div>
          <div className="main-body">
            <div className="header">
              <div className="logo">
                <img src={data?.institute?.image && data?.institute?.image} alt="logo" />
              </div>
              <div className="school">
                <div className="foundation">{data?.institute?.organization_name}</div>
                <div className="schoolName">{`${data?.institute?.institute_name}, ${data?.institute?.city?.name}`}</div>
                <div className="foundation">{`${data.institute?.address}, ${data?.institute?.city?.name} - ${data?.institute?.pincode}, Mobile: ${data?.institute?.mobile} `}</div>
                <div className="certificate">BONAFIDE CERTIFICATE</div>
              </div>
            </div>
            <div className="main-content">
              This is to certify that <strong>{data?.student?.name && (data?.student?.name).toUpperCase()}</strong> is a bonafide student of this institute during the year <strong>{data?.student?.session}</strong>. During this year {data?.student?.gender === 'male' ? 'he' : 'she'} {data?.student?.isLeaved ? 'was' : 'is'} studying in the class <strong>{data?.student?.class}</strong>. {data?.student?.gender === 'male' ? 'his' : 'her'} date of birth is <strong>{data?.student?.dob && format(data?.student?.dob, 'dd-MM-yyyy')} {data?.student?.dob && (dateToWords(data?.student?.dob).toUpperCase())}</strong>. {data?.student?.gender === 'male' ? 'his' : 'her'} father's name is <strong>{data?.student?.parent?.f_name && (data?.student?.parent?.f_name).toUpperCase()}</strong> and mother's name is <strong>{data?.student?.parent?.m_name && (data?.student?.parent?.m_name).toUpperCase()}</strong>
              {data?.student?.gender === 'male' ? ' his' : ' her'} conduct is good.
            </div>
            <div className="other">
              <div className="other-info">
                <div>Admission No. : {data?.student?.admission_no}</div>
                <div>Date : {data?.date && format(data?.date, 'dd-MM-yyyy')}</div>
                <div>Place : {data?.institute?.city?.name && (data?.institute?.city?.name).toUpperCase()}</div>
              </div>
              <div className="img">
                <img src={data?.student?.image && data?.student?.image} data-filename="img" alt='' />
              </div>
            </div>
            <div className="signs">
              <div className="signs-clerk">Clerk</div>
              <div className="signs-teacher">Class Teacher</div>
              <div className="signs-headmaster">{`${data?.institute?.user?.users_designation && (data?.institute?.user?.users_designation).charAt(0).toUpperCase() + (data?.institute?.user?.users_designation).slice(1)}'s Signature`}</div>
            </div>
          </div>
          <div className="inner-bottom">
            <p className="text-center text-uppercase">Proudly Powered By: <a className="link" href="https://www.inshatech.com" target="_blank" rel='noreferrer'>Insha Technologies</a></p>
          </div>
        </div>
      </main>
    </>
  );
});

export default BonafideTemplate;