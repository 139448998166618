import Breadcrumb from "../../components/common/Breadcrumb";
import { transaction_success } from "../../utils/images";
import Error from "../../components/common/Error";

const Success = () => {
  return (
    <>
      <div className="pc-container">
        <div className="pc-content">
        <Breadcrumb />
          <Error image={transaction_success} title="Your payment is successful." pageType="internal" description="Thank you for renewing your account with us; your continued support is greatly appreciated!" />
        </div>
      </div>
    </>
  )
};

export default Success
