import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postData, putData } from "../../api";

const fetchLastSchool = createAsyncThunk('fetchLastSchool', async (data, thunkAPI) => {
  try {
    const response = await postData('administration/last-school/all', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const putLastSchool = createAsyncThunk('putLastSchool', async (data, thunkAPI) => {
  try {
    const response = await putData('administration/last-school/update', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const createLastSchool = createAsyncThunk('createLastSchool', async (data, thunkAPI) => {
  try {
    const response = await postData('administration/last-school/add', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const LastSchoolSlice = createSlice({
  name: 'lastSchoolSlice',
  initialState: {
    isLoading: false,
    isError: false,
    lastSchoolDetails: [],
  },
  extraReducers: (builder) => {
    builder
      //fetch
      .addCase(fetchLastSchool.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchLastSchool.fulfilled, (state, action) => {
        state.isLoading = false;
        state.lastSchoolDetails = action.payload.records;
        state.isError = false;
      })
      .addCase(fetchLastSchool.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
      //put
      .addCase(putLastSchool.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(putLastSchool.fulfilled, (state, action) => {
        state.isLoading = false;
        const updatedLastSchool = action.payload.response;
        const index = state.lastSchoolDetails.findIndex(item => item._id === updatedLastSchool._id);
        if (index !== -1) {
          // Create a new array with the updated item
          state.lastSchoolDetails[index] = updatedLastSchool;
        } else {
          // Handle if the item is not found, possibly add it to the array
          state.lastSchoolDetails.push(updatedLastSchool);
        }
        state.isError = false;
      })
      .addCase(putLastSchool.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
      //create
      .addCase(createLastSchool.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(createLastSchool.fulfilled, (state, action) => {
        state.isLoading = false;
        state.lastSchoolDetails.push(action.payload.response);
        state.isError = false;
      })
      .addCase(createLastSchool.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
  }
})

export { LastSchoolSlice, fetchLastSchool, putLastSchool, createLastSchool }
export const { lastSchoolDetails } = LastSchoolSlice.actions;