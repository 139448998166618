import { Link } from "react-router-dom";
import { logo } from "../../utils/images";
import { appVersion } from "../../utils/config";

const Logo = (props) => {
  return (
    <>
      <Link to="/" className={props.className}>
        <img src={logo} alt={props.alt} height={props.height} width={props.width} />
        <span className="badge bg-light-success rounded-pill ms-2 theme-version">{appVersion}</span>
      </Link>
    </>
  )
};

export default Logo
