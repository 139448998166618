import { useParams } from "react-router-dom";
import Breadcrumb from "../../components/common/Breadcrumb";
import AdmissionForm from "../../components/admission/new/AdmissionForm";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchAdmission } from "../../store/slices/AdmissionSlice";
import AdmissionSidePanel from "../../components/admission/manage/AdmissionSidePanel";

const EditAdmission = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { userDetails } = useSelector((state) => state.user);
  const { admissionDetails } = useSelector((state) => state.admission);
  const [studentDetails, setStudentDetails] = useState(null);

  useEffect(() => {
    dispatch(fetchAdmission({
      institute: userDetails?.institute?._id,
      udise: userDetails?.institute?.udise,
      _id: id,
    }))
  }, [dispatch, userDetails, id])

  useEffect(()=>{
    setStudentDetails(admissionDetails[0])
  },[admissionDetails, setStudentDetails])

  return (
    <>
      <section className="pc-container">
        <div className="pc-content">
          <Breadcrumb />
          <div className="row">
            <div className="col-lg-3">
              <AdmissionSidePanel student={studentDetails} />
            </div>
            <div className="col-lg-9">
              <div className="card">
                <AdmissionForm userDetails={userDetails} type={'update'} student={studentDetails} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
};

export default EditAdmission
