import { img_auth_side_img } from "../../utils/images";

const AuthSideContent = (props) => {
  return (
    <>
      <div className="auth-sidecontent">
        <img src={img_auth_side_img} alt="images" className={props.className} />
      </div>
    </>
  )
};

export default AuthSideContent
