import { format } from "date-fns";
import { avatar_2 } from "../../../utils/images";
import { TbCake, TbPasswordFingerprint } from "react-icons/tb";
import { MdBloodtype } from "react-icons/md";
import { FaFemale, FaMale } from "react-icons/fa";
import getAge from "get-age";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { showAlertMessage } from "../../../utils/sweetAlert";
import { Spinner } from "react-bootstrap";
import { studentImage } from "../../../store/slices/AdmissionSlice";

const AdmissionSidePanel = ({ student }) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const handleImageChange = async (e) => {
    setLoading(true)
    try {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      formData.append("_id", student?._id);

      const response = await dispatch(studentImage(formData));
      if (response?.payload?.status === 'success') {
        await showAlertMessage({ text: response?.payload?.message });
      } else {
        throw new Error(response?.payload?.response?.data.message);
      }
    } catch (error) {
      showAlertMessage({
        icon: 'error',
        title: 'Error occurred!',
        text: error.message,
      });
    }
    setLoading(false)
  };
  return (
    <>
      <div className="card">
        <div className="card-body position-relative">
          <div className="position-absolute end-0 top-0 p-3">
            <span className={`badge bg-${student?.isLeaved ? 'danger' : 'primary'}`}>{student?.isLeaved ? 'Leaved' : 'Active'}</span>
          </div>
          <div className="text-center mt-3">
            {isLoading ? <Spinner animation="border" size="lg" /> :
              <div className="user-upload wid-75">
                <img className="img-fluid" src={student?.image ? student?.image : avatar_2}
                  alt="" />
                <label htmlFor="uplfile" className="img-avtar-upload">
                  <i className="ti ti-camera f-24 mb-1"></i>
                  <span>Upload</span>
                </label>
                <input
                  type="file"
                  id="uplfile"
                  className="d-none"
                  accept='image/*'
                  onChange={handleImageChange}
                />
              </div>
            }
            <h5 className="mb-0">{student?.name}</h5>
            <p className="text-muted text-sm">{`${student?.class} - ${student?.division} (${student?.roll_number})`}</p>
            <hr className="my-3 border border-secondary-subtle" />
            <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
              <FaMale className="me-2" />
              <p className="mb-0">{student?.parent?.f_name}</p>
            </div>
            <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
              <FaFemale className="me-2" />
              <p className="mb-0">{student?.parent?.m_name}</p>
            </div>
            <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
              <TbCake className="me-2" />
              <p className="mb-0 me-2">{student?.dob && format(student?.dob, 'dd-MM-yyyy')}</p>
              <p className="mb-0">{student?.dob && ` (${getAge(format(student?.dob, "yyyy-MM-dd"))} Yrs)`}</p>
            </div>
            <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
              <MdBloodtype className="me-2" />
              <p className="mb-0">{student?.blood_group}</p>
            </div>
            <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
              <i className="ti ti-phone me-2"></i>
              <p className="mb-0">{student?.parent?.f_mobile} - {student?.parent?.m_mobile} </p>
            </div>
            <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
              <i className="ti ti-mail me-2"></i>
              <p className="mb-0">{student?.parent?.email}</p>
            </div>
            <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
              <TbPasswordFingerprint className="me-2" />
              <p className="mb-0">{student?._id}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default AdmissionSidePanel
