import { Spinner } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from '../../common/Button';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { showAlertMessage } from '../../../utils/sweetAlert';
import * as Yup from 'yup';
import Select from 'react-select';
import { religions, statusOptions } from '../../../utils/common';
import { useDispatch } from 'react-redux';
import { createCaste } from '../../../store/slices/CasteSlice';
const CasteForm = ({ show, handleClose, userDetails }) => {
  const dispatch = useDispatch();
  // Form initial values
  const initialValues = {
    udise: userDetails?.institute?.udise,
    institute: userDetails?.institute?._id,
    caste: '',
    religion: '',
    status: 'active',
    user: userDetails?._id
  }
  // Form validation
  const validationSchema = Yup.object().shape({
    religion: Yup.string()
      .required('Religion is required'),
    caste: Yup.string()
      .required('Caste is required'),
    status: Yup.string()
      .required('Status is required'),
  });
  // Form submit
  const onSubmit = async (values, actions) => {
    try {
      const response = await dispatch(createCaste(values));
      if (response?.payload?.status === 'success') {
        await showAlertMessage({ text: response?.payload?.message });
        handleClose(true)
      } else {
        throw new Error(response?.payload?.response?.data.message);
      }
    } catch (error) {
      showAlertMessage({
        icon: 'error',
        title: 'Error occurred!',
        text: error.message,
      });
    } finally {
      actions.setSubmitting(false);
    }
  };


  return (
    <>
      <Offcanvas show={show} onHide={handleClose} key="end" placement="end" name="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Caste</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize={true}
          >
            {({ isSubmitting, setFieldValue, values, handleBlur, resetForm }) => (
              <Form>
                <div className="form-group">
                  <label className="form-label">Religion*</label>
                  <Select
                    name="religion"
                    cacheOptions
                    defaultOptions
                    options={religions}
                    value={religions.find(option => option.value === values.religion)}
                    onChange={(option) => setFieldValue('religion', option ? option.value : '')}
                    onBlur={handleBlur('religion')}
                  />
                  <ErrorMessage className="text-danger mb-1" name="religion" component="div" />
                </div>
                <div className="form-group">
                  <label className="form-label">Caste*</label>
                  <Field
                    className="form-control"
                    name="caste"
                    autoFocus
                  />
                  <ErrorMessage className="text-danger mb-1" name="caste" component="div" />
                </div>
                <div className="form-group">
                  <label className="form-label">Status*</label>
                  <Select
                    name="status"
                    options={statusOptions}
                    value={statusOptions.find(option => option.value === values.status)}
                    onChange={(option) => setFieldValue('status', option ? option.value : '')}
                    onBlur={handleBlur('status')}
                  />
                  <ErrorMessage className="text-danger mb-1" name="status" component="div" />
                </div>
                <div className="card-footer text-end btn-page">
                  <Button className="btn btn-outline-danger" onClick={() => resetForm()} text="Cancel" />
                  <Button className="btn btn-primary" type="submit" icon={isSubmitting && <Spinner animation="border" size="sm" />} disabled={isSubmitting} text={isSubmitting ? "Processing..." : "Add New"} />
                </div>
              </Form>
            )}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
};

export default CasteForm
