import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postData, putData, uploadData } from "../../api";

const fetchInstitute = createAsyncThunk('fetchInstitute', async (data, thunkAPI) => {
  try {
    const response = await postData('administration/institute/single', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const putInstitute = createAsyncThunk('putInstitute', async (data, thunkAPI) => {
  try {
    const response = await putData('administration/institute/update', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const uploadLogo = createAsyncThunk('uploadLogo', async (data, thunkAPI) => {
  try {
    const response = await uploadData('administration/institute/upload', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const InstituteSlice = createSlice({
  name: 'institute',
  initialState: {
    isLoading: false,
    isError: false,
    instituteDetails: {},
    _id: null,
  },
  extraReducers: (builder) => {
    builder
      //fetch
      .addCase(fetchInstitute.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isData = false;
      })
      .addCase(fetchInstitute.fulfilled, (state, action) => {
        state.isLoading = false;
        state.instituteDetails = action.payload.record;
        state._id = action.payload.record._id;
        state.isError = false;
        state.isData = true;
      })
      .addCase(fetchInstitute.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isData = false;
        console.error("Error fetching user:", action.payload);
      })
      //put
      .addCase(putInstitute.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isData = false;
      })
      .addCase(putInstitute.fulfilled, (state, action) => {
        state.isLoading = false;
        state.instituteDetails = action.payload.response;
        state._id = action.payload.response._id;
        state.isError = false;
        state.isData = true;
      })
      .addCase(putInstitute.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isData = false;
        console.error("Error fetching user:", action.payload);
      })
      //User Image
      .addCase(uploadLogo.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isData = false;
      })
      .addCase(uploadLogo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.instituteDetails = action.payload.response;
        state._id = action.payload.response._id;
        state.isError = false;
        state.isData = true;
      })
      .addCase(uploadLogo.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isData = false;
        console.error("Error fetching user:", action.payload);
      })
  }
})

export { InstituteSlice, fetchInstitute, putInstitute, uploadLogo}
export const { instituteDetails } = InstituteSlice.actions;