import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import Select from 'react-select';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { fetchClass } from "../../../store/slices/ClassSlice";
import { fetchDivision } from "../../../store/slices/DivisionSlice";
import { showAlertMessage } from "../../../utils/sweetAlert";
import Button from "../../common/Button";
import { fetchAdmission } from "../../../store/slices/AdmissionSlice";
import { fetchSession } from "../../../store/slices/SessionSlice";
import PromoteForm from "./PromoteForm";

const PromoteSearchForm = ({ userDetails }) => {
  const dispatch = useDispatch();
  const [prams, setPrams] = useState([]);
  const [searchValues, setSearchValues] = useState([]);

  //Fetch admissions
  useEffect(() => {
    dispatch(fetchAdmission(prams))
  }, [dispatch, prams]);

  //Fetch Sessions
  const { sessionDetails } = useSelector((state) => state.session);
  useEffect(() => {
    dispatch(fetchSession({
      institute: userDetails?.institute?._id
    }))
  }, [dispatch, userDetails])

  //Set Sessions
  const [oldSessions, setOldSessions] = useState([]);
  const [currentSession, setCurrentSession] = useState([]);
  useEffect(() => {
    const getOldSessions = async () => {
      const currentSession = sessionDetails
        .filter(item => item.active_session === true)
        .map(item => ({ value: item.session, label: item.session }));
      setCurrentSession(currentSession);
      const inactiveSessions = sessionDetails
        .filter(item => item.active_session !== true)
        .map(item => ({ value: item.session, label: item.session }));
      setOldSessions(inactiveSessions);
    };
    getOldSessions();
  }, [sessionDetails]);

  //Get Standards (Class)
  const [standardOptions, setStandardOptions] = useState([]);
  useEffect(() => {
    dispatch(fetchClass({
      institute: userDetails?.institute?._id
    }))
  }, [dispatch, userDetails])
  const { classDetails } = useSelector((state) => state.class);
  useEffect(() => {
    const getStandards = async () => {
      setStandardOptions(() => classDetails.map(item => ({ value: item.class, label: item.class })));
    }
    getStandards()
  }, [classDetails])

  // Divisions
  const { divisionDetails } = useSelector((state) => state.division);
  const [divisions, setDivisions] = useState([])
  const handleCurrentClassChange = (option, setFieldValue) => {
    setFieldValue('class', option ? option.value : '');
    setDivisions([])
    dispatch(fetchDivision({ institute: userDetails?.institute?._id, class: option.value }))
  }

  useEffect(() => {
    const getDivisions = async () => {
      setDivisions(() => divisionDetails.map(item => ({ value: item.division, label: item.division })));
    }
    getDivisions()
  }, [divisionDetails])
  const initialValues = {
    institute: userDetails?.institute?._id,
    udise: userDetails?.institute?.udise,
    user: userDetails?._id,
    session: '',
    class: '',
    division: '',
    isLeaved: false,
  }
  // Form validation
  const validationSchema = Yup.object().shape({
    session: Yup.string()
      .required('Session is required'),
    class: Yup.string()
      .required('Class is required'),
    division: Yup.string()
      .required('Division is required'),
  });
  // Form submit
  const onSubmit = async (values, actions) => {
    try {
      setPrams(values);
      setSearchValues(values)
    } catch (error) {
      showAlertMessage({
        icon: 'error',
        title: 'Error occurred!',
        text: error.message,
      });
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <>
      <div className="row text-sm-start">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {({ isSubmitting, setFieldValue, values, handleBlur, resetForm }) => (
            <Form>
              <div className="row">
                <div className="col-sm-3">
                  <div className="form-group">
                    <Select
                      name="session"
                      options={oldSessions}
                      value={oldSessions.find(option => option.value === values.session)}
                      onChange={(option) => setFieldValue('session', option ? option.value : '')}
                      onBlur={handleBlur('session')}
                      placeholder="Last Session"
                      autoFocus
                    />
                    <ErrorMessage className="text-danger mb-1" name="session" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <Select
                      name="class"
                      options={standardOptions}
                      value={standardOptions.find(option => option.value === values.class)}
                      onChange={(option) => handleCurrentClassChange(option, setFieldValue)}
                      onBlur={handleBlur('class')}
                      placeholder="Class"
                    />
                    <ErrorMessage className="text-danger mb-1" name="class" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <Select
                      name="division"
                      cacheOptions
                      defaultOptions
                      options={divisions}
                      value={divisions.find(option => option.value === values.division)}
                      onChange={(option) => setFieldValue('division', option ? option.value : '')}
                      onBlur={handleBlur('division')}
                      placeholder="Division"
                    />
                    <ErrorMessage className="text-danger mb-1" name="division" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <Button className="btn btn-light-primary w-100" type="submit" icon={isSubmitting && <Spinner animation="border" size="sm" />} disabled={isSubmitting} text={isSubmitting ? "Processing..." : "Search"} />
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <hr />
        <PromoteForm currentSession={currentSession} values={searchValues} standardOptions={standardOptions} divisions={divisions} />
      </div >

    </>
  )
};

export default PromoteSearchForm
