import { useState } from "react";
import { useDispatch } from "react-redux";
import { showAlertMessage } from "../../utils/sweetAlert";
import { Spinner } from "react-bootstrap";
import { avatar_2 } from "../../utils/images";
import { uploadLogo } from "../../store/slices/InstituteSlice";

const Logo = ({instituteDetails}) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const handleImageChange = async (e) => {
    setLoading(true)
    try {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      formData.append("_id", instituteDetails?._id);
      const response = await dispatch(uploadLogo(formData));
      if (response?.payload?.status === 'success') {
        await showAlertMessage({ text: response?.payload?.message });
      } else {
        throw new Error(response?.payload?.response?.data.message);
      }
    } catch (error) {
      showAlertMessage({
        icon: 'error',
        title: 'Error occurred!',
        text: error.message,
      });
    }
    setLoading(false)
  };
  return (
    <>
      <div className="col-lg-12">
        <div className="card">
          <div className="card-header">
            <h5>Logo</h5>
          </div>
          <div className="card-body">
            <div className="col-sm-12 text-center mb-3">
              {isLoading ? <Spinner animation="border" size="lg" /> :
                <div className="user-upload wid-75">
                  <img
                    src={instituteDetails?.image ? instituteDetails?.image : avatar_2}
                    alt="Profile"
                    className="img-fluid"
                  />
                  <label htmlFor="uplfile" className="img-avtar-upload">
                    <i className="ti ti-camera f-24 mb-1"></i>
                    <span>Upload</span>
                  </label>
                  <input
                    type="file"
                    id="uplfile"
                    className="d-none"
                    accept='image/*'
                    onChange={handleImageChange}
                  />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default Logo
