import { Spinner } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from '../common/Button';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { showAlertMessage } from '../../utils/sweetAlert';
import { Yup } from '../../utils/common';
import { format } from 'date-fns';
import Select from 'react-select';
import { statusOptions } from '../../utils/common';
import { createSession } from '../../store/slices/SessionSlice';
import { useDispatch } from 'react-redux';
import { fetchUser } from '../../store/slices/UserSlice';
const SessionForm = ({ show, handleClose, userDetails }) => {
  const dispatch = useDispatch();
  //Current Date
  const today = new Date();
  // Form initial values
  const initialValues = {
    udise: userDetails?.institute?.udise,
    institute: userDetails?.institute?._id,
    session: '',
    active_session: false,
    start: format(today, 'yyyy-MM-dd'),
    end: format(today, 'yyyy-MM-dd'),
    reopen: format(today, 'yyyy-MM-dd'),
    status: 'active',
    user: userDetails?._id
  }
  // Form validation
  const validationSchema = Yup.object().shape({
    session: Yup.string()
    .required('Session is required')
    .sessionFormat('Session must be in the format yyyy-yyyy and the end year must be exactly one year after the start year'),
    start: Yup.date().required('Date of session start is required'),
    end: Yup.date().required('Date of session end is required'),
    reopen: Yup.date().required('Date of institute reopen is required'),
    status: Yup.string()
      .required('Status is required'),
  });
  // Form submit
  const onSubmit = async (values, actions) => {
    try {
      const response = await dispatch(createSession(values));
      await dispatch(fetchUser({ _id: userDetails._id }))
      if (response?.payload?.status === 'success') {
        await showAlertMessage({ text: response?.payload?.message });
        handleClose(true)
      } else {
        throw new Error(response?.payload?.response?.data.message);
      }
    } catch (error) {
      showAlertMessage({
        icon: 'error',
        title: 'Error occurred!',
        text: error.message,
      });
    } finally {
      actions.setSubmitting(false);
    }
  };


  return (
    <>
      <Offcanvas show={show} onHide={handleClose} key="end" placement="end" name="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Session</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize={true}
          >
            {({ isSubmitting, setFieldValue, values, handleBlur, resetForm }) => (
              <Form>
                <div className="form-group">
                  <label className="form-label">Session (yyyy-yyyy)*</label>
                  <Field
                    className="form-control"
                    name="session"
                    autoFocus
                  />
                  <ErrorMessage className="text-danger mb-1" name="session" component="div" />
                </div>
                <div className="form-group">
                  <label className="form-label">Start Date*</label>
                  <Field
                    type="date"
                    className="form-control"
                    name="start"
                  />
                  <ErrorMessage className="text-danger mb-1" name="start" component="div" />
                </div>
                <div className="form-group">
                  <label className="form-label">End Date*</label>
                  <Field
                    type="date"
                    className="form-control"
                    name="end"
                  />
                  <ErrorMessage className="text-danger mb-1" name="end" component="div" />
                </div>
                <div className="form-group">
                  <label className="form-label">Institute Re-open*</label>
                  <Field
                    type="date"
                    className="form-control"
                    name="reopen"
                  />
                  <ErrorMessage className="text-danger mb-1" name="reopen" component="div" />
                </div>
                <div className="form-group">
                  <label className="form-label">Status*</label>
                  <Select
                    name="status"
                    options={statusOptions}
                    value={statusOptions.find(option => option.value === values.status)}
                    onChange={(option) => setFieldValue('status', option ? option.value : '')}
                    onBlur={handleBlur('status')}
                  />
                  <ErrorMessage className="text-danger mb-1" name="status" component="div" />
                </div>
                <div className="form-group form-check">
                  <Field
                    type="checkbox"
                    className="form-check-input"
                    name="active_session"
                  />
                  <label className="form-label">Set as active session?</label>
                  <ErrorMessage className="text-danger mb-1" name="active_session" component="div" />
                </div>
                <div className="card-footer text-end btn-page">
                  <Button className="btn btn-outline-danger" onClick={() => resetForm()} text="Cancel" />
                  <Button className="btn btn-primary" type="submit" icon={isSubmitting && <Spinner animation="border" size="sm" />} disabled={isSubmitting} text={isSubmitting ? "Processing..." : "Add New"} />
                </div>
              </Form>
            )}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
};

export default SessionForm
