import { configureStore } from "@reduxjs/toolkit"
import { AuthSlice } from "./slices/AuthSlice"
import { UserSlice } from "./slices/UserSlice"
import { CSCSlice } from "./slices/CSCSlice"
import { InstituteSlice } from "./slices/InstituteSlice"
import { SessionSlice } from "./slices/SessionSlice"
import { ClassSlice } from "./slices/ClassSlice"
import { DivisionSlice } from "./slices/DivisionSlice"
import { CategorySlice } from "./slices/CategorySlice"
import { SubCasteSlice } from "./slices/SubCasteSlice"
import { LastSchoolSlice } from "./slices/LastSchoolSlice"
import { AdmissionSlice } from "./slices/AdmissionSlice"
import { PromoteSlice } from "./slices/PromoteSlice"
import { BonafideSlice } from "./slices/BonafideSlice"
import { NirgamSlice } from "./slices/NirgamSlice"
import { TCSlice } from "./slices/TCSlice"
import { CasteSlice } from "./slices/CasteSlice"
import { InvoiceSlice } from "./slices/InvoiceSlice"

const Store = configureStore({
  reducer:{
    auth: AuthSlice.reducer,
    user: UserSlice.reducer,
    csc: CSCSlice.reducer,
    institute: InstituteSlice.reducer,
    session: SessionSlice.reducer,
    class: ClassSlice.reducer,
    division: DivisionSlice.reducer,
    caste: CasteSlice.reducer,
    category: CategorySlice.reducer,
    subCaste: SubCasteSlice.reducer,
    last_school: LastSchoolSlice.reducer,
    admission: AdmissionSlice.reducer,
    promote: PromoteSlice.reducer,
    bonafide: BonafideSlice.reducer,
    nirgam: NirgamSlice.reducer,
    transfer_certificate: TCSlice.reducer,
    invoice: InvoiceSlice.reducer,
  }
})

export default Store
