
import { useContext } from 'react';
import { IPInfoContext } from 'ip-info-react';

const IpInfo = () => {
  const userInfo = useContext(IPInfoContext);
  return (
    <>
      {
        userInfo?.ip && <div className="alert alert-warning" role="alert">
          You are using <b>{userInfo?.org}</b>, <b>{userInfo?.city}</b> Internet Service with IP Address <b>{userInfo?.ip}</b>
        </div>
      }
    </>
  )
};

export default IpInfo
