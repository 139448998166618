import axios from 'axios'
import { baseUrl } from './utils/config'
import cookie from 'react-cookies'

const postData = async (endPoint, data) => {
  const AuthToken = await cookie.load('_auth')
  const response = await axios.post(baseUrl + endPoint, data, {
    headers: {
      'Authorization': AuthToken,
    }
  });
  return response.data;
}

const uploadData = async (endPoint, data) => {
  const AuthToken = await cookie.load('_auth')
  const response = await axios.post(baseUrl + endPoint, data, {
    headers: {
      'Authorization': AuthToken,
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
}

const patchData = async (endPoint, data) => {
  const AuthToken = await cookie.load('_auth')
  const response = await axios.patch(baseUrl + endPoint, data, {
    headers: {
      'Authorization': AuthToken
    }
  });
  return response.data;
}

const putData = async (endPoint, data) => {
  const AuthToken = await cookie.load('_auth')
  const response = await axios.put(baseUrl + endPoint, data, {
    headers: {
      'Authorization': AuthToken
    }
  });
  return response.data;
}


const getData = async (endPoint, data) => {
  const AuthToken = await cookie.load('_auth')
  const response = await axios.get(baseUrl + endPoint, data, {
    headers: {
      'Authorization': AuthToken
    }
  });
  return response.data;
}


export { postData, patchData, getData, putData, uploadData }