import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postData, putData } from "../../api";

const fetchDivision = createAsyncThunk('fetchDivision', async (data, thunkAPI) => {
  try {
    const response = await postData('administration/division/all', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const putDivision = createAsyncThunk('putDivision', async (data, thunkAPI) => {
  try {
    const response = await putData('administration/division/update', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const createDivision = createAsyncThunk('createDivision', async (data, thunkAPI) => {
  try {
    const response = await postData('administration/division/add', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const DivisionSlice = createSlice({
  name: 'divisionSlice',
  initialState: {
    isLoading: false,
    isError: false,
    divisionDetails: [],
  },
  extraReducers: (builder) => {
    builder
      //fetch
      .addCase(fetchDivision.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchDivision.fulfilled, (state, action) => {
        state.isLoading = false;
        state.divisionDetails = action.payload.records;
        state.isError = false;
      })
      .addCase(fetchDivision.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
      //put
      .addCase(putDivision.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(putDivision.fulfilled, (state, action) => {
        state.isLoading = false;
        const updatedDivision = action.payload.response;
        const index = state.divisionDetails.findIndex(item => item._id === updatedDivision._id);
        if (index !== -1) {
          // Create a new array with the updated item
          state.divisionDetails[index] = updatedDivision;
        } else {
          // Handle if the item is not found, possibly add it to the array
          state.divisionDetails.push(updatedDivision);
        }
        state.isError = false;
      })
      .addCase(putDivision.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
      //create
      .addCase(createDivision.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(createDivision.fulfilled, (state, action) => {
        state.isLoading = false;
        state.divisionDetails.push(action.payload.response);
        state.isError = false;
      })
      .addCase(createDivision.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
  }
})

export { DivisionSlice, fetchDivision, putDivision, createDivision }
export const { divisionDetails } = DivisionSlice.actions;