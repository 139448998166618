const mapUrl = `https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15064.861129720673!2d76.7696967!3d19.273002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd0182c504f0b19%3A0x6fc7cf8006f91675!2sInsha%20Technologies!5e0!3m2!1sen!2sin!4v1713678733344!5m2!1sen!2sin`;

const baseUrl = "https://liteapi.inshatech.com/"; //--> http://localhost:7003/ Server: https://liteapi.inshatech.com/

// tawkto 
const tawkConfiguration = {
  propertyId: "667e24b49d7f358570d43122",
  widgetId: "1i1ebuoq2",
};
// Google Analytics
const ga_MeasurementID = 'G-G1RNYBMMCB'
//Google reCaptcha
const googleReCaptcha = {
  type: "v3",
  siteKey: "6Ldjf9wpAAAAAJp7sS-rURUVGI0hEQGWHrnlQwWE"
}

const appVersion = 'v0.1.22 Lite'

export { mapUrl, baseUrl, tawkConfiguration, ga_MeasurementID, googleReCaptcha, appVersion }


