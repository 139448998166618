import Breadcrumb from "../../components/common/Breadcrumb";
import { transaction_failed } from "../../utils/images";
import Error from "../../components/common/Error";

const Failed = () => {
  return (
    <>
      <div className="pc-container">
        <div className="pc-content">
        <Breadcrumb />
          <Error image={transaction_failed} title="Some thing went wrong" pageType="internal" description="Your transaction is failed please try again. Let's try again or contact administration." />
        </div>
      </div>
    </>
  )
};

export default Failed
