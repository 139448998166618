import { Link, useNavigate } from "react-router-dom";
import AuthSideContent from "../../components/common/AuthSideContent";
import Logo from "../../components/common/Logo";
import { Tab } from "bootstrap";
import { Nav, Spinner } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { showAlertMessage } from "../../utils/sweetAlert";
import Select from 'react-select';
import { postData } from "../../api";
import Button from "../../components/common/Button";
import { users_designation } from "../../utils/common";

const Registration = () => {
  //navigate
  const navigate = useNavigate();
  // Country options
  const countries = [
    { value: '101', label: 'India' }
  ];
  // State options
  const states = [
    { value: '22', label: 'Maharashtra' }
  ];
  // City options
  const [cities, setCities] = useState([])
  useEffect(() => {
    const getCities = async () => {
      const response = await postData('administration/csc/cities', { "state": 22 });
      setCities(() => response.records.map(city => ({ value: city._id, label: city.name })));
      return response
    }
    getCities()
  }, [])

  // Form initial values
  const initialValues = {
    institute_name: '',
    udise: '',
    organization_name: '',
    permission_details: '',
    mobile: '',
    email: '',
    address: '',
    country: countries[0].value,
    state: states[0].value,
    city: '',
    pincode: '',
    name: '',
    users_designation: '',
    users_type: 'school-admin',
    contact: '',
  };
  // Form validation
  const validationSchema = Yup.object().shape({
    institute_name: Yup.string()
      .required('Institute name is required'),
    organization_name: Yup.string()
      .required('Organization name is required'),
    permission_details: Yup.string()
      .required('Permission details is required'),
    mobile: Yup.string()
      .matches(/^[6-9]\d{9}$/, 'Invalid Indian mobile number')
      .required('Mobile number is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    udise: Yup.string()
      .required('UDISE number is required'),
    address: Yup.string()
      .required('Address is required'),
    country: Yup.string()
      .required('County is required'),
    state: Yup.string()
      .required('State is required'),
    city: Yup.string()
      .required('City is required'),
    pincode: Yup.string()
      .matches(/^(4[0-4]\d{0,4})?$/, 'Pincode must start with a number between 40 and 44 and have a maximum length of 6')
      .required('Pincode is required'),
    name: Yup.string()
      .required('Full name is required'),
    users_designation: Yup.string()
      .required('Designation is required'),
  });
  // Form submit
  const onSubmit = async (values, actions) => {
    try {
      const query = {
        user: {
          name: values.name,
          mobile: values.mobile,
          email: values.email,
          udise: values.udise,
          users_designation: values.users_designation,
          users_type: values.users_type,
        },
        institute: {
          institute_name: values.institute_name,
          mobile: values.mobile,
          email: values.email,
          udise: values.udise,
          organization_name: values.organization_name,
          permission_details: values.permission_details,
          address: values.address,
          country: values.country,
          state: values.state,
          city: values.city,
          pincode: values.pincode,
          contact: values.contact,
        }
      }
      const response = await postData('administration/sign-up/new', query);
      await showAlertMessage({ text: response.message });
      navigate('/auth/login');
    } catch (error) {
      const errorMessage = error.response.data ? error.response.data.message : error.message
      showAlertMessage({
        icon: 'error',
        title: 'Error occurred!',
        text: errorMessage,
      });
    }
    actions.setSubmitting(false);
  }

  const authActiveSlideRef = useRef(null);

  const changeTab = (tabName) => {
    const someTabTriggerEl = document.querySelector('a[href="' + tabName + '"]');
    authActiveSlideRef.current.innerHTML = someTabTriggerEl.getAttribute('data-slide-index');
    const actTab = new Tab(someTabTriggerEl);
    actTab.show();
  }
  return (
    <>
      <div className="auth-main">
        <div className="auth-wrapper v3">
          <AuthSideContent className="auth-sidecontent" />
          <div className="auth-form">
            <div className="auth-header row">
              <div className="col my-1">
                <Link href="#">
                  <Logo alt="image" height="80px" />
                </Link>
              </div>
              <div className="col-auto my-1">
                <h5 className="m-0 text-muted f-w-500">Step <b className="h5" ref={authActiveSlideRef} id="auth-active-slide">1</b> to 3</h5>
              </div>
            </div>
            <div className="card my-5">
              <div className="card-body">
                <Nav className="nav nav-tabs d-none" id="myTab" role="tablist">
                  <Nav.Item className="nav-item">
                    <Nav.Link
                      className="nav-link active"
                      id="auth-tab-1"
                      data-bs-toggle="tab"
                      href="#auth-1"
                      role="tab"
                      data-slide-index="1"
                      aria-controls="auth-1"
                      aria-selected="true"
                    >
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item">
                    <Nav.Link
                      className="nav-link"
                      id="auth-tab-2"
                      data-bs-toggle="tab"
                      href="#auth-2"
                      role="tab"
                      data-slide-index="2"
                      aria-controls="auth-2"
                      aria-selected="true"
                    >
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item">
                    <Nav.Link
                      className="nav-link"
                      id="auth-tab-3"
                      data-bs-toggle="tab"
                      href="#auth-3"
                      role="tab"
                      data-slide-index="3"
                      aria-controls="auth-3"
                      aria-selected="true"
                    >
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                >
                  {({ values, handleBlur, isSubmitting, setFieldValue }) => (
                    <Form>
                      <div className="tab-content">
                        <div className="tab-pane show active" id="auth-1" role="tabpanel" aria-labelledby="auth-tab-1">
                          <div className="text-center">
                            <h3 className="text-center mb-3">Welcome to the Edu NexGen</h3>
                            <p className="mb-2">Institute Management Application (Saas)</p>
                            <h3 className="mb-4">Institution General Information</h3>
                          </div>
                          <div className="row my-4">
                            <div className="form-group">
                              <label className="form-label">Institute Name*</label>
                              <Field className="form-control" name="institute_name" placeholder="Institute Name" />
                              <ErrorMessage className="text-danger mb-1" name="institute_name" component="div" />
                            </div>
                            <div className="form-group">
                              <label className="form-label">UDISE*</label>
                              <Field className="form-control" name="udise" placeholder="UDISE"
                                maxLength={11}
                                size={11}
                              />
                              <ErrorMessage className="text-danger mb-1" name="udise" component="div" />
                            </div>
                            <div className="form-group">
                              <label className="form-label">Organization*</label>
                              <Field className="form-control" name="organization_name" placeholder="Organization" />
                              <ErrorMessage className="text-danger mb-1" name="organization_name" component="div" />
                            </div>
                            <div className="form-group">
                              <label className="form-label">Permission Details*</label>
                              <Field className="form-control" name="permission_details" placeholder="Permission Details" />
                              <ErrorMessage className="text-danger mb-1" name="permission_details" component="div" />
                            </div>
                          </div>
                          <div className="row g-3">
                            <div className="col-sm-6">
                              <div className="d-grid">
                                <Link className="btn btn-light-success" to='/auth/login' >Login</Link>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="d-grid">
                                <Link className="btn btn-primary" onClick={() => changeTab('#auth-2')}>Continue</Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="auth-2" role="tabpanel" aria-labelledby="auth-tab-2">
                          <div className="text-center">
                            <h3 className="text-center mb-3">Welcome to the Edu NexGen</h3>
                            <p className="mb-2">Institute Management Application (Saas)</p>
                            <h3 className="text-center mb-3">Institution Contact Details</h3>
                          </div>
                          <div className="row my-4">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label className="form-label">Principal's Mobile*</label>
                                <Field className="form-control" name="mobile" placeholder="Mobile"
                                  maxLength={10}
                                  size={10}
                                />
                                <ErrorMessage className="text-danger mb-1" name="mobile" component="div" />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label className="form-label">Email*</label>
                                <Field className="form-control" name="email" placeholder="Email" />
                                <ErrorMessage className="text-danger mb-1" name="email" component="div" />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="form-label">Address*</label>
                              <Field className="form-control" name="address" placeholder="Address" />
                              <ErrorMessage className="text-danger mb-1" name="address" component="div" />
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label className="form-label">Country*</label>
                                <Select
                                  name="country"
                                  options={countries}
                                  value={countries.find(option => option.value === values.country)}
                                  onChange={(option) => setFieldValue('country', option ? option.value : '')}
                                  onBlur={handleBlur('country')}
                                />
                                <ErrorMessage className="text-danger mb-1" name="country" component="div" />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label className="form-label">State*</label>
                                <Select
                                  name="state"
                                  options={states}
                                  value={states.find(option => option.value === values.state)}
                                  onChange={(option) => setFieldValue('state', option ? option.value : '')}
                                  onBlur={handleBlur('state')}
                                />
                                <ErrorMessage className="text-danger mb-1" name="state" component="div" />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label className="form-label">City*</label>
                                <Select
                                  name="city"
                                  options={cities}
                                  value={cities.find(option => option.value === values.city)}
                                  onChange={(option) => setFieldValue('city', option ? option.value : '')}
                                  onBlur={handleBlur('city')}
                                />
                                <ErrorMessage className="text-danger mb-1" name="city" component="div" />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label className="form-label">Pin Code*</label>
                                <Field className="form-control form-control-sm" name="pincode" placeholder="Pin Code"
                                  maxLength={6}
                                  size={6}
                                />
                                <ErrorMessage className="text-danger mb-1" name="pincode" component="div" />
                              </div>
                            </div>
                          </div>
                          <div className="row g-3">
                            <div className="col-sm-6">
                              <div className="d-grid">
                                <Link className="btn btn-outline-secondary" onClick={() => changeTab('#auth-1')}>Back</Link>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="d-grid">
                                <Link className="btn btn-primary" onClick={() => changeTab('#auth-3')}>Continue</Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="auth-3" role="tabpanel" aria-labelledby="auth-tab-3">
                          <div className="text-center">
                            <h3 className="text-center mb-3">Welcome to the Edu NexGen</h3>
                            <p className="mb-2">Institute Management Application (Saas)</p>
                            <h3 className="text-center mb-3">Principal or Headmaster details</h3>
                          </div>
                          <div className="row mb-4">
                            <div className="form-group">
                              <label className="form-label">Name*</label>
                              <Field className="form-control" name="name" placeholder="Name" />
                              <ErrorMessage className="text-danger mb-1" name="name" component="div" />
                            </div>
                            <div className="form-group">
                              <label className="form-label">Designation*</label>
                              <Select
                                name="users_designation"
                                options={users_designation}
                                value={users_designation.find(option => option.value === values.users_designation)}
                                onChange={(option) => setFieldValue('users_designation', option ? option.value : '')}
                                onBlur={handleBlur('users_designation')}
                              />
                              <ErrorMessage className="text-danger mb-1" name="users_designation" component="div" />
                            </div>
                          </div>
                          <div className="row g-3">
                            <div className="col-sm-6">
                              <div className="d-grid">
                                <Link className="btn btn-outline-secondary" onClick={() => changeTab('#auth-2')}>Back</Link>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="d-grid">
                                <Button className="btn btn-success" type="submit" icon={isSubmitting && <Spinner animation="border" size="sm" />} disabled={isSubmitting} text={isSubmitting ? "Processing..." : "Register Now"} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="auth-footer">
              <p className="m-0 w-100 text-center">
                By signing up, you confirm to have read our
                <Link to=""> Privacy Policy </Link>
                and agree to the
                <Link to="#"> Terms of Service</Link>.
              </p>
            </div>
          </div>
        </div>
      </div >
    </>
  )
};

export default Registration
