import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postData, putData } from "../../api";

const fetchBonafide = createAsyncThunk('fetchBonafide', async (data, thunkAPI) => {
  try {
    const response = await postData('administration/bonafide/all', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const putBonafide = createAsyncThunk('putBonafide', async (data, thunkAPI) => {
  try {
    const response = await putData('administration/bonafide/update', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const createBonafide = createAsyncThunk('createBonafide', async (data, thunkAPI) => {
  try {
    const response = await postData('administration/bonafide/add', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const BonafideSlice = createSlice({
  name: 'bonafideSlice',
  initialState: {
    isLoading: false,
    isError: false,
    bonafideDetails: [],
  },
  extraReducers: (builder) => {
    builder
      //fetch
      .addCase(fetchBonafide.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchBonafide.fulfilled, (state, action) => {
        state.isLoading = false;
        state.bonafideDetails = action.payload.records;
        state.isError = false;
      })
      .addCase(fetchBonafide.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
      //put
      .addCase(putBonafide.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(putBonafide.fulfilled, (state, action) => {
        state.isLoading = false;
        const updatedBonafide = action.payload.response;
        const index = state.bonafideDetails.findIndex(item => item._id === updatedBonafide._id);
        if (index !== -1) {
          // Replace the existing object with the updated object
          state.bonafideDetails[index] = { ...state.bonafideDetails[index], ...updatedBonafide };
        } else {
          // Handle if the item is not found, possibly add it to the array (though not likely in an update case)
          state.bonafideDetails.push(updatedBonafide);
        }
        state.isError = false;
      })
      .addCase(putBonafide.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
      //create
      .addCase(createBonafide.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(createBonafide.fulfilled, (state, action) => {
        state.isLoading = false;
        state.bonafideDetails.push(action.payload.response);
        state.isError = false;
      })
      .addCase(createBonafide.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
  }
})

export { BonafideSlice, fetchBonafide, putBonafide, createBonafide }
export const { bonafideDetails } = BonafideSlice.actions;