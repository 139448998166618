import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postData } from "../../api";

const fetchInvoice = createAsyncThunk('fetchInvoice', async (data, thunkAPI) => {
  try {
    const response = await postData('administration/account/invoice', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const InvoiceSlice = createSlice({
  name: 'invoiceSlice',
  initialState: {
    isLoading: false,
    isError: false,
    invoiceDetails: [],
  },
  extraReducers: (builder) => {
    builder
      //fetch
      .addCase(fetchInvoice.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.invoiceDetails = action.payload.records;
        state.isError = false;
      })
      .addCase(fetchInvoice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
  }
})

export { InvoiceSlice, fetchInvoice }
export const { invoiceDetails } = InvoiceSlice.actions;