import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import React, { useState, useEffect } from "react";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import { putSession } from "../../store/slices/SessionSlice";
import { format } from "date-fns";
import { showAlertMessage } from "../../utils/sweetAlert";
import { useDispatch } from "react-redux";
import { fetchUser } from "../../store/slices/UserSlice";

const SessionTable = ({ sessionDetails, userDetails }) => {
  const dispatch = useDispatch();

  const [sessions, setSessions] = useState(null);
  const [statuses] = useState(["Active", "Inactive"]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setSessions(
      sessionDetails.map((session) => ({
        ...session,
        user: session?.user?.name,
        start: format(session.start, "yyyy-MM-dd"),
        end: format(session.end, "yyyy-MM-dd"),
        reopen: format(session.reopen, "yyyy-MM-dd"),
        status:
          session.status.charAt(0).toUpperCase() + session.status.slice(1),
      }))
    );
    setLoading(false);
  }, [sessionDetails, userDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const getSeverity = (value) => {
    switch (value) {
      case "Active":
        return "success";
      case "Inactive":
        return "danger";
      default:
        return null;
    }
  };

  const onRowEditComplete = async (e) => {
    try {
      const { newData } = e;
      const { _id: deletedId, __v: deleteV, ...rest } = newData; // Delete key
      const values = { ...rest, user: userDetails?._id }; // Update user details
      const response = await dispatch(
        putSession({
          _id: newData._id,
          query: values,
        })
      );
      await dispatch(fetchUser({ _id: userDetails._id }));
      if (response?.payload?.status === "success") {
        await showAlertMessage({ text: response?.payload?.message });
      } else {
        throw new Error(response?.payload?.response?.data.message);
      }
    } catch (error) {
      showAlertMessage({
        icon: "error",
        title: "Error occurred!",
        text: error.message,
      });
    }
  };

  const textEditor = (options) => {
    return (
      <input
        type="checkbox"
        className="form-control form-check-input input-light-primary"
        checked={options.value}
        onChange={(e) => options.editorCallback(e.target.checked)}
      />
    );
  };

  const activeSessionStatus = (rowData) => {
    return (
      <input
        type="checkbox"
        className="form-control form-check-input input-light-primary"
        checked={rowData.active_session}
      ></input>
    );
  };

  const statusEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e) => options.editorCallback(e.value)}
        placeholder="Status"
        itemTemplate={(option) => {
          return <Tag value={option} severity={getSeverity(option)}></Tag>;
        }}
      />
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag value={rowData.status} severity={getSeverity(rowData.status)}></Tag>
    );
  };

  const dateEditor = (options) => {
    return (
      <input
        type="date"
        className="form-control"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  const allowEdit = (rowData) => {
    return rowData.name !== "Blue Band";
  };

  const sessionStyle = {
    backgroundColor: "rgba(255, 255, 255, 0.3)", // Adjust opacity as needed
    backdropFilter: "blur(10px)", // Adjust blur amount
    width: "auto", // Adjust
    textAlign: "center", // Adjust
  };

  return (
    <>
      <div className="dt-responsive table-responsive">
        <DataTable
          size="normal"
          className="table-striped table-hover table-bordered nowrap"
          stripedRows
          showGridlines
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          value={sessions}
          sortField="session"
          sortOrder={-1}
          scrollable
          scrollHeight="100%"
          editMode="row"
          dataKey="id"
          onRowEditComplete={onRowEditComplete}
          tableStyle={{ minWidth: "50rem" }}
          loading={loading}
        >
          <Column
            field="_id"
            header="Id"
            style={{ width: "auto", display: "none" }}
          ></Column>
          <Column
            field="session"
            header="Session"
            alignFrozen="left"
            frozen
            sortable
            style={sessionStyle}
          ></Column>
          <Column
            field="active_session"
            header="Active"
            body={activeSessionStatus}
            editor={(options) => textEditor(options)}
            sortable
            style={{ width: "auto", textAlign: "center" }}
          ></Column>
          <Column
            field="start"
            header="Start On"
            editor={(options) => dateEditor(options)}
            sortable
            style={{ width: "auto" }}
          ></Column>
          <Column
            field="end"
            header="End On"
            editor={(options) => dateEditor(options)}
            sortable
            style={{ width: "auto" }}
          ></Column>
          <Column
            field="reopen"
            header="Reopen On"
            editor={(options) => dateEditor(options)}
            sortable
            style={{ width: "auto" }}
          ></Column>
          <Column
            field="status"
            header="Status"
            body={statusBodyTemplate}
            editor={(options) => statusEditor(options)}
            sortable
            style={{ width: "15%", textAlign: "center" }}
          ></Column>
          <Column
            field="user"
            header="User"
            sortable
            style={{ width: "auto" }}
          ></Column>
          <Column
            rowEditor={allowEdit}
            headerStyle={{ width: "auto", minWidth: "8rem" }}
            frozen
            alignFrozen="right"
            bodyStyle={sessionStyle}
          ></Column>
        </DataTable>
      </div>
    </>
  );
};

export default SessionTable;
