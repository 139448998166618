import { ErrorMessage, Field, Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from 'yup';
import { showAlertMessage } from "../../utils/sweetAlert";
import Button from "../common/Button";
import { Spinner } from "react-bootstrap";
import { changePassword } from "../../store/slices/UserSlice";
import { Link, useNavigate } from "react-router-dom";
import { TbEye, TbEyeClosed } from "react-icons/tb";
import { useState } from "react";
import { doLogout } from "../../store/slices/AuthSlice";
import useSignOut from "react-auth-kit/hooks/useSignOut";

const ChangePassword = ({ userDetails }) => {

  const signOut = useSignOut();

  const [password, setPassword] = useState(false);
  //navigate
  const navigate = useNavigate();
  // Redux dispatch
  const dispatch = useDispatch();
  // Form initial values
  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  // Form validation
  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required('Current Password is required'),
    newPassword: Yup.string()
      .required('New Password is required')
      .min(8, 'Password must be at least 8 characters long')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/\d/, 'Password must contain at least one digit')
      .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character'),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
  });
  // Form submit
  const onSubmit = async (values, actions) => {
    try {
      const response = await dispatch(changePassword({
        _id: userDetails?._id,
        currentPassword: values.currentPassword,
        newPassword: values.newPassword
      }));
      if (response?.payload?.status === 'success') {
        await showAlertMessage({ text: response?.payload?.message });
        actions.resetForm();
        dispatch(doLogout());
        signOut();
        navigate('/auth/login');
      } else {
        throw new Error(response?.payload?.response?.data.message);
      }
    } catch (error) {
      showAlertMessage({
        icon: 'error',
        title: 'Error occurred!',
        text: error.message,
      });
    }
    actions.setSubmitting(false);
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, resetForm }) => (
          <Form>
            <div className="card">
              <div className="card-header">
                <h5>Change Password</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label">Old Password</label>
                      <Field
                        type="password"
                        className="form-control"
                        minLength="8"
                        name="currentPassword"
                      />
                      <ErrorMessage className="text-danger mb-1" name="currentPassword" component="div" />
                    </div>
                    <div className="form-group">
                      <label className="form-label">New Password</label>

                      <div className="input-group search-form mb-3">

                        <Field
                          type={password ? "text" : "password"}
                          className="form-control"
                          minLength="8"
                          name="newPassword"
                        />
                        <Link className="input-group-text bg-transparent" onClick={() => setPassword(!password)}>{password ? <TbEyeClosed /> : <TbEye />}</Link>
                      </div>
                      <ErrorMessage className="text-danger mb-1" name="newPassword" component="div" />
                    </div>
                    <div className="form-group">
                      <label className="form-label">Confirm Password</label>
                      <Field
                        type="password"
                        className="form-control"
                        minLength="8"
                        name="confirmPassword"
                      />
                      <ErrorMessage className="text-danger mb-1" name="confirmPassword" component="div" />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <h5>New password must contain:</h5>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item"><i className="ti ti-circle-check text-success f-16 me-2"></i> At least 8
                        characters</li>
                      <li className="list-group-item"><i className="ti ti-circle-check text-success f-16 me-2"></i> At least 1
                        lower letter (a-z)</li>
                      <li className="list-group-item"><i className="ti ti-circle-check text-success f-16 me-2"></i> At least 1
                        uppercase letter(A-Z)</li>
                      <li className="list-group-item"><i className="ti ti-circle-check text-success f-16 me-2"></i> At least 1
                        number (0-9)</li>
                      <li className="list-group-item"><i className="ti ti-circle-check text-success f-16 me-2"></i> At least 1
                        special characters</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="card-footer text-end btn-page">
                <Button className="btn btn-outline-secondary" onClick={() => resetForm()} text="Cancel" />
                <Button className="btn btn-primary" type="submit" icon={isSubmitting && <Spinner animation="border" size="sm" />} disabled={isSubmitting} text={isSubmitting ? "Processing..." : "Change Password"} />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
};

export default ChangePassword
