import { useSelector } from "react-redux";
import Breadcrumb from "../../components/common/Breadcrumb";
import AdmissionForm from "../../components/admission/new/AdmissionForm";
import { FiAlertTriangle } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const AddAdmission = () => {
  const { t } = useTranslation();
  const { userDetails } = useSelector((state) => state.user);
  return (
    <>
      <section className="pc-container">
        <div className="pc-content">
          <Breadcrumb />
          <div className="row">
            <div className="col-lg-12">
            <div className="alert alert-danger d-flex align-items-center alert-dismissible fade show" role="alert">
                <FiAlertTriangle className="me-2" />
                <div><strong>{t("Warning")}!</strong> {t("Before starting to add students, please check if the session is added or set it active session. If it is, please ignore it.")} <Link className="btn btn-primary btn-sm" to="/institute/session">{t("Manage Session")}</Link></div>
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>
              <div className="card">
                <AdmissionForm userDetails={userDetails} type={'new'} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
};

export default AddAdmission
