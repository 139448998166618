import { Link } from "react-router-dom";
import { avatar_2 } from "../utils/images";
import { FiGlobe, FiSearch, FiSettings, FiSun } from "react-icons/fi";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UserMenu from "./common/UserMenu";
import Badge from 'react-bootstrap/Badge';
import { format } from "date-fns";
import i18n from "../utils/i18n";
import { useTranslation } from "react-i18next";

const Header = ({ onClick }) => {
  // redux get user details
  const userDetails = useSelector((state) => state.user.userDetails);
  // theme
  const [theme, setTheme] = useState(localStorage.getItem('theme'));
  const systemTheme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    //Theme Scheme
    localStorage.setItem('theme', theme);
    body.setAttribute('data-pc-theme', theme);
  }, [theme])
  const [toggleUserMenu, setToggleUserMenu] = useState(false);
  const [toggleTheme, setToggleTheme] = useState(false);
  const [toggleSearch, setToggleSearch] = useState(false);

  const { t } = useTranslation();
  const defaultLanguage = localStorage.getItem('i18nextLng') || 'en'; // Get language from local storage or default to English
  const [language, setLanguage] = useState(defaultLanguage);
  const [toggleLanguage, setToggleLanguage] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(language); // Change the language when selectedLanguage changes
    localStorage.setItem('i18nextLng', language); // Store selected language in local storage
  }, [language]);

  return (
    <>
      <header className="pc-header">
        <div className="header-wrapper">
          <div className="me-auto pc-mob-drp">
            <ul className="list-unstyled">
              <li className="pc-h-item pc-sidebar-collapse">
                <Link to="#" className="pc-head-link ms-0" onClick={onClick} >
                  <i className="ti ti-menu-2"></i>
                </Link>
              </li>
              <li className="pc-h-item pc-sidebar-popup">
                <Link to="#" className="pc-head-link ms-0" id="mobile-collapse" onClick={onClick}>
                  <i className="ti ti-menu-2"></i>
                </Link>
              </li>
              <li className="dropdown pc-h-item">
                <Link
                  className="pc-head-link dropdown-toggle arrow-none m-0 trig-drp-search"
                  onClick={() => setToggleSearch(!toggleSearch)}
                >
                  <FiSearch className="pc-icon" />
                </Link>
                <div className={`dropdown-menu pc-h-dropdown drp-search ${toggleSearch && 'show'}`}>
                  <form className="px-3 py-2">
                    <input type="search" className="form-control border-0 shadow-none" placeholder="Search here. . ." />
                  </form>
                </div>
              </li>
            </ul>
          </div>
          <div className="ms-auto">
            <ul className="list-unstyled">
              <li className="dropdown pc-h-item me-2">
                {t("Status")}:
                {userDetails?.institute?.status === 'active' ?
                  <Badge pill bg="badge bg-light-success rounded-pill f-12">{t("Active")}</Badge> :
                  <Badge pill bg="badge bg-light-danger rounded-pill f-12">{t("Expired")}</Badge>
                }
              </li>
              <li className="dropdown pc-h-item me-2">
                {t("Renewal")}: {userDetails?.institute?.nextRenewalDate && format(userDetails?.institute?.nextRenewalDate, "dd-MM-yyyy")}
              </li>
              <li className="dropdown pc-h-item me-2">
                {userDetails?.institute?.active_session?.session && `${t("Session")}: ${userDetails?.institute?.active_session?.session}`}
              </li>
              <li className="dropdown pc-h-item">
                <Link
                  className="pc-head-link dropdown-toggle arrow-none me-0"
                  onClick={() => setToggleTheme(!toggleTheme)}
                >
                  <FiSun className="pc-icon" />
                </Link>
                <div className={`dropdown-menu dropdown-menu-end pc-h-dropdown ${toggleTheme && 'show'}`} >
                  <Link className="dropdown-item" onClick={() => setTheme('dark')}>
                    <i className="ti ti-moon"></i>
                    <span>{t("Dark")}</span>
                  </Link>
                  <Link className="dropdown-item" onClick={() => setTheme('light')}>
                    <i className="ti ti-brightness-up"></i>
                    <span>{t("Light")}</span>
                  </Link>
                  <Link to="" className="dropdown-item" onClick={() => setTheme(systemTheme)}>
                    <i className="ti ti-settings"></i>
                    <span>{t("Default")}</span>
                  </Link>
                </div>
              </li>
              <li className="dropdown pc-h-item">
                <Link
                  className="pc-head-link dropdown-toggle arrow-none me-0"
                  onClick={() => setToggleLanguage(!toggleLanguage)}
                >
                  <FiGlobe className="pc-icon" />
                </Link>
                <div className={`dropdown-menu dropdown-menu-end pc-h-dropdown ${toggleLanguage && 'show'}`} >
                  <Link className="dropdown-item" onClick={() => setLanguage('en')}>
                    <span>{t("en")}</span>
                  </Link>
                  <Link className="dropdown-item" onClick={() => setLanguage('mr')}>
                    <span>{t("mr")}</span>
                  </Link>
                </div>
              </li>
              <li className="dropdown pc-h-item">
                <Link
                  className="pc-head-link dropdown-toggle arrow-none me-0"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvas_pc_layout"
                  to="#"
                  role="button"
                  aria-haspopup="false"
                  aria-expanded="false"
                >
                  <FiSettings className="pc-icon" />
                </Link>
              </li>
              <li className="dropdown pc-h-item header-user-profile">
                <Link
                  className="pc-head-link dropdown-toggle arrow-none me-0"
                  onClick={() => setToggleUserMenu(!toggleUserMenu)}
                >
                  <img src={userDetails?.image ? userDetails?.image : avatar_2} alt="" className="user-avtar" />
                </Link>
                <UserMenu className={`dropdown-menu dropdown-menu-end pc-h-dropdown ${toggleUserMenu ? 'show' : ''}`} />
              </li>
            </ul>
          </div>
        </div>
      </header>
    </>
  )
};

export default Header
