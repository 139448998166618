import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postData, putData } from "../../api";

const fetchCaste = createAsyncThunk('fetchCaste', async (data, thunkAPI) => {
  try {
    const response = await postData('administration/caste/caste/all', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const putCaste = createAsyncThunk('putCaste', async (data, thunkAPI) => {
  try {
    const response = await putData('administration/caste/caste/update', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const createCaste = createAsyncThunk('createCaste', async (data, thunkAPI) => {
  try {
    const response = await postData('administration/caste/caste/add', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const CasteSlice = createSlice({
  name: 'casteSlice',
  initialState: {
    isLoading: false,
    isError: false,
    casteDetails: [],
  },
  extraReducers: (builder) => {
    builder
      //fetch
      .addCase(fetchCaste.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchCaste.fulfilled, (state, action) => {
        state.isLoading = false;
        state.casteDetails = action.payload.records;
        state.isError = false;
      })
      .addCase(fetchCaste.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
      //put
      .addCase(putCaste.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(putCaste.fulfilled, (state, action) => {
        state.isLoading = false;
        const updatedCaste = action.payload.response;
        const index = state.casteDetails.findIndex(item => item._id === updatedCaste._id);
        if (index !== -1) {
          // Create a new array with the updated item
          state.casteDetails[index] = updatedCaste;
        } else {
          // Handle if the item is not found, possibly add it to the array
          state.casteDetails.push(updatedCaste);
        }
        state.isError = false;
      })
      .addCase(putCaste.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
      //create
      .addCase(createCaste.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(createCaste.fulfilled, (state, action) => {
        state.isLoading = false;
        state.casteDetails.push(action.payload.response);
        state.isError = false;
      })
      .addCase(createCaste.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
  }
})

export { CasteSlice, fetchCaste, putCaste, createCaste }
export const { casteDetails } = CasteSlice.actions;