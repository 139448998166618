import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TiChevronRight } from "react-icons/ti";
import { Dashboard } from "react-flaticons";
import Logo from "./common/Logo";
import { FaFileInvoiceDollar, FaPowerOff, FaPrint, FaRegCircleUser, FaSliders, FaUserGraduate } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { doLogout } from "../store/slices/AuthSlice";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { showAlertMessage } from "../utils/sweetAlert";
import { avatar_2 } from "../utils/images";
import UserMenu from "./common/UserMenu";
import { textFormatter } from "../utils/common"
import { fetchUser } from "../store/slices/UserSlice";
import { useTranslation } from "react-i18next";

const NavBar = ({ isHidden, pathname }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const signOut = useSignOut();
  const navigate = useNavigate();

  // redux get user details
  const { userDetails } = useSelector((state) => state?.user);
  const _Auth_id = localStorage.getItem("_Auth_id");

  useEffect(() => {
    dispatch(fetchUser({ _id: _Auth_id }))
  }, [dispatch, _Auth_id])

  useEffect(() => {
    if (!_Auth_id) {
      dispatch(doLogout());
      signOut();
      navigate('/auth/login');
      localStorage.removeItem("_Auth_id");
    }
  }, [dispatch, _Auth_id, signOut, navigate])



  const handleLogout = async () => {
    const result = await showAlertMessage({
      title: "Are you sure?",
      text: "You want to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Logout!"
    });
    if (result.isConfirmed) {
      dispatch(doLogout());
      signOut();
      navigate('/auth/login');
      localStorage.removeItem("_Auth_id");
      window.location.reload();
    }
  }
  const [menuItems, setMenuItems] = useState([
    {
      heading: 'Navigation', menu: [
        {
          title: 'Dashboard', link: '/', icon: <Dashboard className="pc-icon" />, isActive: false, isSubMenu: false
        },
      ]
    }, {
      heading: 'Admission', menu: [
        {
          title: 'Admissions', icon: <FaUserGraduate className="pc-icon" />, isActive: false, isSubMenu: true, subMenu: [
            { title: 'Add', link: '/admission/add', icon: '', isActive: false, isSubMenu: false },
            { title: 'Manage', link: '/admission/manage', icon: "", isActive: false, isSubMenu: false },
            { title: 'Promote', link: '/admission/promote', icon: "", isActive: false, isSubMenu: false },
          ]
        }
      ],
    }, {
      heading: 'Report', menu: [
        {
          title: 'Generate', icon: <FaPrint className="pc-icon" />, isActive: false, isSubMenu: true, subMenu: [
            { title: 'Transfer Certificate', link: '/generate/transfer-certificate', icon: '', isActive: false, isSubMenu: false, },
            { title: 'Boanafide', link: '/generate/bonafide', icon: '', isActive: false, isSubMenu: false, },
            { title: 'Nirgam Utara', link: '/generate/nirgam-utara', icon: '', isActive: false, isSubMenu: false, }
          ]
        }
      ]
    }//, 
    // {
    //   heading: 'User', menu: [
    //     {
    //       title: 'Users', icon: <FaUserGear className="pc-icon" />, isActive: false, isSubMenu: true, subMenu: [
    //         { title: 'Add', icon: '', isActive: false, isSubMenu: false },
    //         { title: 'Manage', icon: "", isActive: false, isSubMenu: false },
    //       ]
    //     },
    //   ]
    // }
    , {
      heading: 'Manage', menu: [
        {
          title: 'Manage Institute', icon: <FaSliders className="pc-icon" />, isActive: false, isSubMenu: true, subMenu: [
            { title: 'General Information', link: '/institute/general-information', icon: '', isActive: false, isSubMenu: false },
            { title: 'Session', link: '/institute/session', icon: '', isActive: false, isSubMenu: false },
            { title: 'Class', link: '/institute/class', icon: "", isActive: false, isSubMenu: false },
            { title: 'Division', link: '/institute/division', icon: "", isActive: false, isSubMenu: false },
            // { title: 'Class Teacher', icon: "", isActive: false, isSubMenu: false },
            { title: 'Caste & Category', link: '/institute/caste', icon: "", isActive: false, isSubMenu: false },
          ]
        },
      ]
    }, {
      heading: 'Account', menu: [
        { title: 'Profile', link: '/account/profile', icon: <FaRegCircleUser className="pc-icon" />, isActive: false, isSubMenu: false, },
        { title: 'Invoice', link: '/account/invoice', icon: <FaFileInvoiceDollar className="pc-icon" />, isActive: false, isSubMenu: false, },
        { title: 'Logout', link: '', icon: <FaPowerOff className="pc-icon text-primary" />, isActive: false, isSubMenu: false, },
      ]
    }
  ]);

  const handleMenuClick = (menuItem, subMenuItem = "") => {
    const updatedMenuItems = menuItems.map(item => {
      item.menu.forEach(menu => {
        menu.isActive = false; // Deactivate all menu items
        if (menu.title === menuItem.title) {
          menu.isActive = true;
          if (menu.title === 'Logout') {
            handleLogout()
          }
          if (menuItem.isSubMenu) {
            if (subMenuItem) {
              menu.subMenu.forEach(sub => {
                sub.isActive = sub.title === subMenuItem.title;
              });
            }
          } else {
            if (menu.subMenu) {
              menu.subMenu.forEach(sub => {
                sub.isActive = false;
              });
            }
          }
        }
      });
      return item;
    });
    setMenuItems(updatedMenuItems);
  };

  useEffect(() => {

    const updatedMenuItems = menuItems.map(item => {
      item.menu.forEach(menu => {
        menu.isActive = false; // Deactivate all menu items
        if (menu.link === '/' && pathname === '/') {
          menu.isActive = true; // Activate Dashboard if the path is '/'
        } else if (pathname.includes('/admission/edit/student/')) {
          if (menu.title === 'Admissions') {
            menu.isActive = true
          }
        } else if (menu.link === pathname) {
          menu.isActive = true;
        } else if (menu.subMenu) {
          menu.subMenu.forEach(submenu => {
            submenu.isActive = false; // Deactivate all sub-menu items
            if (submenu.link === pathname) {
              menu.isActive = true; // Activate parent menu if a sub-menu item is active
              submenu.isActive = true;
            }
          });
        }
      });
      return item;
    });
    setMenuItems(updatedMenuItems);
  }, [pathname]);

  const [toggleUserMenu, setToggleUserMenu] = useState(true);

  return (
    <>
      <nav className={`pc-sidebar ${isHidden ? 'pc-sidebar-hide mob-sidebar-active' : ''}`} >
        <div className="navbar-wrapper">
          <div className="m-header">
            <Link to="/" className="b-brand text-primary">
              <Logo alt="image" height="80px" />
            </Link>
          </div>

          <div className="navbar-content">
            <div className="card pc-user-card">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <img src={userDetails?.image ? userDetails?.image : avatar_2} alt="" className="user-avtar wid-45 rounded-circle" />
                  </div>
                  <div className="flex-grow-1 ms-3 me-2">
                    <h6 className="mb-0">{userDetails?.name}</h6>
                    <small>{userDetails?.users_designation && textFormatter(userDetails?.users_designation)}</small>
                    <br />
                    <small>{userDetails?.institute?.active_session?.session && userDetails?.institute?.active_session?.session}</small>
                  </div>
                  <button className="btn btn-icon btn-link-secondary avtar-s" onClick={() => setToggleUserMenu(!toggleUserMenu)} >
                    <TiChevronRight />
                  </button>
                </div>
                <UserMenu className={`${toggleUserMenu ? 'collapse' : ''} pc-user-links`} />
              </div>
            </div>
            <div className="navbar-content" style={{ overflowY: "auto", height: "100vh" }}>
              <ul className="pc-navbar">
                {menuItems.map(item => (
                  <React.Fragment key={item.heading}>
                    <li className="pc-item pc-caption">
                      <label>{t(item.heading)}</label>
                      <i className="ti ti-dashboard"></i>
                    </li>
                    {item.menu.map(menu => (
                      <li key={menu.title} className={`pc-item ${menu.isActive ? 'pc-hasmenu pc-trigger active' : ''}`}>
                        <Link
                          to={menu.link}
                          className="pc-link"
                          onClick={() => handleMenuClick(menu)}
                        >
                          <span className="pc-micon">
                            {menu.icon}
                          </span>
                          <span className="pc-mtext">{t(menu.title)}</span>
                          {menu.isSubMenu && <TiChevronRight className="pc-arrow" />}
                          {menu.count && <span className="pc-badge">{menu.count}</span>}
                        </Link>
                        <ul className="pc-submenu" style={menu.isActive && menu.isSubMenu ? { display: 'block' } : { display: 'none' }}>
                          {menu.isSubMenu &&
                            menu.subMenu.map(sub => (
                              <li key={sub.title} className={`pc-item ${sub.isActive ? 'pc-trigger' : ''}`}>
                                <Link
                                  className="pc-link"
                                  to={sub.link}
                                  onClick={() => handleMenuClick(menu, sub)}
                                >
                                  {t(sub.title)}
                                </Link>
                              </li>
                            ))
                          }
                        </ul>
                      </li>
                    ))}
                  </React.Fragment>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
