import Breadcrumb from "../../components/common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Button from "../../components/common/Button"
import CategoryForm from "../../components/caste/category/CategoryForm"
import SubCasteForm from "../../components/caste/sub-caste/SubCasteForm";
import CategoryTable from "../../components/caste/category/CategoryTable";
import { fetchCategory } from "../../store/slices/CategorySlice";
import { fetchSubCaste } from "../../store/slices/SubCasteSlice";
import SubCasteTable from "../../components/caste/sub-caste/SubCasteTable";
import { fetchCaste } from "../../store/slices/CasteSlice";
import CasteTable from "../../components/caste/caste/CasteTable";
import CasteForm from "../../components/caste/caste/CasteForm";

const Caste = () => {
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(fetchCaste())
  }, [dispatch]);
  const { casteDetails } = useSelector((state) => state.caste);

  useEffect(() => {
    dispatch(fetchCategory())
  }, [dispatch]);
  const { categoryDetails } = useSelector((state) => state.category);

  useEffect(() => {
    dispatch(fetchSubCaste())
  }, [dispatch]);

  const { subCasteDetails } = useSelector((state) => state.subCaste);

  const [showCaste, setShowCaste] = useState(false);
  const handleCloseCaste = () => setShowCaste(false);
  const handleShowCaste = () => setShowCaste(true);

  const [showCategory, setShowCategory] = useState(false);
  const handleCloseCategory = () => setShowCategory(false);
  const handleShowCategory = () => setShowCategory(true);

  const [showSubCaste, setShowSubCaste] = useState(false);
  const handleCloseSubCaste = () => setShowSubCaste(false);
  const handleShowSubCaste = () => setShowSubCaste(true);

  return (
    <>
      <section className="pc-container">
        <CasteForm show={showCaste} handleClose={handleCloseCaste} userDetails={userDetails} />
        <CategoryForm show={showCategory} handleClose={handleCloseCategory} userDetails={userDetails} />
        <SubCasteForm show={showSubCaste} handleClose={handleCloseSubCaste} categoryDetails={categoryDetails} userDetails={userDetails} />
        <div className="pc-content">
          <Breadcrumb />
          <div className="row">
            <div className="col-lg-4">
              <div className="card">
                <div id="sticky-action" className="sticky-action">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col-sm-6">
                        <h5>Manage Caste</h5>
                      </div>
                      <div className="col-sm-6 text-sm-end mt-3 mt-sm-0">
                        <Button onClick={handleShowCaste} className="btn btn-success me-2" text="Add New" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <CasteTable casteDetails={casteDetails} userDetails={userDetails} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card">
                <div id="sticky-action" className="sticky-action">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col-sm-6">
                        <h5>Manage Category</h5>
                      </div>
                      <div className="col-sm-6 text-sm-end mt-3 mt-sm-0">
                        <Button onClick={handleShowCategory} className="btn btn-success me-2" text="Add New" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <CategoryTable categoryDetails={categoryDetails} userDetails={userDetails} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card">
                <div id="sticky-action" className="sticky-action">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col-sm-6">
                        <h5>Manage Sub Caste</h5>
                      </div>
                      <div className="col-sm-6 text-sm-end mt-3 mt-sm-0">
                        <Button onClick={handleShowSubCaste} className="btn btn-success me-2" text="Add New" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <SubCasteTable subCasteDetails={subCasteDetails} userDetails={userDetails} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
};

export default Caste
