import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import React, { useState, useEffect } from "react";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { format } from "date-fns";
import getAge from "get-age";
import { avatar_2 } from "../../../utils/images";
import { Button } from "primereact/button";
import { TiDirections, TiEdit } from "react-icons/ti";
import { FaFileExcel } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { FilterMatchMode } from "primereact/api";
import { showAlertMessage } from "../../../utils/sweetAlert";
import MoveStudentForm from "./MoveStudentForm";

const ManageAdmissionTable = ({ admissionDetails, userDetails }) => {
  const navigate = useNavigate();

  const [admissions, setAdmissions] = useState(null);
  const [loading, setLoading] = useState(true);

  //Load data
  useEffect(() => {
    setAdmissions(
      admissionDetails.map((admission) => ({
        ...admission,
        _id: admission?._id,
        name: admission?.name,
        father: admission?.parent?.f_name,
        mother: admission?.parent?.m_name,
        mobile: admission?.parent?.f_mobile,
        roll_number: admission?.roll_number,
        admission_no: admission?.admission_no,
        saral_id: admission?.saral_id,
        aadhaar_no: admission?.aadhaar_no,
        religion: admission?.religion,
        caste: admission?.caste,
        category: admission?.category,
        sub_caste: admission?.sub_caste,
        class: admission?.class,
        division: admission?.division,
        dob: admission?.dob && format(admission?.dob, "dd-MM-yyyy"),
        age:
          admission?.dob &&
          `${getAge(format(admission?.dob, "yyyy-MM-dd"))} Yrs`,
        admitted_class: admission?.admitted_class,
        admission_date:
          admission?.admission_date &&
          format(admission?.admission_date, "dd-MM-yyyy"),
        last_school: admission?.last_school?.last_school,
      }))
    );
    setLoading(false);
  }, [admissionDetails, userDetails]);

  // Search
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const exportExcel = () => {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(admissions);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });

      saveAsExcelFile(excelBuffer, 'admissions');
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE
        });

        module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      }
    });
  };

  const renderHeader = () => {
    return (
      <>
        <div className="row text-sm-end col-12">
          <div className="col-sm-6">
            <div className="form-group">
              <input
                className="form-control"
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
                placeholder="Search"
              />
            </div>
          </div>
          <div className="col-sm-6">
            <Button className="btn btn-light-primary" icon={<FaFileExcel />} onClick={exportExcel} data-pr-tooltip="XLS"> Export</Button>
          </div>
        </div>
      </>
    );
  };

  const imageBodyTemplate = (rowData) => {
    return (
      <img
        src={rowData.image ? rowData.image : avatar_2}
        alt=""
        className="user-avtar wid-45 rounded-circle"
      />
    );
  };

  const actions = (rowData) => {
    return (
      <>
        <div className="d-flex gap-2 mb-4">
          <Button
            className="btn btn-icon btn-light-primary avtar-s"
            icon={<TiEdit />}
            tooltip="Edit Student"
            onClick={() => updateStudent(rowData)}
          />
          <Button
            className="btn btn-icon btn-light-primary avtar-s"
            icon={<TiDirections />}
            tooltip="Move Student"
            onClick={() => moveStudent(rowData)}
          />
        </div>
      </>
    );
  };

  const updateStudent = async (data) => {
    const result = await showAlertMessage({
      title: "Are you sure?",
      text: "You want to update student: " + data.name + "?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Update it.!"
    });
    if (result.isConfirmed) {
      navigate("/admission/edit/student/" + data._id);
    }
  };

  const style = {
    backgroundColor: "rgba(255, 255, 255, 0.3)", // Adjust opacity as needed
    backdropFilter: "blur(10px)", // Adjust blur amount
    width: "auto", // Adjust
    textAlign: "center", // Adjust
  };

  const header = renderHeader();

  // Move students to the new division
  const [ student, setStudent] = useState(null);
  const moveStudent = async (data) => {
    setStudent(data)
    const result = await showAlertMessage({
      title: "Are you sure?",
      text: "You want to move student: " + data.name + "?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Move it.!"
    });
    if (result.isConfirmed) {
      handleShow(true);
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <MoveStudentForm show={show} handleClose={handleClose} userDetails={userDetails} student={student} />
      <div className="dt-responsive table-responsive">
        <DataTable
          size="normal"
          className="table-striped table-hover table-bordered nowrap"
          sortField="roll_number"
          sortOrder={1}
          stripedRows
          showGridlines
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          value={admissions}
          scrollable
          scrollHeight="100%"
          editMode="row"
          dataKey="id"
          tableStyle={{ minWidth: "50rem" }}
          loading={loading}
          filters={filters}
          filterDisplay="row"
          globalFilterFields={[
            "name",
            "father",
            "mother",
            "mobile",
            "admission_no",
            "saral_id",
            "aadhaar_no",
            "religion",
            "caste",
            "category",
            "sub_caste",
            "age",
            "last_school",
          ]}
          header={header}
          emptyMessage="No records found."
        >
          <Column
            field="_id"
            header="Id"
            style={{ width: "auto", display: "none" }}
          ></Column>
          <Column
            header="Image"
            body={imageBodyTemplate}
            alignFrozen="left"
            frozen
            sortable
            style={style}
          ></Column>
          <Column
            field="name"
            header="Name"
            alignFrozen="left"
            frozen
            sortable
            style={style}
          ></Column>
          <Column field="father" header="Father" sortable></Column>
          <Column field="mother" header="Mother" sortable></Column>
          <Column field="mobile" header="Mobile" sortable></Column>
          <Column field="roll_number" header="Roll No" sortable></Column>
          <Column field="admission_no" header="Admission No" sortable></Column>
          <Column field="saral_id" header="Saral Id" sortable></Column>
          <Column field="aadhaar_no" header="Aadhaar" sortable></Column>
          <Column field="religion" header="Religion" sortable></Column>
          <Column field="caste" header="Caste" sortable></Column>
          <Column field="category" header="Category" sortable></Column>
          <Column field="sub_caste" header="Sub Caste" sortable></Column>
          <Column field="class" header="Class" sortable></Column>
          <Column field="division" header="Division" sortable></Column>
          <Column field="dob" header="DOB" sortable></Column>
          <Column field="age" header="Age" sortable></Column>
          <Column field="admitted_class" header="Admitted In" sortable></Column>
          <Column field="admission_date" header="Admitted On" sortable></Column>
          <Column field="last_school" header="Last School" sortable></Column>
          <Column
            header="Actions"
            style={style}
            alignFrozen="right"
            frozen
            body={actions}
          ></Column>
        </DataTable>
      </div>
    </>
  );
};

export default ManageAdmissionTable;
