import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import React, { useState, useEffect, useRef } from "react";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { format } from "date-fns";
import { showAlertMessage } from "../../utils/sweetAlert";
import { useDispatch } from "react-redux";
import { avatar_2 } from "../../utils/images";
import { TiEyeOutline, TiLockOpen, TiPrinter } from "react-icons/ti";
import { Button } from "primereact/button";
import { useReactToPrint } from "react-to-print";
import { Tag } from "primereact/tag";
import { Dropdown } from "primereact/dropdown";
import { Link } from "react-router-dom";
import TCTemplate from "../../templates/TCTemplate";
import { patchUnlockTC, putTC } from "../../store/slices/TCSlice";
import { postData } from "../../api";
import Modal from 'react-bootstrap/Modal';
import { InputOtp } from 'primereact/inputotp';
import { FaPenToSquare } from "react-icons/fa6";
import { FilterMatchMode } from "primereact/api";

const TCTable = ({ tcDetails, userDetails }) => {
  const dispatch = useDispatch();
  const [TCs, setTCs] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTCs(
      tcDetails.map((item) => ({
        ...item,
        _id: item?._id,
        name: item?.student?.name,
        admission_no: item?.student?.admission_no,
        session: item?.student?.session,
        date: item?.date && format(item?.date, "yyyy-MM-dd"),
        tc_number: item?.tc_number,
        progress: item?.progress,
        conduct: item?.conduct,
        reason: item?.reason,
        remark: item?.remark,
        issue_date: item?.issue_date && format(item?.issue_date, "yyyy-MM-dd"),
        print_count: item?.print_count ? item?.print_count : 0,
        status: item?.isOriginal ? "Original" : "Duplicate",
        user: item?.user?.name,
      }))
    );
    setLoading(false);
  }, [tcDetails, userDetails]);

  const onRowEditComplete = async (e) => {
    try {
      const { newData } = e;
      const { __v: deleteV, ...rest } = newData; // Delete key
      const values = {
        _id: rest._id,
        query: {
          date: rest.date,
          tc_number: rest.tc_number,
          progress: rest.progress,
          conduct: rest.conduct,
          reason: rest.reason,
          remark: rest.remark,
          issue_date: rest.issue_date,
          user: userDetails?._id,
        },
      }; // Update user details
      const response = await dispatch(putTC(values));
      if (response?.payload?.status === "success") {
        await showAlertMessage({ text: response?.payload?.message });
      } else {
        throw new Error(response?.payload?.response?.data.message);
      }
    } catch (error) {
      showAlertMessage({
        icon: "error",
        title: "Error occurred!",
        text: error.message,
      });
    }
  };

  const textEditor = (options) => {
    return (
      <input
        type="text"
        className="form-control"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  const dateEditor = (options) => {
    return (
      <input
        type="date"
        className="form-control"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  const allowEdit = (rowData) => {
    return rowData.name !== "Blue Band";
  };

  const actions = (rowData) => {
    return (
      <>
        <div className="d-flex gap-2 mb-4">
          <Button
            className="btn btn-icon btn-light-primary"
            icon={<TiEyeOutline />}
            tooltip="Sample Print"
            onClick={() => samplePrint(rowData)}
          />
          {rowData.isLock ?
            <>
              <Button
                className="btn btn-icon btn-light-dark"
                icon={<TiLockOpen />}
                tooltip="Unlock T.C"
                onClick={() => unlockOTP(rowData)}
              />
            </>
            :
            <>
              <Button
                className="btn btn-icon btn-light-danger"
                icon={<TiPrinter />}
                tooltip="Final Print"
                onClick={() => finalPrint(rowData)}
              />
              {/* <Button
                className="btn btn-icon btn-light-warning"
                icon={<TiArrowBack />}
                tooltip="Delete and restore admission"
                onClick={() => samplePrint(rowData)}
              /> */}
            </>
          }
        </div>
      </>
    );
  };

  //Unlock TC
  const [showModal, setShowModal] = useState(false);
  const [unlockTCData, setUnlockTCData] = useState({});
  const [otpToken, setOTPToken] = useState();
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const unlockOTP = async (rowData) => {
    try {
      setLoading(true);
      const response = await postData('administration/transfer-certificate/unlock-otp', { _id: rowData._id });
      if (response.status === 'success') {
        localStorage.setItem('_TC_Token', response.response.security_code);
        setUnlockTCData(rowData)
        handleShowModal();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      showAlertMessage({
        icon: "error",
        title: "Error occurred!",
        text: error.message,
      });
    }
  }

  const unlockTC = async () => {
    setLoading(true);
    try {
      const TC_Token = localStorage.getItem('_TC_Token')

      if (otpToken === atob(TC_Token)) {
        const response = await dispatch(patchUnlockTC({ _id: unlockTCData._id }));
        if (response?.payload?.status === "success") {
          handleCloseModal();
          await showAlertMessage({ text: response?.payload?.message });
        } else {
          throw new Error(response?.payload?.response?.data.message);
        }
      } else {
        showAlertMessage({
          icon: 'error',
          title: 'Error occurred!',
          text: 'Invalid OTP. Please double check.',
        });
      }
    } catch (error) {
      showAlertMessage({
        icon: "error",
        title: "Error occurred!",
        text: error.message,
      });
    }
    setLoading(false);
  }

  //Status
  const getSeverity = (value) => {
    switch (value) {
      case "Original":
        return "success";
      case "Duplicate":
        return "danger";
      case "QUALIFIED":
        return "success";
      case "NOT QUALIFIED":
        return "danger";
      case "GOOD":
        return "success";
      case "NOT GOOD":
        return "danger";
      default:
        return null;
    }
  };
  const statusBodyTemplate = (rowData) => {
    return (
      <Tag value={rowData.status} severity={getSeverity(rowData.status)}></Tag>
    );
  };

  //Print
  const tcRef = useRef();
  const [tcData, setTCData] = useState({});
  const [sample, setSample] = useState(true);
  // Sample Print
  const samplePrint = (rowData) => {
    setTCData(rowData);
    setSample(true)
    setLoading(true);
    setTimeout(() => {
      print();
      setLoading(false);
    }, 1000);
  };

  //Final Print
  const finalPrint = async (rowData) => {
    const result = await showAlertMessage({
      title: "Are you sure?",
      text: "Single time print is allowed! Printer is working?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, All Right!"
    });
    if (result.isConfirmed) {
      setTCData(rowData);
      setSample(false)
      setLoading(true);
      setTimeout(() => {
        print();
        setLoading(false);
        !rowData.isLock && afterTCPrint(rowData)
      }, 1000);
    }
  }

  const print = useReactToPrint({
    content: () => tcRef.current,
    documentTitle:
      "Transfer Certificate " + format(new Date(), "dd-MM-yyyy HH:mm:ss bb"),
    removeAfterPrint: true,
  });

  //Finally TC is Print
  const afterTCPrint = async (rowData) => {
    try {
      const values = {
        _id: rowData._id,
        query: {
          print_count: +1,
          isLock: true,
          user: userDetails?._id,
        },
      };
      await dispatch(putTC(values));
    } catch (error) {
      showAlertMessage({
        icon: "error",
        title: "Error occurred!",
        text: error.message,
      });
    }
  }

  const imageBodyTemplate = (rowData) => {
    return (
      <img
        src={rowData.student.image ? rowData.student.image : avatar_2}
        alt=""
        className="user-avtar wid-45 rounded-circle"
      />
    );
  };

  const nameBodyTemplate = (rowData) => {
    return (
      <div>
        {rowData?.student?.name}
        <br />
        {rowData?.student?.parent?.f_name}
      </div>
    );
  };

  const admNoBodyTemplate = (rowData) => {
    return (
      rowData?.isLock ? rowData?.student?.admission_no : <Link className="badge bg-light-danger" to={'/admission/edit/student/' + rowData?.student?._id}><FaPenToSquare className="m-1" /> {rowData?.student?.admission_no}</Link>
    )
  }

  const style = {
    backgroundColor: "rgba(255, 255, 255, 0.3)", // Adjust opacity as needed
    backdropFilter: "blur(10px)", // Adjust blur amount
    width: "auto", // Adjust
    textAlign: "center", // Adjust
  };

  //Progress
  const [progress] = useState(['QUALIFIED', 'NOT QUALIFIED']);
  const progressEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={progress}
        onChange={(e) => options.editorCallback(e.value)}
        placeholder="Progress"
        itemTemplate={(option) => {
          return <Tag value={option} severity={getSeverity(option)}></Tag>;
        }}
      />
    );
  };

  const progressBodyTemplate = (rowData) => {
    return <Tag value={rowData.progress} severity={getSeverity(rowData.progress)}></Tag>;
  };

  //Conduct
  const [conduct] = useState(['GOOD', 'NOT GOOD']);
  const conductEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={conduct}
        onChange={(e) => options.editorCallback(e.value)}
        placeholder="Conduct"
        itemTemplate={(option) => {
          return <Tag value={option}></Tag>;
        }}
      />
    );
  };

  const conductBodyTemplate = (rowData) => {
    return <Tag value={rowData.conduct} severity={getSeverity(rowData.conduct)} ></Tag>;
  };

  const countBodyTemplate = (rowData) => {
    return <Tag className="badge bg-light-danger" value={rowData.print_count}></Tag>;
  }

  const dateBodyTemplate = (rowData) => {
    return format(rowData.date, 'dd-MM-yyyy');
  }

  const issueDateBodyTemplate = (rowData) => {
    return format(rowData.issue_date, 'dd-MM-yyyy');
  }

  // Search
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <>
        <div className="row text-sm-end col-12">
          <div className="col-sm-6">
            <div className="form-group">
              <input
                className="form-control"
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
                placeholder="Search"
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const header = renderHeader();

  return (
    <>
      <div style={{ display: "none" }}>
        <TCTemplate ref={tcRef} data={tcData} sample={sample} template={parseInt(userDetails?.institute?.tcTemplate)} />
      </div>
      <div className="dt-responsive table-responsive">
        <DataTable
          size="normal"
          className="table-striped table-hover table-bordered nowrap"
          stripedRows
          showGridlines
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          value={TCs}
          scrollable
          scrollHeight="100%"
          editMode="row"
          dataKey="id"
          onRowEditComplete={onRowEditComplete}
          tableStyle={{ minWidth: "50rem" }}
          loading={loading}
          filters={filters}
          filterDisplay="row"
          globalFilterFields={[
            "name",
            "father",
            "mother",
            "mobile",
            "admission_no",
            "tc_number",
          ]}
          header={header}
          emptyMessage="No records found."
          sortField="date"
          sortOrder={-1}
        >
          <Column
            field="_id"
            header="Id"
            style={{ width: "auto", display: "none" }}
          ></Column>
          <Column
            header="Image"
            body={imageBodyTemplate}
            alignFrozen="left"
            frozen
            sortable
            style={style}
          ></Column>
          <Column
            field="name"
            header="Name"
            body={nameBodyTemplate}
            alignFrozen="left"
            frozen
            sortable
            style={style}
          ></Column>
          <Column
            field="admission_no"
            header="ADM No"
            body={admNoBodyTemplate}
            sortable
          ></Column>
          <Column
            field="session"
            header="Session"
            sortable
            style={{ width: "auto" }}
          ></Column>
          <Column
            field="date"
            header="L.Dt"
            body={dateBodyTemplate}
            editor={(options) => dateEditor(options)}
            sortable
            style={{ width: "auto" }}
          ></Column>
          <Column
            field="tc_number"
            header="T.C.No."
            sortable
            style={style}
          ></Column>
          <Column
            field="progress"
            header="Progress"
            body={progressBodyTemplate}
            editor={(options) => progressEditor(options)}
            sortable
            style={{ width: "auto" }}
          ></Column>
          <Column
            field="conduct"
            header="Conduct"
            body={conductBodyTemplate}
            editor={(options) => conductEditor(options)}
            sortable
            style={style}
          ></Column>
          <Column
            field="reason"
            header="Reason"
            editor={(options) => textEditor(options)}
            sortable
            style={{ width: "auto" }}
          ></Column>
          <Column
            field="remark"
            header="Remark"
            editor={(options) => textEditor(options)}
            sortable
            style={style}
          ></Column>
          <Column
            field="print_count"
            header="Count"
            body={countBodyTemplate}
            sortable
            style={style}
          ></Column>
          <Column
            field="status"
            header="Status"
            body={statusBodyTemplate}
            sortable
            style={{ width: "auto" }}
          ></Column>
          <Column
            field="issue_date"
            header="Print On"
            body={issueDateBodyTemplate}
            editor={(options) => dateEditor(options)}
            sortable
            style={{ width: "auto" }}
          ></Column>
          <Column
            field="user"
            header="User"
            sortable
            style={{ width: "auto" }}
          ></Column>
          <Column
            rowEditor={(options) => !options.isLock && allowEdit}
            header="Update"
            headerStyle={{ width: "auto", minWidth: "8rem" }}
            frozen
            alignFrozen="right"
            bodyStyle={style}
          ></Column>
          <Column
            header="Actions"
            style={style}
            alignFrozen="right"
            frozen
            body={actions}
          ></Column>
        </DataTable>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Please enter OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <InputOtp value={otpToken} onChange={(e) => setOTPToken(e.value)} integerOnly length={6} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-danger me-2" onClick={handleCloseModal}>
            Close
          </button>
          <button type="button" className="btn btn-success me-2" onClick={unlockTC}>
            Unlock TC
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TCTable;
