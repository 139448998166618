import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import React, { useState, useEffect } from "react";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import { showAlertMessage } from "../../utils/sweetAlert";
import { useDispatch } from "react-redux";
import { putClass } from "../../store/slices/ClassSlice";

const ClassTable = ({ classDetails, userDetails }) => {
  const dispatch = useDispatch();

  const [standards, setStandards] = useState(null);
  const [statuses] = useState(["Active", "Inactive"]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setStandards(
      classDetails.map((item) => ({
        ...item,
        standard: item.class,
        status: item.status.charAt(0).toUpperCase() + item.status.slice(1),
        user: item?.user?.name,
      }))
    );
    setLoading(false);
  }, [classDetails, userDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const getSeverity = (value) => {
    switch (value) {
      case "Active":
        return "success";
      case "Inactive":
        return "danger";
      default:
        return null;
    }
  };

  const onRowEditComplete = async (e) => {
    try {
      const { newData } = e;
      const { _id: deletedId, __v: deleteV, ...rest } = newData; // Delete key
      const values = { ...rest, user: userDetails?._id }; // Update user details
      const response = await dispatch(
        putClass({
          _id: newData._id,
          query: values,
        })
      );
      if (response?.payload?.status === "success") {
        await showAlertMessage({ text: response?.payload?.message });
      } else {
        throw new Error(response?.payload?.response?.data.message);
      }
    } catch (error) {
      showAlertMessage({
        icon: "error",
        title: "Error occurred!",
        text: error.message,
      });
    }
  };

  const textEditor = (options) => {
    return (
      <input
        type="text"
        className="form-control"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  const statusEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e) => options.editorCallback(e.value)}
        placeholder="Status"
        itemTemplate={(option) => {
          return <Tag value={option} severity={getSeverity(option)}></Tag>;
        }}
      />
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag value={rowData.status} severity={getSeverity(rowData.status)}></Tag>
    );
  };

  const allowEdit = (rowData) => {
    return rowData.name !== "Blue Band";
  };

  const classStyle = {
    backgroundColor: "rgba(255, 255, 255, 0.3)", // Adjust opacity as needed
    backdropFilter: "blur(10px)", // Adjust blur amount
    width: "auto", // Adjust
    textAlign: "center", // Adjust
  };

  return (
    <>
      <div className="dt-responsive table-responsive">
        <DataTable
          size="normal"
          className="table-striped table-hover table-bordered nowrap"
          stripedRows
          showGridlines
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          value={standards}
          sortField="class"
          sortOrder={1}
          scrollable
          scrollHeight="100%"
          editMode="row"
          dataKey="id"
          onRowEditComplete={onRowEditComplete}
          tableStyle={{ minWidth: "50rem" }}
          loading={loading}
        >
          <Column field="_id" header="Id" style={{ width: "auto" }}></Column>
          <Column
            field="class"
            header="Class"
            alignFrozen="left"
            editor={(options) => textEditor(options)}
            frozen
            sortable
            style={classStyle}
          ></Column>
          <Column
            field="status"
            header="Status"
            body={statusBodyTemplate}
            editor={(options) => statusEditor(options)}
            sortable
            style={{ width: "15%", textAlign: "center" }}
          ></Column>
          <Column
            field="user"
            header="User"
            sortable
            style={{ width: "auto" }}
          ></Column>
          <Column
            rowEditor={allowEdit}
            headerStyle={{ width: "auto", minWidth: "8rem" }}
            frozen
            alignFrozen="right"
            bodyStyle={classStyle}
          ></Column>
        </DataTable>
      </div>
    </>
  );
};

export default ClassTable;
