import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postData, putData, uploadData } from "../../api";

const fetchAdmission = createAsyncThunk('fetchAdmission', async (data, thunkAPI) => {
  try {
    const response = await postData('administration/admission/all', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const putAdmission = createAsyncThunk('putAdmission', async (data, thunkAPI) => {
  try {
    const response = await putData('administration/admission/update', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const promoteAdmission = createAsyncThunk('promoteAdmission', async (data, thunkAPI) => {
  try {
    const response = await putData('administration/admission/promote', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const createAdmission = createAsyncThunk('createAdmission', async (data, thunkAPI) => {
  try {
    const response = await postData('administration/admission/add', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const studentImage = createAsyncThunk('studentImage', async (data, thunkAPI) => {
  try {
    const response = await uploadData('administration/admission/upload', data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const AdmissionSlice = createSlice({
  name: 'admissionSlice',
  initialState: {
    isLoading: false,
    isError: false,
    admissionDetails: [],
  },
  extraReducers: (builder) => {
    builder
      //fetch
      .addCase(fetchAdmission.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchAdmission.fulfilled, (state, action) => {
        state.isLoading = false;
        state.admissionDetails = action.payload.records;
        state.isError = false;
      })
      .addCase(fetchAdmission.rejected, (state, action) => {
        state.isLoading = false;
        state.admissionDetails = [];
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
      //put
      .addCase(putAdmission.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(putAdmission.fulfilled, (state, action) => {
        state.isLoading = false;
        const updatedAdmission = action.payload.response;
        const index = state.admissionDetails.findIndex(item => item._id === updatedAdmission._id);
        if (index !== -1) {
          // Create a new array with the updated item
          state.admissionDetails[index] = updatedAdmission;
        } else {
          // Handle if the item is not found, possibly add it to the array
          state.admissionDetails.push(updatedAdmission);
        }
        state.isError = false;
      })
      .addCase(putAdmission.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
      //create
      .addCase(createAdmission.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(createAdmission.fulfilled, (state, action) => {
        state.isLoading = false;
        state.admissionDetails.push(action.payload.response);
        state.isError = false;
      })
      .addCase(createAdmission.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
      //Image
      .addCase(studentImage.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isData = false;
      })
      .addCase(studentImage.fulfilled, (state, action) => {
        state.isLoading = false;
        const updatedAdmission = action.payload.response;
        const index = state.admissionDetails.findIndex(item => item._id === updatedAdmission._id);
        if (index !== -1) {
          // Create a new array with the updated item
          state.admissionDetails[index] = updatedAdmission;
        } else {
          // Handle if the item is not found, possibly add it to the array
          state.admissionDetails.push(updatedAdmission);
        }
        state.isError = false;
      })
      .addCase(studentImage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
      //Promote
      .addCase(promoteAdmission.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(promoteAdmission.fulfilled, (state, action) => {
        state.isLoading = false;
        const updatedAdmission = action.payload.response;
        const index = state.admissionDetails.findIndex(item => item._id === updatedAdmission._id);
        if (index !== -1) {
          // Create a new array with the updated item
          state.admissionDetails[index] = updatedAdmission;
        } else {
          // Handle if the item is not found, possibly add it to the array
          state.admissionDetails.push(updatedAdmission);
        }
        state.isError = false;
      })
      .addCase(promoteAdmission.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching user:", action.payload);
      })
  }
})

export { AdmissionSlice, fetchAdmission, putAdmission, createAdmission, studentImage, promoteAdmission }
export const { admissionDetails } = AdmissionSlice.actions;