import { createSlice } from "@reduxjs/toolkit";

const AuthSlice = createSlice({
  name: 'auth',
  initialState: {
    userDetails: {},
    token: null,
    isAuthenticated: false,
    otp: null,
  },
  reducers: {
    doLogin(state, action) {
      state.userDetails = action.payload.details;
      state.token = action.payload.token;
      state.isAuthenticated = true;
      state.otp = action.payload.otp;
    },
    doLogout(state, action) {
      state.token = null;
      state.isAuthenticated = false;
      state.otp = null
      state.userDetails = {}
    }
  },
})

export { AuthSlice }
export const { doLogin, doLogout } = AuthSlice.actions;