import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import Select from 'react-select';
import { showAlertMessage } from "../../../utils/sweetAlert";
import { nextClass } from "../../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchDivision } from "../../../store/slices/DivisionSlice";
import Button from "../../common/Button";
import { Spinner } from "react-bootstrap";
import { promoteAdmission } from "../../../store/slices/AdmissionSlice";

const PromoteForm = ({ currentSession, values, standardOptions }) => {
  const dispatch = useDispatch();
  //get promoting Students
  const { promoteStudentDetails } = useSelector((state) => state.promote);
  // const [students, setStudents] = useState([]);

  // useEffect(() => {
  //   if (promoteStudentDetails && promoteStudentDetails.length > 0) {
  //     // Map only if promoteStudentDetails is defined and not empty
  //     const mappedStudents = promoteStudentDetails.map((item) => ({ _id: item._id }));
  //     setStudents(mappedStudents);
  //   } else {
  //     // Handle case where promoteStudentDetails is not defined or empty
  //     setStudents([]);
  //   }
  // }, [promoteStudentDetails]);

  // Divisions
  const { divisionDetails } = useSelector((state) => state.division);
  const [divisions, setDivisions] = useState([])
  const handleCurrentClassChange = (option, setFieldValue) => {
    setFieldValue('class', option ? option.value : '');
    setDivisions([])
    dispatch(fetchDivision({ institute: values?.institute, class: option.value }))
  }

  useEffect(() => {
    const getDivisions = async () => {
      setDivisions(() => divisionDetails.map(item => ({ value: item.division, label: item.division })));
    }
    getDivisions()
  }, [divisionDetails])

  const initialValues = {
    institute: values?.institute,
    udise: values?.udise,
    session: '',
    class: values?.class ? nextClass(values?.class) : '',
    division: values?.division,
    last_class: values?.class,
    students: promoteStudentDetails,
    user: values?.user
  }
  // Form validation
  const studentSchema = Yup.object().shape({
    _id: Yup.string().required('Please select a student'),
  });
  const validationSchema = Yup.object().shape({
    session: Yup.string()
      .required('Session is required'),
    class: Yup.string()
      .required('Class is required'),
    division: Yup.string()
      .required('Division is required'),
    students: Yup.array().of(studentSchema)
  });
  // Form submit
  const onSubmit = async (values, actions) => {
    try {
      const response = await dispatch(promoteAdmission({
        institute: values.institute,
        udise: values.udise,
        user: values.user,
        students: values.students.map(item => ({
          _id: item._id,
          class: values.class,
          division: values.division,
          session: values.session
        }))
      }));
      if (response?.payload?.status === 'success') {
        await showAlertMessage({ text: response?.payload?.message });
      } else {
        throw new Error(response?.payload?.response?.data.message);
      }
    } catch (error) {
      showAlertMessage({
        icon: 'error',
        title: 'Error occurred!',
        text: error.message,
      });
    } finally {
      actions.setSubmitting(false);
    }
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({ isSubmitting, setFieldValue, values, handleBlur, resetForm }) => (
          <Form>
            <div className="row">
              <div className="col-sm-3">
                <div className="form-group">
                  <Select
                    name="session"
                    options={currentSession}
                    value={currentSession.find(option => option.value === values.session)}
                    onChange={(option) => setFieldValue('session', option ? option.value : '')}
                    onBlur={handleBlur('session')}
                    placeholder="Current Session"
                    autoFocus
                  />
                  <ErrorMessage className="text-danger mb-1" name="session" component="div" />
                </div>
              </div>
              <div className="col-sm-3">
                <div className="form-group">
                  <Select
                    name="class"
                    options={standardOptions}
                    value={standardOptions.find(option => option.value === values.class)}
                    onChange={(option) => handleCurrentClassChange(option, setFieldValue)}
                    onBlur={handleBlur('class')}
                    placeholder="Class"
                  />
                  <ErrorMessage className="text-danger mb-1" name="class" component="div" />
                </div>
              </div>
              <div className="col-sm-3">
                <div className="form-group">
                  <Select
                    name="division"
                    cacheOptions
                    defaultOptions
                    options={divisions}
                    value={divisions.find(option => option.value === values.division)}
                    onChange={(option) => setFieldValue('division', option ? option.value : '')}
                    onBlur={handleBlur('division')}
                    placeholder="Division"
                  />
                  <ErrorMessage className="text-danger mb-1" name="division" component="div" />
                </div>
              </div>
              <div className="col-sm-3">
                <Button className="btn btn-light-danger w-100" type="submit" icon={isSubmitting && <Spinner animation="border" size="sm" />} disabled={isSubmitting} text={isSubmitting ? "Processing..." : "Promote"} />
                <ErrorMessage className="text-danger mb-1" name="students" component="div" />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
};

export default PromoteForm
