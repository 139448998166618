import * as Yup from 'yup';

// Custom method to validate the session format and year range
Yup.addMethod(Yup.string, 'sessionFormat', function (message) {
  return this.test('sessionFormat', message, function (value) {
    const { path, createError } = this;

    if (!value) return true; // Skip validation if value is not present (handled by .required)

    const regex = /^\d{4}-\d{4}$/;
    if (!regex.test(value)) {
      return createError({ path, message: 'Session must be in the format yyyy-yyyy' });
    }

    const [startYear, endYear] = value.split('-').map(Number);

    if (endYear !== startYear + 1) {
      return createError({ path, message: 'End year must be exactly one year after start year' });
    }

    return true;
  });
});

const romanNumeral = (message) => {
  return Yup.string().test('romanNumeral', message, function (value) {
    const { path, createError } = this;

    if (!value) return true; // Skip validation if value is not present (handled by .required)

    const regex = /^(?=[MDCLXVI])M{0,4}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})$/;
    if (!regex.test(value)) {
      return createError({ path, message });
    }

    return true;
  });
};

const textFormatter = (string) => string.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

const bloodGroups = [
  { value: 'O +ve', label: 'O +ve' },
  { value: 'O -ve', label: 'O -ve' },
  { value: 'A +ve', label: 'A +ve' },
  { value: 'A -ve', label: 'A -ve' },
  { value: 'B +ve', label: 'B +ve' },
  { value: 'B -ve', label: 'B -ve' },
  { value: 'AB +ve', label: 'AB +ve' },
  { value: 'AB -ve', label: 'AB -ve' },
]

//Designations
const users_designation = [
  { value: 'headmaster', label: 'Headmaster' },
  { value: 'principal', label: 'Principal' }
]

const statusOptions = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' }
]

const genders = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: 'other', label: 'Other' }
]

const religions = [
  { value: 'Islam', label: 'Islam' },
  { value: 'Hindu', label: 'Hindu' },
  { value: 'Christian', label: 'Christian' },
  { value: 'Sikh', label: 'Sikh' },
  { value: 'Buddh', label: 'Buddh' },
  { value: 'Jain', label: 'Jain' },
  { value: 'Judaism', label: 'Judaism' },
  { value: 'Zoroastrianism', label: 'Zoroastrianism' },
  { value: 'Others', label: 'Others' },
  { "value": "इस्लाम", "label": "इस्लाम" },
  { "value": "हिंदू", "label": "हिंदू" },
  { "value": "ख्रिश्चन", "label": "ख्रिश्चन" },
  { "value": "सिख", "label": "सिख" },
  { "value": "बौद्ध", "label": "बौद्ध" },
  { "value": "जैन", "label": "जैन" },
  { "value": "यहूदी", "label": "यहूदी" },
  { "value": "जरोआस्ट्रियनिज्म", "label": "जरोआस्ट्रियनिज्म" },
  { "value": "इतर", "label": "इतर" }
]

const castes = [
  { value: 'Muslim', label: 'Muslim' }
]

const classOptions = [
  { value: 'Jr.K.G', label: 'Jr.K.G' },
  { value: 'Sr.K.G', label: 'Sr.K.G' },
  { value: 'I', label: 'I' },
  { value: 'II', label: 'II' },
  { value: 'III', label: 'III' },
  { value: 'IV', label: 'IV' },
  { value: 'V', label: 'V' },
  { value: 'VI', label: 'VI' },
  { value: 'VII', label: 'VII' },
  { value: 'VIII', label: 'VIII' },
  { value: 'IX', label: 'IX' },
  { value: 'X', label: 'X' },
  { value: 'XI', label: 'XI' },
  { value: 'XII', label: 'XII' },
  { value: 'I Year', label: 'I Year' },
  { value: 'II Year', label: 'II Year' },
  { value: 'III Year', label: 'III Year' },
  { value: 'IV Year', label: 'IV Year' },
  { value: 'V Year', label: 'V Year' },
  { value: 'D.El.Ed I Year', label: 'D.El.Ed I Year' },
  { value: 'D.El.Ed II Year', label: 'D.El.Ed II Year' },
]

const languages = [
  { value: 'Arabic', label: 'Arabic' },
  { value: 'Assamese', label: 'Assamese' },
  { value: 'Bengali', label: 'Bengali' },
  { value: 'Gujarati', label: 'Gujarati' },
  { value: 'Hindi', label: 'Hindi' },
  { value: 'Kannada', label: 'Kannada' },
  { value: 'Malayalam', label: 'Malayalam' },
  { value: 'Marathi', label: 'Marathi' },
  { value: 'Odia', label: 'Odia' },
  { value: 'Punjabi', label: 'Punjabi' },
  { value: 'Tamil', label: 'Tamil' },
  { value: 'Telugu', label: 'Telugu' },
  { value: 'Urdu', label: 'Urdu' },
  { "value": "अरबी", "label": "अरबी" },
  { "value": "असमीया", "label": "असमीया" },
  { "value": "बंगाली", "label": "बंगाली" },
  { "value": "गुजराती", "label": "गुजराती" },
  { "value": "हिंदी", "label": "हिंदी" },
  { "value": "कन्नड", "label": "कन्नड" },
  { "value": "मलयालम", "label": "मलयालम" },
  { "value": "मराठी", "label": "मराठी" },
  { "value": "ओडिया", "label": "ओडिया" },
  { "value": "पंजाबी", "label": "पंजाबी" },
  { "value": "तमिळ", "label": "तमिळ" },
  { "value": "तेलगु", "label": "तेलगु" },
  { "value": "उर्दू", "label": "उर्दू" }
]
function dateToWords(dob) {
  // Helper function to convert a number to words
  function numberToWords(num) {
    const belowTwenty = ["", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine", "ten", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen"];
    const tens = ["", "", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];

    if (num < 20) return belowTwenty[num];
    if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 === 0 ? "" : "-" + belowTwenty[num % 10]);
    if (num < 1000) return belowTwenty[Math.floor(num / 100)] + " hundred" + (num % 100 === 0 ? "" : " " + numberToWords(num % 100));
    if (num < 1000000) return numberToWords(Math.floor(num / 1000)) + " thousand" + (num % 1000 === 0 ? "" : " " + numberToWords(num % 1000));
    return "";
  }

  // Helper function to convert month number to month name
  function getMonthName(month) {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return monthNames[month];
  }

  // Parse the date
  const date = new Date(dob);
  const day = numberToWords(date.getDate());
  const month = getMonthName(date.getMonth());
  const year = numberToWords(date.getFullYear());

  // Construct the worded date string
  return `${day} ${month} ${year}`;
}

function nextClass(roman) {
  const romanNumerals = ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", "XI", "XII"];
  return romanNumerals[romanNumerals.indexOf(roman) + 1] || null;
}

function romanSuffix(romanNumeral = "") {
  const numeral = romanNumeral.toUpperCase(); // Ensure uppercase for consistency

  // Determine suffix based on the last character of the Roman numeral
  switch (numeral) {
    case "I":
      return "st";
    case "II":
      return "nd";
    case "III":
      return "rd";
    case "IV":
    case "V":
    case "VI":
    case "VII":
    case "VIII":
    case "IX":
    case "X":
    case "XI":
    case "XII":
      return "th";
    default:
      return ""; // Handle cases beyond "I" to "XII" as needed
  }
}

const stringToDate = (dateString) => {
  const [day, month, year] = dateString.split('-');
  return `${year}-${month}-${day}`;
}

const progress = [
  { value: 'QUALIFIED', label: 'QUALIFIED' },
  { value: 'NOT QUALIFIED', label: 'NOT QUALIFIED' },
]

const conduct = [
  { value: 'GOOD', label: 'GOOD' },
  { value: 'NOT GOOD', label: 'NOT GOOD' },
]

const reasons = [
  { value: 'AS PER PARENT APPLICATION', label: 'AS PER PARENT APPLICATION' },
  { value: 'DUE TO ABSENCE OF UPPER CLASS', label: 'DUE TO ABSENCE OF UPPER CLASS' },
  { value: 'DUE TO DEATH', label: 'DUE TO DEATH' },
  { value: 'D.El.Ed II Year Exam Passed', label: 'D.El.Ed II Year Exam Passed' },
]

const Templates = [
  {value: "1", label: 'Template 1'},
  {value: "2", label: 'Template 2'},
  {value: "3", label: 'Template 3 - (Diploma)'},
]

export { textFormatter, bloodGroups, users_designation, statusOptions, Yup, romanNumeral, genders, religions, castes, classOptions, languages, nextClass, dateToWords, romanSuffix, stringToDate, progress, conduct, reasons, Templates }
