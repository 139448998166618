import { Spinner } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from '../common/Button';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { showAlertMessage } from '../../utils/sweetAlert';
import { Yup } from '../../utils/common';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useState } from 'react';
import { postData } from '../../api';
import { avatar_2 } from '../../utils/images';
import { createBonafide } from '../../store/slices/BonafideSlice';
const BonafideForm = ({ show, handleClose, userDetails }) => {
  const dispatch = useDispatch();
  //Current Date
  const today = new Date();
  // Form initial values
  const initialValues = {
    udise: userDetails?.institute?.udise,
    institute: userDetails?.institute?._id,
    student: '',
    date: format(today, 'yyyy-MM-dd'),
    remark: '',
    user: userDetails?._id
  }
  // Form validation
  const validationSchema = Yup.object().shape({
    student: Yup.string()
      .required('Student is required'),
    date: Yup.date().required('Date is required'),
  });
  // Form submit
  const onSubmit = async (values, actions) => {
    try {
      const response = await dispatch(createBonafide(values));
      if (response?.payload?.status === 'success') {
        await showAlertMessage({ text: response?.payload?.message });
        handleClose(true)
      } else {
        throw new Error(response?.payload?.response?.data.message);
      }
    } catch (error) {
      showAlertMessage({
        icon: 'error',
        title: 'Error occurred!',
        text: error.message,
      });
    } finally {
      actions.setSubmitting(false);
    }
  };

  // Student Search --------------
  const [isLoading, setIsLoading] = useState(false);
  const [students, setStudents] = useState([]);
  const handleSearch = async (query) => {
    setIsLoading(true);
    const response = await postData('administration/admission/search', {
      name: query,
      institute: userDetails?.institute?._id,
      udise: userDetails?.institute?.udise,
      user: userDetails?._id,
    });
    setStudents(response.records);
    setIsLoading(false);
  };
  const filterBy = () => true;
  // ---------------------------

  return (
    <>
      <Offcanvas show={show} onHide={handleClose} key="end" placement="end" name="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Bonafide</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize={true}
          >
            {({ isSubmitting, setFieldValue, values, handleBlur, resetForm }) => (
              <Form>
                <div className="form-group">
                  <label className="form-label">Student*</label>
                  <AsyncTypeahead
                    filterBy={filterBy}
                    id="async-example"
                    isLoading={isLoading}
                    labelKey="name"
                    name="student"
                    minLength={3}
                    onSearch={handleSearch}
                    options={students}
                    onChange={(selected) => {
                      setFieldValue('student', selected.length > 0 ? selected[0]._id : '');
                    }}
                    onBlur={handleBlur}
                    placeholder="Search for students..."
                    renderMenuItemChildren={(option, props, index) => (
                      <div className="d-flex align-items-center" key={option._id}>
                        <div className="flex-shrink-0">
                          <div className="avtar avtar-s border">
                            <img src={option.image ? option.image : avatar_2} alt="" className="avtar avtar-s border" />
                          </div>
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <div className="row g-1">
                            <div className="col-6">
                              <h6 className="mb-0">{option.name}</h6>
                              <p className="text-muted mb-0"><small>{option.parent.f_name}</small></p>
                              <p className="text-muted mb-0"><small>{option.session}</small></p>
                            </div>
                            <div className="col-6 text-end">
                              <p className="mb-1">{option.isLeaved ? <span className="badge bg-light-danger">Leaved</span>:<span className="badge bg-light-primary">Active</span>}</p>
                              <p className="text-primary mb-0"><small>{option.admission_no}</small></p>
                              <p className="text-danger mb-0"><small>{option.roll_number}</small></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  />
                  <ErrorMessage className="text-danger mb-1" name="student" component="div" />
                </div>
                <div className="form-group">
                  <label className="form-label">Remark</label>
                  <Field
                    className="form-control"
                    name="remark"
                    autoFocus
                  />
                  <ErrorMessage className="text-danger mb-1" name="remark" component="div" />
                </div>
                <div className="form-group">
                  <label className="form-label">Date*</label>
                  <Field
                    type="date"
                    className="form-control"
                    name="date"
                  />
                  <ErrorMessage className="text-danger mb-1" name="date" component="div" />
                </div>
                <div className="card-footer text-end btn-page">
                  <Button className="btn btn-outline-danger" onClick={() => resetForm()} text="Cancel" />
                  <Button className="btn btn-primary" type="submit" icon={isSubmitting && <Spinner animation="border" size="sm" />} disabled={isSubmitting} text={isSubmitting ? "Processing..." : "Add New"} />
                </div>
              </Form>
            )}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
};

export default BonafideForm
