
const Button = (props) => {
  return (
    <button
      className={props.className}
      type={props.type}
      style={props.style}
      onClick={props.onClick}
      data-bs-dismiss={props.dataBsDismiss}
      aria-label={props.ariaLabel}
      data-value={props.data_value} // corrected to camelCase
      disabled={props.disabled}
    >
      {props.icon} {props.text}
    </button>

  )
};

export default Button
