import { useSelector } from "react-redux";
import Breadcrumb from "../../components/common/Breadcrumb";
import { restricted_area } from "../../utils/images";
import Error from "../../components/common/Error";

const Expired = () => {
  const userDetails = useSelector((state) => state.user.userDetails);
  return (
    <>
      <div className="pc-container">
        <div className="pc-content">
        <Breadcrumb />
          <Error image={restricted_area} title="Your account has expired or is inactive." pageType="internal" description="To active your account kindly make payment." btnText="Activate Now" data={userDetails} planDetails="true" />
        </div>
      </div>
    </>
  )
};

export default Expired
