import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import React, { useState, useEffect } from "react";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { format } from "date-fns";

const InvoiceTable = ({ invoiceDetails, userDetails }) => {
  const [invoices, setInvoices] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setInvoices(
      invoiceDetails.map((invoice) => ({
        ...invoice,
        user: invoice?.user?.name,
        orderId: invoice.orderId,
        date: invoice?.date && format(invoice.date, "dd-MM-yyyy"),
        renewalDate:
          invoice?.renewalDate && format(invoice.renewalDate, "dd-MM-yyyy"),
        amount: invoice?.amount,
        response: invoice?.response,
        state: invoice?.state,
        transactionId: invoice?.transactionId,
      }))
    );
    setLoading(false);
  }, [invoiceDetails, userDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const invoiceStyle = {
    backgroundColor: "rgba(255, 255, 255, 0.3)", // Adjust opacity as needed
    backdropFilter: "blur(10px)", // Adjust blur amount
    width: "auto", // Adjust
    textAlign: "center", // Adjust
  };

  return (
    <>
      <div className="dt-responsive table-responsive">
        <DataTable
          size="normal"
          className="table-striped table-hover table-bordered nowrap"
          stripedRows
          showGridlines
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          value={invoices}
          sortField="date"
          sortOrder={-1}
          scrollable
          scrollHeight="100%"
          editMode="row"
          dataKey="id"
          tableStyle={{ minWidth: "50rem" }}
          loading={loading}
        >
          <Column
            field="_id"
            header="Id"
            style={{ width: "auto", display: "none" }}
          ></Column>
          <Column
            field="orderId"
            header="Invoice"
            alignFrozen="left"
            frozen
            sortable
            style={invoiceStyle}
          ></Column>
          <Column
            field="date"
            header="Date"
            sortable
            style={{ width: "auto", textAlign: "center" }}
          ></Column>
          <Column
            field="renewalDate"
            header="Valid Until"
            sortable
            style={{ width: "auto" }}
          ></Column>
          <Column
            field="amount"
            header="Amount"
            sortable
            style={{ width: "auto" }}
          ></Column>
          <Column
            field="response"
            header="Response"
            sortable
            style={{ width: "auto" }}
          ></Column>
          <Column
            field="state"
            header="Status"
            sortable
            style={{ width: "15%", textAlign: "center" }}
          ></Column>
          <Column
            field="transactionId"
            header="Trans ID"
            sortable
            style={{ width: "15%", textAlign: "center" }}
          ></Column>
          <Column
            field="user"
            header="User"
            sortable
            style={{ width: "auto" }}
          ></Column>
        </DataTable>
      </div>
    </>
  );
};

export default InvoiceTable;
