import { Spinner } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from '../common/Button';
import { ErrorMessage, Form, Formik } from 'formik';
import { showAlertMessage } from '../../utils/sweetAlert';
import * as Yup from 'yup';
import Select from 'react-select';
import { statusOptions } from '../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { createDivision } from '../../store/slices/DivisionSlice';
import { useEffect, useState } from 'react';
import { fetchClass } from '../../store/slices/ClassSlice';
const DivisionForm = ({ show, handleClose, userDetails }) => {
  const dispatch = useDispatch();

  const [standardOptions, setStandardOptions] = useState([])
  useEffect(() => {
    dispatch(fetchClass({
      institute: userDetails?.institute?._id
    }))
  }, [dispatch, userDetails])

  const { classDetails } = useSelector((state) => state.class);

  useEffect(() => {
    const getStandards = async () => {
      setStandardOptions(() => classDetails.map(item => ({ value: item.class, label: item.class })));
    }
    getStandards()
  }, [classDetails])

  // Form initial values
  const initialValues = {
    udise: userDetails?.institute?.udise,
    institute: userDetails?.institute?._id,
    standard: '',
    division: '',
    status: 'active',
    user: userDetails?._id
  }
  // Form validation
  const validationSchema = Yup.object().shape({
    standard: Yup.string()
      .required('Class is required'),
    division: Yup.string()
      .required('Division is required'),
    status: Yup.string()
      .required('Status is required'),
  });
  // Form submit
  const onSubmit = async (values, actions) => {
    try {
      const response = await dispatch(createDivision(values));
      if (response?.payload?.status === 'success') {
        await showAlertMessage({ text: response?.payload?.message });
        handleClose(true)
      } else {
        throw new Error(response?.payload?.response?.data.message);
      }
    } catch (error) {
      showAlertMessage({
        icon: 'error',
        title: 'Error occurred!',
        text: error.message,
      });
    } finally {
      actions.setSubmitting(false);
    }
  };

  const divisions= [
    {value: 'A', label: 'A'},
    {value: 'B', label: 'B'},
    {value: 'C', label: 'C'},
    {value: 'D', label: 'D'},
    {value: 'E', label: 'E'},
  ]


  return (
    <>
      <Offcanvas show={show} onHide={handleClose} key="end" placement="end" name="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Division</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize={true}
          >
            {({ isSubmitting, setFieldValue, values, handleBlur, resetForm }) => (
              <Form>
                <div className="form-group">
                  <label className="form-label">Class*</label>
                  <Select
                    name="standard"
                    options={standardOptions}
                    value={standardOptions.find(option => option.value === values.standard)}
                    onChange={(option) => setFieldValue('standard', option ? option.value : '')}
                    onBlur={handleBlur('class')}
                  />
                  <ErrorMessage className="text-danger mb-1" name="standard" component="div" />
                </div>
                <div className="form-group">
                  <label className="form-label">Division*</label>
                  <Select
                    name="division"
                    options={divisions}
                    value={divisions.find(option => option.value === values.division)}
                    onChange={(option) => setFieldValue('division', option ? option.value : '')}
                    onBlur={handleBlur('division')}
                  />
                  <ErrorMessage className="text-danger mb-1" name="standard" component="div" />
                </div>
                <div className="form-group">
                  <label className="form-label">Status*</label>
                  <Select
                    name="status"
                    options={statusOptions}
                    value={statusOptions.find(option => option.value === values.status)}
                    onChange={(option) => setFieldValue('status', option ? option.value : '')}
                    onBlur={handleBlur('status')}
                  />
                  <ErrorMessage className="text-danger mb-1" name="status" component="div" />
                </div>
                <div className="card-footer text-end btn-page">
                  <Button className="btn btn-outline-danger" onClick={() => resetForm()} text="Cancel" />
                  <Button className="btn btn-primary" type="submit" icon={isSubmitting && <Spinner animation="border" size="sm" />} disabled={isSubmitting} text={isSubmitting ? "Processing..." : "Add New"} />
                </div>
              </Form>
            )}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
};

export default DivisionForm
