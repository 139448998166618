import { format } from "date-fns";
import { textFormatter } from "../../utils/common";
import { avatar_2 } from "../../utils/images";
import { TbCake, TbPasswordFingerprint } from "react-icons/tb";
import { MdBloodtype } from "react-icons/md";

const SidePanel = ({userDetails}) => {
  return (
    <>
      <div className="col-lg-4 col-xxl-3">
        <div className="card">
          <div className="card-body position-relative">
            <div className="position-absolute end-0 top-0 p-3">
              <span className="badge bg-primary">{userDetails?.users_type && textFormatter(userDetails?.users_type)}</span>
            </div>
            <div className="text-center mt-3">
              <div className="chat-avtar d-inline-flex mx-auto">
                <img className="rounded-circle img-fluid wid-70" src={userDetails?.image ? userDetails?.image : avatar_2}
                  alt="" />
              </div>
              <h5 className="mb-0">{userDetails?.name}</h5>
              <p className="text-muted text-sm">{userDetails?.users_designation && textFormatter(userDetails?.users_designation)}</p>
              <hr className="my-3 border border-secondary-subtle" />
              <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                <i className="ti ti-mail me-2"></i>
                <p className="mb-0">{userDetails?.email}</p>
              </div>
              <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                <i className="ti ti-phone me-2"></i>
                <p className="mb-0">{userDetails?.mobile} - {userDetails?.emergency_mobile} </p>
              </div>
              <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                <TbCake className="me-2" />
                <p className="mb-0">{userDetails?.dob && format(userDetails?.dob, 'dd-MM-yyyy')}</p>
              </div>
              <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                <MdBloodtype className="me-2" />
                <p className="mb-0">{userDetails?.blood_group}</p>
              </div>
              <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                <TbPasswordFingerprint className="me-2" />
                <p className="mb-0">{userDetails?._id}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default SidePanel
