import { useSelector } from "react-redux";
import Breadcrumb from "../../components/common/Breadcrumb";
import UserImage from "../../components/profile/UserImage";
import ChangePassword from "../../components/profile/ChangePassword";
import { FaLock } from "react-icons/fa6";
import SidePanel from "../../components/profile/SidePanel";
import ProfileForm from "../../components/profile/ProfileForm";

const Profile = () => {
  // Get the current user's profile
  const { userDetails } = useSelector((state) => state.user);
  return (
    <>
      <div className="pc-container">
        <div className="pc-content">
          <Breadcrumb />
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body py-0">
                  <ul className="nav nav-tabs profile-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                      <a className="nav-link active" id="profile-tab-1" data-bs-toggle="tab" href="#profile-1" role="tab"
                        aria-selected="true">
                        <i className="ti ti-user me-2"></i>Profile
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" id="profile-tab-2" data-bs-toggle="tab" href="#profile-2" role="tab"
                        aria-selected="true">
                          <FaLock className="me-2" />Change Password
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="tab-content">
                <div className="tab-pane show active" id="profile-1" role="tabpanel" aria-labelledby="profile-tab-1">
                  <div className="row">
                    <SidePanel userDetails={userDetails}/>
                    <div className="col-lg-8 col-xxl-9">
                      <div className="card">
                        <div className="card-header">
                          <h5>Personal Details</h5>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <UserImage userDetails={userDetails}/>
                            <ProfileForm userDetails={userDetails} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane" id="profile-2" role="tabpanel" aria-labelledby="profile-tab-2">
                  <ChangePassword userDetails={userDetails}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default Profile
