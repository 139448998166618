import Breadcrumb from "../../components/common/Breadcrumb";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Button from "../../components/common/Button"
import TCForm from "../../components/tc/TCForm";
import TCTable from "../../components/tc/TCTable";
import { fetchTC } from "../../store/slices/TCSlice";
import { FiAlertTriangle } from "react-icons/fi";

const TransferCertificate = () => {
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state.user);
  useEffect(() => {
    dispatch(fetchTC({
      institute: userDetails?.institute?._id
    }))
  }, [dispatch, userDetails]);
  const { tcDetails } = useSelector((state) => state.transfer_certificate);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <section className="pc-container">
        <TCForm show={show} handleClose={handleClose} userDetails={userDetails} />
        <div className="pc-content">
          <Breadcrumb />
          <div className="row">
            <div className="col-lg-12">
              <div className="alert alert-danger d-flex align-items-center alert-dismissible fade show" role="alert">
                <FiAlertTriangle className="me-2" />
                <div><strong>Warning!</strong> Select proper working printer with all settings. Only single time printing is allowed. </div>
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>
              <div className="card">
                <div id="sticky-action" className="sticky-action">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col-sm-6">
                        <h5>Manage Transfer Certificate</h5>
                      </div>
                      <div className="col-sm-6 text-sm-end mt-3 mt-sm-0">
                        <Button type="button" onClick={handleShow} className="btn btn-success me-2" text="Add New" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <TCTable tcDetails={tcDetails} userDetails={userDetails} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
};

export default TransferCertificate
