import React from 'react';
import ReactDOM from 'react-dom/client';

//CSS
import "./assets/css/plugins/animate.min.css"
import "./assets/fonts/inter/inter.css"
//Fonts CSS
import "./assets/fonts/tabler-icons.min.css"
// Main CSS
import "bootstrap/dist/css/bootstrap.min.css"
import "./assets/css/style.css";
import "./assets/css/style-preset.css"

import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './utils/i18n';
import { Provider } from 'react-redux';
import Store from './store/Store';
import { AuthStore } from './store/AuthStore';
import AuthProvider from 'react-auth-kit/AuthProvider';
import { GoogleReCaptchaProvider } from '@google-recaptcha/react';
import ReactGA from 'react-ga4';

// Js
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { ga_MeasurementID, googleReCaptcha } from './utils/config';


const root = ReactDOM.createRoot(document.getElementById('root'));
//Google analytics
ReactGA.initialize(ga_MeasurementID);
root.render(
  <Provider store={Store}>
    <AuthProvider store={AuthStore} >
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <GoogleReCaptchaProvider
            type={googleReCaptcha.type}
            siteKey={googleReCaptcha.siteKey}
          >
            {/* <React.StrictMode> */}
              <App />
            {/* </React.StrictMode> */}
          </GoogleReCaptchaProvider>
        </I18nextProvider>
      </BrowserRouter>
    </AuthProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
