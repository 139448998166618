
const Label = (props) => {
  return (
    <>
      <label className={props.className} htmlFor={props.htmlFor}>{props.title}</label>
    </>
  )
};

export default Label
