import Breadcrumb from "../../components/common/Breadcrumb";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import InvoiceTable from "../../components/invoice/InvoiceTable";
import { fetchInvoice } from "../../store/slices/InvoiceSlice";

const Invoice = () => {
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state.user);
  useEffect(() => {
    dispatch(fetchInvoice({
      institute: userDetails?.institute?._id
    }))
  }, [dispatch, userDetails]);
  const { invoiceDetails } = useSelector((state) => state.invoice);

  return (
    <>
      <section className="pc-container">
        <div className="pc-content">
          <Breadcrumb />
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div id="sticky-action" className="sticky-action">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col-sm-6">
                        <h5>Manage Invoice</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <InvoiceTable invoiceDetails={invoiceDetails} userDetails={userDetails} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
};

export default Invoice
