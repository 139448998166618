
import { ErrorMessage, Field, Form, Formik } from "formik";
import { showAlertMessage } from "../../../utils/sweetAlert";
import * as Yup from "yup";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useEffect, useMemo, useState } from "react";
import { postData } from "../../../api";
import Select from 'react-select';
import { bloodGroups, classOptions, genders, languages, religions } from "../../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategory } from "../../../store/slices/CategorySlice";
import { fetchSubCaste } from "../../../store/slices/SubCasteSlice";
import { fetchCities, fetchCountries, fetchStates } from "../../../store/slices/CSCSlice";
import { fetchClass } from "../../../store/slices/ClassSlice";
import { fetchDivision } from "../../../store/slices/DivisionSlice";
import LastSchoolForm from "../../../components/admission/LastSchoolForm";
import { fetchLastSchool } from "../../../store/slices/LastSchoolSlice";
import { FaPlus } from "react-icons/fa6";
import CategoryForm from "../../../components/caste/category/CategoryForm";
import SubCasteForm from "../../../components/caste/sub-caste/SubCasteForm";
import ClassForm from "../../../components/class/ClassForm";
import DivisionForm from "../../../components/division/DivisionForm";
import Button from "../../../components/common/Button";
import { Spinner } from "react-bootstrap";
import { createAdmission, putAdmission } from "../../../store/slices/AdmissionSlice";
import { format } from "date-fns";
import { fetchCaste } from "../../../store/slices/CasteSlice";
import CasteForm from "../../caste/caste/CasteForm";
import { useTranslation } from "react-i18next";

const AdmissionForm = ({ userDetails, type = "", student = "" }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // Last school
  const [lastSchools, setLastSchools] = useState([]);
  const { lastSchoolDetails } = useSelector((state) => state.last_school);
  useEffect(() => {
    dispatch(fetchLastSchool());
  }, [dispatch])

  useEffect(() => {
    const getLastSchool = () => {
      setLastSchools(lastSchoolDetails.map(item => ({ value: item._id, label: item.last_school })));
    }
    getLastSchool();
  }, [lastSchoolDetails])

  // State Search --------------
  const [isLoading, setIsLoading] = useState(false);
  const [stateOptions, setStateOptions] = useState([]);
  const handleSearch = async (query) => {
    setIsLoading(true);
    const response = await postData('administration/csc/states/search', { name: query });
    setStateOptions(response.records);
    setIsLoading(false);
  };
  const filterBy = () => true;
  // ---------------------------

  //Get Standards (Class)
  const [standardOptions, setStandardOptions] = useState([]);
  useEffect(() => {
    dispatch(fetchClass({
      institute: userDetails?.institute?._id
    }))
  }, [dispatch, userDetails])
  const { classDetails } = useSelector((state) => state.class);
  useEffect(() => {
    const getStandards = async () => {
      setStandardOptions(() => classDetails.map(item => ({ value: item.class, label: item.class })));
    }
    getStandards()
  }, [classDetails])

  // Divisions
  const { divisionDetails } = useSelector((state) => state.division);
  const [divisions, setDivisions] = useState([])
  const handleCurrentClassChange = (option, setFieldValue) => {
    setFieldValue('class', option ? option.value : '');
    setDivisions([])
    dispatch(fetchDivision({ institute: userDetails?.institute?._id, class: option.value }))
  }

  useEffect(() => {
    const getDivisions = async () => {
      setDivisions(() => divisionDetails.map(item => ({ value: item.division, label: item.division })));
    }
    getDivisions()
  }, [divisionDetails])

  // Caste options
  const { casteDetails } = useSelector((state) => state.caste);
  const [castes, setCastes] = useState([])

  const handleReligionChange = (option, setFieldValue) => {
    setFieldValue('religion', option ? option.value : '');
    setCastes([])
    dispatch(fetchCaste({ religion: option.value }))
  };

  useEffect(() => {
    dispatch(fetchCaste())
  }, [dispatch])

  useEffect(() => {
    const getCastes = async () => {
      setCastes(() => casteDetails.map(item => ({ value: item.caste, label: item.caste })));
    }
    getCastes()
  }, [casteDetails])

  // Category options
  const { categoryDetails } = useSelector((state) => state.category);
  const [categories, setCategories] = useState([])
  useEffect(() => {
    dispatch(fetchCategory())
  }, [dispatch])

  useEffect(() => {
    const getCategories = async () => {
      setCategories(() => categoryDetails.map(item => ({ value: item.category, label: item.category })));
    }
    getCategories()
  }, [categoryDetails])

  // Sub caste options
  const { subCasteDetails } = useSelector((state) => state.subCaste);
  const [subCastes, setSubCastes] = useState([])
  const handleCategoryChange = (option, setFieldValue) => {
    setFieldValue('category', option ? option.value : '');
    setSubCastes([])
    dispatch(fetchSubCaste({ category: option.value }))
  };
  useEffect(() => {
    const getSubCastes = async () => {
      setSubCastes(() => subCasteDetails.map(item => ({ value: item.sub_caste, label: item.sub_caste })));
    }
    getSubCastes()
  }, [subCasteDetails])

  // Country options
  const { countries: countriesList, states: statesList, cities: citiesList } = useSelector((state) => state.csc);
  const [countries, setCountries] = useState([])
  useEffect(() => {
    dispatch(fetchCountries())
  }, [dispatch])

  useEffect(() => {
    const getCountries = async () => {
      setCountries(() => countriesList.map(country => ({ value: country._id, label: country.name })));
    }
    getCountries()
  }, [countriesList])

  // State options
  const [states, setStates] = useState([])
  const handleCountryChange = (option, setFieldValue) => {
    setFieldValue('country', option ? option.value : '');
    setStates([])
    dispatch(fetchStates({ country: option.value }))
  };
  useEffect(() => {
    const getStates = async () => {
      setStates(() => statesList.map(state => ({ value: state._id, label: state.name })));
    }
    getStates()
  }, [statesList])

  // City options
  const [cities, setCities] = useState([])
  const handleStateChange = (option, setFieldValue) => {
    setFieldValue('state', option ? option.value : '');
    setCities([])
    dispatch(fetchCities({ state: option.value }))
  };
  useEffect(() => {
    const getCities = async () => {
      setCities(() => citiesList.map(city => ({ value: city._id, label: city.name })));
    }
    getCities()
  }, [citiesList])

  // Form initial values
  const initialValues = useMemo(() => ({
    institute: {
      udise: userDetails?.institute?.udise,
      institute: userDetails?.institute?._id,
      session: userDetails?.institute?.active_session?.session,
      user: userDetails?._id,
    },
    _id: student ? student._id : null,
    name: student ? student?.name : '',
    saral_id: student ? student?.saral_id : '',
    aadhaar_no: student ? student?.aadhaar_no : '',
    dob: student?.dob ? format(student?.dob, 'yyyy-MM-dd') : '',
    gender: student ? student?.gender : '',
    blood_group: student ? student?.blood_group : '',
    mother_tongue: student ? student?.mother_tongue : 'Urdu',
    birth_place: student ? student?.birth_place : '',
    birth_state: student ? student?.birth_state?._id : '',
    identification_mark_1: student ? student?.identification_mark_1 : '',
    identification_mark_2: student ? student?.identification_mark_2 : '',
    religion: student ? student?.religion : 'Islam',
    caste: student ? student?.caste : 'Muslim',
    category: student ? student?.category : '',
    sub_caste: student ? student?.sub_caste : '',

    parent_id: student ? student?.parent?._id : '',
    f_name: student ? student?.parent?.f_name : '',
    m_name: student ? student?.parent?.m_name : '',
    f_mobile: student ? student?.parent?.f_mobile : '',
    m_mobile: student ? student?.parent?.m_mobile : '',
    address: student ? student?.parent?.address : '',
    country: student ? student?.parent?.country?._id : '',
    state: student ? student?.parent?.state?._id : '',
    city: student ? student?.parent?.city?._id : '',
    pin_code: student ? student?.parent?.pin_code : '',
    email: student ? student?.parent?.email : '',
    f_dob: student?.parent?.f_dob ? format(student?.parent?.f_dob, 'yyyy-MM-dd') : '',
    m_dob: student?.parent?.m_dob ? format(student?.parent?.m_dob, 'yyyy-MM-dd') : '',
    f_education: student ? student?.parent?.f_education : '',
    m_education: student ? student?.parent?.m_education : '',
    f_occupation: student ? student?.parent?.f_occupation : '',
    m_occupation: student ? student?.parent?.m_occupation : '',

    tc_number: student ? student?.tc_number : '',
    tc_date: student?.tc_date ? format(student?.tc_date, 'yyyy-MM-dd') : '',
    last_school: student ? student?.last_school?._id : '',

    admission_date: student?.admission_date ? format(student?.admission_date, 'yyyy-MM-dd') : '',
    admission_no: student ? student?.admission_no : '',
    admitted_class: student ? student?.admitted_class : '',
    last_class: student ? student?.last_class : '',
    class: student ? student?.class : '',
    division: student ? student?.division : '',
    roll_number: student ? student?.roll_number : '',
  }), [student, userDetails]);
  // Form validation
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Student name is required'),
    saral_id: Yup.string()
      .max(19, 'Saral ID must be maximum 19 digits')
      .matches(/^\d*$/, 'Saral ID must contain only numbers'),
    aadhaar_no: Yup.string()
      .matches(/^\d{12}$/, 'Aadhaar number must be exactly 12 digits'),
    religion: Yup.string()
      .required('Religion is required'),
    caste: Yup.string()
      .required('Caste name is required'),
    category: Yup.string()
      .required('Category name is required'),
    dob: Yup.date().required('Date of birth is required'),
    birth_place: Yup.string()
      .required('Place of birth is required'),
    gender: Yup.string()
      .required('Gender is required'),
    f_name: Yup.string()
      .required('Father name is required'),
    m_name: Yup.string()
      .required('Mother name is required'),
    f_mobile: Yup.string()
      .matches(/^[6-9]\d{9}$/, 'Invalid Indian mobile number')
      .required('Father mobile number is required'),
    address: Yup.string()
      .required('Address is required'),
    country: Yup.string()
      .required('County is required'),
    state: Yup.string()
      .required('State is required'),
    city: Yup.string()
      .required('City is required'),
    pin_code: Yup.string()
      .required('Pin code is required'),
    admission_date: Yup.date().required('Date of admission is required'),
    admitted_class: Yup.string()
      .required('Admitted class is required'),
    class: Yup.string()
      .required('Class is required'),
    division: Yup.string()
      .required('Division is required'),
  });
  // Form submit
  const onSubmit = async (values, actions) => {
    try {
      const response = student ?
        //Update student information
        await dispatch(putAdmission({
          _id: values._id,
          query: {
            student: {
              name: values.name,
              saral_id: values.saral_id,
              aadhaar_no: values.aadhaar_no,
              dob: values.dob,
              gender: values.gender,
              blood_group: values.blood_group,
              mother_tongue: values.mother_tongue,
              birth_place: values.birth_place,
              birth_state: values.birth_state,
              identification_mark_1: values.identification_mark_1,
              identification_mark_2: values.identification_mark_2,
              religion: values.religion,
              caste: values.caste,
              category: values.category,
              sub_caste: values.sub_caste,
              tc_number: values.tc_number,
              tc_date: values.tc_date,
              last_school: values.last_school,
              admission_date: values.admission_date,
              admission_no: values.admission_no,
              admitted_class: values.admitted_class,
              last_class: values.last_class,
              class: values.class,
              division: values.division,
              roll_number: values.roll_number
            },
            parent: {
              _id: values.parent_id,
              f_name: values.f_name,
              m_name: values.m_name,
              f_mobile: values.f_mobile,
              m_mobile: values.m_mobile,
              address: values.address,
              country: values.country,
              state: values.state,
              city: values.city,
              pin_code: values.pin_code,
              email: values.email,
              f_dob: values.f_dob,
              m_dob: values.m_dob,
              f_education: values.f_education,
              m_education: values.m_education,
              f_occupation: values.f_occupation,
              m_occupation: values.m_occupation,
            }
          }
        }))
        :
        // Add new admission
        await dispatch(createAdmission({
          institute: values.institute,
          student: {
            name: values.name,
            saral_id: values.saral_id,
            aadhaar_no: values.aadhaar_no,
            dob: values.dob,
            gender: values.gender,
            blood_group: values.blood_group,
            mother_tongue: values.mother_tongue,
            birth_place: values.birth_place,
            birth_state: values.birth_state,
            identification_mark_1: values.identification_mark_1,
            identification_mark_2: values.identification_mark_2,
            religion: values.religion,
            caste: values.caste,
            category: values.category,
            sub_caste: values.sub_caste,
            tc_number: values.tc_number,
            tc_date: values.tc_date,
            last_school: values.last_school,
            admission_date: values.admission_date,
            admission_no: values.admission_no,
            admitted_class: values.admitted_class,
            last_class: values.last_class,
            class: values.class,
            division: values.division,
            roll_number: values.roll_number
          },
          parent: {
            f_name: values.f_name,
            m_name: values.m_name,
            f_mobile: values.f_mobile,
            m_mobile: values.m_mobile,
            address: values.address,
            country: values.country,
            state: values.state,
            city: values.city,
            pin_code: values.pin_code,
            email: values.email,
            f_dob: values.f_dob,
            m_dob: values.m_dob,
            f_education: values.f_education,
            m_education: values.m_education,
            f_occupation: values.f_occupation,
            m_occupation: values.m_occupation,
          }
        }))
      if (response?.payload?.status === 'success') {
        await showAlertMessage({ text: response?.payload?.message });
        actions.resetForm();
      } else {
        throw new Error(response?.payload?.response?.data.message);
      }
    } catch (error) {
      showAlertMessage({
        icon: 'error',
        title: 'Error occurred!',
        text: error.message,
      });
    } finally {
      actions.setSubmitting(false);
    }
  };

  //Add Last School
  const [showLastSchool, setShowLastSchool] = useState(false);
  const handleCloseLastSchool = () => setShowLastSchool(false);
  const handleShowLastSchool = () => setShowLastSchool(true);

  //Add Sub caste, Category & Sub Caste
  const [showCaste, setShowCaste] = useState(false);
  const handleCloseCaste = () => setShowCaste(false);
  const handleShowCaste = () => setShowCaste(true);

  const [showCategory, setShowCategory] = useState(false);
  const handleCloseCategory = () => setShowCategory(false);
  const handleShowCategory = () => setShowCategory(true);

  const [showSubCaste, setShowSubCaste] = useState(false);
  const handleCloseSubCaste = () => setShowSubCaste(false);
  const handleShowSubCaste = () => setShowSubCaste(true);

  //Add Class & Division
  const [showClass, setShowClass] = useState(false);
  const handleCloseClass = () => setShowClass(false);
  const handleShowClass = () => setShowClass(true);

  const [showDivision, setShowDivision] = useState(false);
  const handleCloseDivision = () => setShowDivision(false);
  const handleShowDivision = () => setShowDivision(true);

  useEffect(() => {
    dispatch(fetchStates({ country: initialValues.country }))
    dispatch(fetchCities({ state: initialValues.state }))
    dispatch(fetchCaste({ religion: initialValues.religion }))
    dispatch(fetchSubCaste({ category: initialValues.category }))
    dispatch(fetchDivision({ institute: userDetails?.institute?._id, class: initialValues.class }))
  }, [dispatch, initialValues, userDetails]);

  return (
    <>
      <LastSchoolForm show={showLastSchool} handleClose={handleCloseLastSchool} userDetails={userDetails} />
      <CasteForm show={showCaste} handleClose={handleCloseCaste} userDetails={userDetails} />
      <CategoryForm show={showCategory} handleClose={handleCloseCategory} userDetails={userDetails} />
      <SubCasteForm show={showSubCaste} handleClose={handleCloseSubCaste} categoryDetails={categoryDetails} userDetails={userDetails} />
      <ClassForm show={showClass} handleClose={handleCloseClass} userDetails={userDetails} />
      <DivisionForm show={showDivision} handleClose={handleCloseDivision} userDetails={userDetails} />
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({ isSubmitting, setFieldValue, values, handleBlur, handleChange, resetForm }) => (
          <Form>
            <div id="sticky-action" className="sticky-action">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col-sm-6">
                    <h5>{t("Admission Form")}</h5>
                  </div>
                  <div className="col-sm-6 text-sm-end mt-3 mt-sm-0 btn-page">
                    <Button className="btn btn-outline-danger" onClick={() => resetForm()} text={t("Cancel")} />
                    <Button className="btn btn-primary" type="submit" icon={isSubmitting && <Spinner animation="border" size="sm" />} disabled={isSubmitting} text={isSubmitting ? t("Processing...") : `${student ? t("Update") : t("Add")} ${t("Admission")}`} />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <h5>{t("Student Details")}</h5>
              <hr />
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">{t("Student Name")}*</label>
                    <Field
                      className="form-control"
                      name="name"
                      autoFocus
                    />
                    <ErrorMessage className="text-danger mb-1" name="name" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">{t("Saral Id")}</label>
                    <Field
                      className="form-control"
                      name="saral_id"
                    />
                    <ErrorMessage className="text-danger mb-1" name="saral_id" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">{t("Aadhaar No")}</label>
                    <Field
                      className="form-control"
                      name="aadhaar_no"
                    />
                    <ErrorMessage className="text-danger mb-1" name="aadhaar_no" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">{t("Date of Birth")}*</label>
                    <Field
                      type="date"
                      className="form-control"
                      name="dob"
                    />
                    <ErrorMessage className="text-danger mb-1" name="dob" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">{t("Gender")}*</label>
                    <Field
                      as="select"
                      className="form-control"
                      name="gender"
                      onChange={handleChange}
                    >
                      <option></option>
                      {genders.map(item => (<option value={item.value} key={item.value}>{item.label}</option>))}
                    </Field>
                    <ErrorMessage className="text-danger mb-1" name="gender" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">{t("Blood Group")}</label>
                    <Field
                      as="select"
                      className="form-control"
                      name="blood_group"
                      onChange={handleChange}
                    >
                      <option></option>
                      {bloodGroups.map(item => (<option value={item.value} key={item.value}>{item.label}</option>))}
                    </Field>
                    <ErrorMessage className="text-danger mb-1" name="blood_group" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">{t("Mother Tongue")}</label>
                    <Field
                      as="select"
                      className="form-control"
                      name="mother_tongue"
                      onChange={handleChange}
                    >
                      <option></option>
                      {languages.map(item => (<option value={item.value} key={item.value}>{item.label}</option>))}
                    </Field>
                    <ErrorMessage className="text-danger mb-1" name="mother_tongue" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">{t("Place of Birth")}*</label>
                    <Field
                      className="form-control"
                      name="birth_place"
                    />
                    <ErrorMessage className="text-danger mb-1" name="birth_place" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">{t("State of Birth")}</label>
                    <AsyncTypeahead
                      filterBy={filterBy}
                      id="async-example"
                      isLoading={isLoading}
                      labelKey="name"
                      name="birth_state"
                      minLength={3}
                      onSearch={handleSearch}
                      options={stateOptions}
                      onChange={(selected) => {
                        setFieldValue('birth_state', selected.length > 0 ? selected[0]._id : '');
                      }}
                      onBlur={handleBlur}
                      placeholder="Search for a state..."
                    />
                    <ErrorMessage className="text-danger mb-1" name="birth_state" component="div" />
                  </div>
                </div>

                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">{t("Identification Mark 1")}</label>
                    <Field
                      className="form-control"
                      name="identification_mark_1"
                    />
                    <ErrorMessage className="text-danger mb-1" name="identification_mark_1" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">{t("Identification Mark 2")}</label>
                    <Field
                      className="form-control"
                      name="identification_mark_2"
                    />
                    <ErrorMessage className="text-danger mb-1" name="identification_mark_2" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">{t("Religion")}*</label>
                    <Select
                      name="religion"
                      cacheOptions
                      defaultOptions
                      options={religions}
                      value={religions.find(option => option.value === values.religion)}
                      onChange={(option) => handleReligionChange(option, setFieldValue)}
                      onBlur={handleBlur('religion')}
                    />
                    <ErrorMessage className="text-danger mb-1" name="religion" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">{t("Caste")}*</label>
                    <div className="row">
                      <div className="col-sm-10">
                        <Select
                          name="caste"
                          cacheOptions
                          defaultOptions
                          options={castes}
                          value={castes.find(option => option.value === values.caste)}
                          onChange={(option) => setFieldValue('caste', option ? option.value : '')}
                          onBlur={handleBlur('caste')}
                        />
                      </div>
                      <div className="col-sm-2">
                        <button type="button" className="btn btn-icon btn-success" onClick={handleShowCaste} ><FaPlus /></button>
                      </div>
                    </div>
                    <ErrorMessage className="text-danger mb-1" name="caste" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">{t("Category")}*</label>
                    <div className="row">
                      <div className="col-sm-10">
                        <Select
                          name="category"
                          cacheOptions
                          defaultOptions
                          options={categories}
                          value={categories.find(option => option.value === values.category)}
                          onChange={(option) => handleCategoryChange(option, setFieldValue)}
                          onBlur={handleBlur('category')}
                        />
                      </div>
                      <div className="col-sm-2">
                        <button type="button" className="btn btn-icon btn-success" onClick={handleShowCategory} ><FaPlus /></button>
                      </div>
                    </div>
                    <ErrorMessage className="text-danger mb-1" name="category" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">{t("Sub Caste")}</label>
                    <div className="row">
                      <div className="col-sm-10">
                        <Select
                          name="sub_caste"
                          options={subCastes}
                          value={subCastes.find(option => option.value === values.sub_caste)}
                          onChange={(option) => setFieldValue('sub_caste', option ? option.value : '')}
                          onBlur={handleBlur('sub_caste')}
                        />
                      </div>
                      <div className="col-sm-2">
                        <button type="button" className="btn btn-icon btn-success" onClick={handleShowSubCaste} ><FaPlus /></button>
                      </div>
                    </div>
                    <ErrorMessage className="text-danger mb-1" name="sub_caste" component="div" />
                  </div>
                </div>
              </div>
              <h5 className="mt-2">Parents Details</h5>
              <hr />
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">Father's Name*</label>
                    <Field
                      className="form-control"
                      name="f_name"
                    />
                    <ErrorMessage className="text-danger mb-1" name="f_name" component="div" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">Mother's Name*</label>
                    <Field
                      className="form-control"
                      name="m_name"
                    />
                    <ErrorMessage className="text-danger mb-1" name="m_name" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">Father's Mobile*</label>
                    <Field
                      className="form-control"
                      name="f_mobile"
                    />
                    <ErrorMessage className="text-danger mb-1" name="f_mobile" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">Mother's Mobile</label>
                    <Field
                      className="form-control"
                      name="m_mobile"
                      maxLength={10}
                      size={10}
                    />
                    <ErrorMessage className="text-danger mb-1" name="m_mobile" component="div" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">Address*</label>
                    <Field
                      className="form-control"
                      name="address"
                    />
                    <ErrorMessage className="text-danger mb-1" name="address" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">Country*</label>
                    <Select
                      name="country"
                      cacheOptions
                      defaultOptions
                      options={countries}
                      value={countries.find(option => option.value === values.country)}
                      onChange={(option) => handleCountryChange(option, setFieldValue)}
                      onBlur={handleBlur('country')}
                    />
                    <ErrorMessage className="text-danger mb-1" name="country" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">State*</label>
                    <Select
                      name="state"
                      cacheOptions
                      defaultOptions
                      options={states}
                      value={states.find(option => option.value === values.state)}
                      onChange={(option) => handleStateChange(option, setFieldValue)}
                      onBlur={handleBlur('state')}
                    />
                    <ErrorMessage className="text-danger mb-1" name="state" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">City*</label>
                    <Select
                      name="city"
                      cacheOptions
                      defaultOptions
                      options={cities}
                      value={cities.find(option => option.value === values.city)}
                      onChange={(option) => setFieldValue('city', option ? option.value : '')}
                      onBlur={handleBlur('city')}
                    />
                    <ErrorMessage className="text-danger mb-1" name="city" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">Pin code*</label>
                    <Field
                      className="form-control form-control-sm"
                      name="pin_code"
                      maxLength={6}
                      size={6}
                    />
                    <ErrorMessage className="text-danger mb-1" name="pin_code" component="div" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">Email</label>
                    <Field
                      type="email"
                      className="form-control"
                      name="email"
                    />
                    <ErrorMessage className="text-danger mb-1" name="email" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">Father's DOB</label>
                    <Field
                      type="date"
                      className="form-control"
                      name="f_dob"
                    />
                    <ErrorMessage className="text-danger mb-1" name="f_dob" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">Mother's DOB</label>
                    <Field
                      type="date"
                      className="form-control"
                      name="m_dob"
                    />
                    <ErrorMessage className="text-danger mb-1" name="m_dob" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">Father's Education</label>
                    <Field
                      className="form-control"
                      name="f_education"
                    />
                    <ErrorMessage className="text-danger mb-1" name="f_education" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">Mother's Education</label>
                    <Field
                      className="form-control"
                      name="m_education"
                    />
                    <ErrorMessage className="text-danger mb-1" name="m_education" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">Father's Occupation</label>
                    <Field
                      className="form-control"
                      name="f_occupation"
                    />
                    <ErrorMessage className="text-danger mb-1" name="f_occupation" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">Mother's Occupation</label>
                    <Field
                      className="form-control"
                      name="m_occupation"
                    />
                    <ErrorMessage className="text-danger mb-1" name="m_occupation" component="div" />
                  </div>
                </div>
              </div>
              <h5 className="mt-2">Previous Institute Educational Details</h5>
              <hr />
              <div className="row">
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">T.C Number</label>
                    <Field
                      className="form-control"
                      name="tc_number"
                    />
                    <ErrorMessage className="text-danger mb-1" name="tc_number" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">T.C Issue Date</label>
                    <Field
                      type="date"
                      className="form-control"
                      name="tc_date"
                    />
                    <ErrorMessage className="text-danger mb-1" name="tc_date" component="div" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">Previous Institute</label>
                    <div className="row">
                      <div className="col-sm-10">
                        <Select
                          name="last_school"
                          cacheOptions
                          defaultOptions
                          options={lastSchools}
                          value={lastSchools.find(option => option.value === values.last_school)}
                          onChange={(option) => setFieldValue('last_school', option ? option.value : '')}
                          onBlur={handleBlur('last_school')}
                        />
                      </div>
                      <div className="col-sm-2">
                        <button type="button" className="btn btn-icon btn-success" onClick={handleShowLastSchool} ><FaPlus /></button>
                      </div>
                    </div>
                    <ErrorMessage className="text-danger mb-1" name="last_school" component="div" />
                  </div>
                </div>
              </div>
              <h5 className="mt-2">This Institute Educational Details</h5>
              <hr />
              <div className="row">
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">Date of admission*</label>
                    <Field
                      type="date"
                      className="form-control"
                      name="admission_date"
                    />
                    <ErrorMessage className="text-danger mb-1" name="admission_date" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">Admission Number</label>
                    <Field
                      className="form-control"
                      name="admission_no"
                    />
                    <ErrorMessage className="text-danger mb-1" name="admission_no" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">In which class admitted?*</label>
                    <Field
                      as="select"
                      className="form-control"
                      name="admitted_class"
                      onChange={handleChange}
                    >
                      <option></option>
                      {classOptions.map(item => (<option value={item.value} key={item.value}>{item.label}</option>))}
                    </Field>
                    <ErrorMessage className="text-danger mb-1" name="admitted_class" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">Pervious Class</label>
                    <Field
                      as="select"
                      className="form-control"
                      name="last_class"
                      onChange={handleChange}
                    >
                      <option></option>
                      {classOptions.map(item => (<option value={item.value} key={item.value}>{item.label}</option>))}
                    </Field>
                    <ErrorMessage className="text-danger mb-1" name="last_class" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">Current Class*</label>
                    <div className="row">
                      <div className="col-sm-10">
                        <Select
                          name="class"
                          options={standardOptions}
                          value={standardOptions.find(option => option.value === values.class)}
                          onChange={(option) => handleCurrentClassChange(option, setFieldValue)}
                          onBlur={handleBlur('class')}
                        />
                      </div>
                      <div className="col-sm-2">
                        <button type="button" className="btn btn-icon btn-success" onClick={handleShowClass} ><FaPlus /></button>
                      </div>
                    </div>
                    <ErrorMessage className="text-danger mb-1" name="class" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">Division*</label>
                    <div className="row">
                      <div className="col-sm-10">
                        <Select
                          name="division"
                          cacheOptions
                          defaultOptions
                          options={divisions}
                          value={divisions.find(option => option.value === values.division)}
                          onChange={(option) => setFieldValue('division', option ? option.value : '')}
                          onBlur={handleBlur('division')}
                        />
                      </div>
                      <div className="col-sm-2">
                        <button type="button" className="btn btn-icon btn-success" onClick={handleShowDivision} ><FaPlus /></button>
                      </div>
                    </div>
                    <ErrorMessage className="text-danger mb-1" name="division" component="div" />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">Roll Number</label>
                    <Field
                      className="form-control form-control-sm"
                      name="roll_number"
                    />
                    <ErrorMessage className="text-danger mb-1" name="roll_number" component="div" />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer bg-light border-0">
              <div className="row">
                <label className="col-lg-4"></label>
                <div className="col-lg-8 text-end btn-page">
                  <Button className="btn btn-outline-danger" onClick={() => resetForm()} text="Cancel" />
                  <Button className="btn btn-primary" type="submit" icon={isSubmitting && <Spinner animation="border" size="sm" />} disabled={isSubmitting} text={isSubmitting ? "Processing..." : `${student ? "Update" : "Add"} Admission`} />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
};

export default AdmissionForm
