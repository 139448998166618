import { Link } from "react-router-dom";
import AuthSideContent from "../../components/common/AuthSideContent";
import Logo from "../../components/common/Logo";
import Label from "../../components/common/Label";
import Input from "../../components/common/Input";
import Button from "../../components/common/Button";

const ForgotPassword = () => {

  return (
    <>
      <div className="auth-main">
        <div className="auth-wrapper v2">
          <div className="auth-sidecontent">
            <AuthSideContent className="img-fluid img-auth-side" />
          </div>
          <div className="auth-form">
            <div className="card my-5">
              <div className="card-body">
                <Logo alt="image" height="200px" />
                <div className="mb-4">
                  <h3 className="mb-2"><b>Reset Password</b></h3>
                  <p className="text-muted">Please choose your new password</p>
                </div>
                <div className="form-group mb-3">
                  <Label className="form-label" title="Password" />
                  <Input type="password" className="form-control" id="floatingInput" placeholder="Password" />
                </div>
                <div className="form-group mb-3">
                  <Label className="form-label" title="Confirm Password" />
                  <Input type="password" className="form-control" id="floatingInput1" placeholder="Confirm Password" />
                </div>
                <div className="d-grid mt-4">
                  <Button type="button" className="btn btn-primary" text="Reset Password" />
                </div>
                <div className="d-flex justify-content-between align-items-end mt-4">
                  <h6 className="f-w-500 mb-0">Want to Login</h6>
                  <Link to="/auth/login" className="link-primary">Back to Login</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
};

export default ForgotPassword
